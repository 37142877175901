import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  BACKEND_BASE_URL,
  PORTAL_BACKEND_BASE_URL,
} from "../../../common/constant";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";

const CWCEventForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { Campaign } = useParams();
  const pathName = location.pathname?.split("/")[2] || "";

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialValues, setInitialValues] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNo: "",
    CountryPreference: [],
    CoursePreference: [],
    TimeToVisitID: null,
  });
  const [coffeeWithCareearlineID, setCoffeeWithCareearlineID] = useState(null);

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${BACKEND_BASE_URL}/Master/ManageCMSDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ CMSURL: "CoffeeWithCareearlineID" }),
          }
        );
        const data = await response.json();
        if (data.Status === 200) {
          setCoffeeWithCareearlineID(data?.Data?.Template);
        } else {
          console.log("Failed to fetch blogs");
        }
      } catch (error) {
        console.log("Error while fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomePage();
  }, []);

  useEffect(() => {
    AOS.init();
    // if (eventID) {
    // if (eventID || coffeeWithCareearlineID) {
    if (coffeeWithCareearlineID) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/findbyid?id=${
              coffeeWithCareearlineID
              // eventID
              // eventID || coffeeWithCareearlineID
            }`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const Response = await response.json();
          const result = Response?.Data;
          // const updatedVideoUrls = result?.VideoURL.map((item) => {
          //   const videoId = getYouTubeVideoId(item.VideoURL);
          //   return { VideoID: videoId, ...item };
          // });
          setData({
            // BannerImage: result?.BannerImage,
            // SideBanner: result?.SideBanner,
            // CMSHeader: result?.CMSHeader,
            // CMSFooter: result?.CMSFooter,
            EventCourse: result?.EventCourse ? result?.EventCourse : [],
            EventContry: result?.EventContry ? result?.EventContry : [],
            TimeSlotList: result?.TimeSlotList ? result?.TimeSlotList : [],
            // VideoURL: updatedVideoUrls ? updatedVideoUrls : [],
            // RegisteredPercentage: result?.RegisteredPercentage
            //   ? result?.RegisteredPercentage
            //   : null,
          });
        } catch (error) {
          setError(error?.message || "NetWrok Error");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [coffeeWithCareearlineID]);

  const phoneRegExp = /^[0-9]{10,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .email("Invalid email")
      .required("Email Address is required"),
    TimeToVisitID: Yup.number().required("Time to Visit is required"),
    // CoursePreference: Yup.number()
    //   .nullable()
    //   .test(
    //     "at-least-one",
    //     "Either Course Preference or Country Preference is required",
    //     function (value) {
    //       const { CountryPreference } = this.parent;
    //       return Boolean(value || CountryPreference);
    //     }
    //   ),
    // CountryPreference: Yup.number()
    //   .nullable()
    //   .test(
    //     "at-least-one",
    //     "Either Country Preference or Course Preference is required",
    //     function (value) {
    //       const { CoursePreference } = this.parent;
    //       return Boolean(value || CoursePreference);
    //     }
    //   ),
    CountryPreference: Yup.array().test(
      "at-least-one",
      "At least one of Country Preference is required",
      function (value) {
        const { CoursePreference } = this.parent;
        return (
          value?.length > 0 ||
          (CoursePreference && CoursePreference?.length > 0)
        );
      }
    ),
    CoursePreference: Yup.array().test(
      "at-least-one",
      "At least one of Course Preference is required",
      function (value) {
        const { CountryPreference } = this.parent;
        return (
          value?.length > 0 ||
          (CountryPreference && CountryPreference?.length > 0)
        );
      }
    ),
  });

  const handleCountryChange = (countryID) => {
    const { CountryPreference } = formik.values;
    const updatedPreferences = CountryPreference.includes(countryID)
      ? CountryPreference.filter((ID) => ID !== countryID)
      : [...CountryPreference, countryID];
    formik.setFieldValue("CountryPreference", updatedPreferences);
  };

  const handleCourseChange = (courseID) => {
    const { CoursePreference } = formik.values;
    const updatedPreferences = CoursePreference.includes(courseID)
      ? CoursePreference.filter((ID) => ID !== courseID)
      : [...CoursePreference, courseID];
    formik.setFieldValue("CoursePreference", updatedPreferences);
  };

  const onSubmit = async (values) => {
    // console.log("values", values);
    const payload = {
      ...values,
      CountryPreference: values.CountryPreference
        ? values.CountryPreference.join(",")
        : "",
      CoursePreference: values?.CoursePreference
        ? values?.CoursePreference?.join(",")
        : "",
      TimeToVisitID: values.TimeToVisitID ? +values.TimeToVisitID : null,
      MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
      EventID: coffeeWithCareearlineID ? +coffeeWithCareearlineID : null,
      Campaign: Campaign ? Campaign : "website",
    };
    // console.log("payload", payload);

    try {
      setLoading(true);
      const response = await fetch(
        `${PORTAL_BACKEND_BASE_URL}/Events/AddCWCEvents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      if (responseData.Status !== 200) {
        ErrorToast(responseData?.Details);
        // return;
      } else {
        navigate("/thankyou/");
        SuccessToast(responseData?.Details);
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  // if (loading) return <LoadingSpinner />;
  return (
    <form onSubmit={formik.handleSubmit} className="cwc-event-form">
      {loading && <LoadingSpinner />}
      <div className="row">
        <div className="col-12 d-flex">
          <div
            className="card flex-fill"
            style={{
              borderRadius: 30,
              background:
                pathName === "coffeewithcareerline2" ? "#275AA6" : "#fff",
              color: pathName === "coffeewithcareerline2" ? "#fff" : "#000",
            }}
          >
            <div className="card-body row">
              <h4
                className="card-title"
                style={{
                  color:
                    pathName === "coffeewithcareerline2" ? "#fff" : "#275AA6",
                }}
              >
                Claim Your Free Seat Now
              </h4>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    First Name<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="FirstName"
                      type="text"
                      id="FirstName"
                      placeholder="i.e. John"
                      className={`form-control ${
                        formik.errors.FirstName &&
                        formik.touched.FirstName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.FirstName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Last Name<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="LastName"
                      type="text"
                      id="LastName"
                      placeholder="i.e. Smith"
                      className={`form-control ${
                        formik.errors.LastName &&
                        formik.touched.LastName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.LastName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Email Address<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="EmailID"
                      type="email"
                      id="EmailID"
                      placeholder="i.e. example@gmail.com"
                      className={`form-control ${
                        formik.errors.EmailID &&
                        formik.touched.EmailID &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.EmailID}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Mobile No<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="MobileNo"
                      type="number"
                      id="MobileNo"
                      placeholder="i.e. 9999999999"
                      className={`form-control ${
                        formik.errors.MobileNo &&
                        formik.touched.MobileNo &&
                        "error-border"
                      }`}
                      // max={9999999999}

                      maxLength={10}
                      minLength={10}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.MobileNo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Country Prefrence<code>*</code>
                  </label>
                  <div className="col-12">
                    {/* <select
                      id="CountryPreference"
                      name="CountryPreference"
                      className={`form-control ${
                        formik.errors.CountryPreference &&
                        formik.touched.CountryPreference &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.CountryPreference}
                    >
                      <option value={null} label={"Select Country"} />
                      {data.EventContry?.map((item) => {
                        return (
                          <option value={item?.ID} label={item?.CountryName} />
                        );
                      })}
                    </select> */}
                    <table
                      className={`form-control tabel-border-none ${
                        formik.errors.CountryPreference &&
                        formik.touched.CountryPreference &&
                        "error-border"
                      }`}
                      id="CountryPreference"
                      style={{ maxHeight: 184, overflow: "auto" }}
                    >
                      <tbody>
                        {data?.EventContry ? (
                          data?.EventContry?.map((item) => (
                            <tr key={item.ID}>
                              <td>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={formik.values.CountryPreference.includes(
                                      item.ID
                                    )}
                                    onChange={() =>
                                      handleCountryChange(item.ID)
                                    }
                                  />
                                  {item.CountryName}
                                </label>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Course Preference<code>*</code>
                  </label>
                  <div className="col-12">
                    {/* <select
                      id="CoursePreference"
                      name="CoursePreference"
                      className={`form-control ${
                        formik.errors.CoursePreference &&
                        formik.touched.CoursePreference &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.CoursePreference}
                    >
                      <option value={null} label={"Select Course"} />
                      {data.EventCourse?.map((item) => {
                        return (
                          <option value={item?.ID} label={item?.CourseName} />
                        );
                      })}
                    </select> */}
                    <table
                      className={`form-control tabel-border-none ${
                        formik.errors.CoursePreference &&
                        formik.touched.CoursePreference &&
                        "error-border"
                      }`}
                      id="CoursePreference"
                      style={{ maxHeight: 184, overflow: "auto" }}
                    >
                      <tbody>
                        {data?.EventCourse ? (
                          data?.EventCourse?.map((item) => (
                            <tr key={item.ID}>
                              <td>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={formik.values.CoursePreference?.includes(
                                      item.ID
                                    )}
                                    onChange={() => handleCourseChange(item.ID)}
                                  />
                                  {item.CourseName}
                                </label>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-12 col-form-label fw-bold">
                    Time to Visit<code>*</code>
                  </label>
                  <div className="col-12">
                    <select
                      id="TimeToVisitID"
                      name="TimeToVisitID"
                      className={`form-control ${
                        formik.errors.TimeToVisitID &&
                        formik.touched.TimeToVisitID &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.TimeToVisitID}
                    >
                      <option value={null} label={"Select Time"} />
                      {data?.TimeSlotList?.map((item) => {
                        return (
                          <option value={item?.ID} label={item?.TimeSlot} />
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6"></div> */}
              <div className="text-center">
                <button
                  id="submit-btn"
                  className="submit-btn"
                  type="submit"
                  disabled={loading}
                >
                  {"Reserve My FREE Spot!"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CWCEventForm;
