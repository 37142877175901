import React, { useEffect, useState } from "react";
import BANNERIMG from "../../assets/image/Coaching PTE img layout.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../common/constant";
import BannerSubNews from "./dynamicMain/BannerSubNews";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";

// const BASE_URL = "https://api.careerline.in/api";
// const BASE_URL = "http://68.64.173.183/CALWEBAPI/api";

const SubNews = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  // const { id } = useParams();
  const { url } = useParams();
  const fetchNews = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("OperationType", 4);
      formData.append("NewsURL", url);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageNews`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.Status === 200) {
        setNews(data?.Data[0]);
      } else {
        console.log("Failed to fetch news");
      }
    } catch (error) {
      console.error("Error while fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  React.useEffect(() => {
    AOS.init();
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <BannerSubNews
        title={news.NewsTitle}
        date={news.NewsDate}
        imageSrc={BANNERIMG}
      />
      <div className="wpb_wrapper">
        <div className="wpb_text_column wpb_content_element">
          <div className="wpb_wrapper">
            <figure
              id="attachment_2529"
              aria-describedby="caption-attachment-2529"
              style={{ width: "300px", margin: "95px auto" }}
              className="wp-caption alignnone"
            >
              <img src={news.NewsImage} />
            </figure>

            <div style={{ margin: "0px 75px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: news.ShortDescription,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: news.LongDescription,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubNews;
