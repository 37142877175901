import React, { useEffect, useState } from "react";
import BANNERIMG from "../../assets/image/Coaching IELTS img layout.png";
import DynamicBanner from "../coaching/dynamicMain/DynamicBanner";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { PORTAL_BACKEND_BASE_URL } from "../../common/constant";
import { ErrorToast, SuccessToast } from "../../Components/Common/Toast";
import { Wheel } from "react-custom-roulette";

const wheelData = [
  {
    id: 1,
    value: "Free Study Material",
    option: "Study Material*",
  },
  {
    id: 2,
    value: "Discount Voucher For International Air Ticket",
    option: "Air Ticket*",
  },
  {
    id: 3,
    value: "Careerline Coffee Mug",
    option: "Coffee Mug",
  },
  {
    id: 4,
    value: "Discount Voucher For Processing Fees",
    option: "Processing Fee*",
  },
  {
    id: 5,
    value: "Free Mock Test",
    option: "Free Mock Test",
  },
  {
    id: 6,
    value: "Discount Voucher For Coaching",
    option: "Coaching Coupon*",
  },
];

const CWCThankyou = () => {
  // const scrollToTop = () => {
  //   window.scrollTo(0, 0);
  //   scrollToTop();
  // };

  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [hasSpun, setHasSpun] = useState(false);
  const [prizeTitle, setPrizeTitle] = useState("");
  const [showSpinnerModal, setShowSpinnerModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSpinnerModal(true);
    }, 2000);
  }, []);

  const handleSpinClick = () => {
    // const validOptions = wheelData?.filter(
    //   (option) => option.option !== "Batter Luck next Time"
    // );

    const randomIndex = Math.floor(Math.random() * wheelData.length);
    const randomOption = wheelData[randomIndex];

    setPrizeTitle(randomOption.value);
    setPrizeNumber(randomOption.id - 1);
    setSpin(true);
    setHasSpun(true);
  };

  const WheelDataSubmitHandler = async () => {
    const insertPayload = {
      EventApplicantID: state?.eventApplicantID,
      PriceWheelID: prizeNumber ? prizeNumber + 1 : 0,
    };
    // setShowModal(false);
    try {
      setLoading(true);
      const response = await fetch(
        `${PORTAL_BACKEND_BASE_URL}/eventregistration/UpdatePriceWheelID`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(insertPayload),
        }
      );
      const responseData = await response.json();
      if (responseData.Status !== 200) {
        ErrorToast(responseData?.Details);
      } else {
        SuccessToast(responseData?.Details);
        // navigate("/events/coffeewithcareearline/thankyou/", {
        //   state: {
        //     prizeTitle,
        //   },
        // });
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred during the API call:", error);
    } finally {
      // setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/* <DynamicBanner title={"Thank You"} imageSrc={BANNERIMG} /> */}
      <div className="post-inner" id="post-inner">
        <div className="entry-content">
          <div className="vc_row wpb_row vc_row-fluid about-section1">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid about-container">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                            <div className="wpb_wrapper">
                              <section className="login-main-wrapper">
                                <div className="main-container">
                                  <div className="login-process">
                                    <div className="login-main-container">
                                      <div className="thankyou-wrapper">
                                        <h1>
                                          <img
                                            src={
                                              "https://www.websiteimages.careerline.in/uploads/cwcthankyou.png"
                                            }
                                            alt="Thanks"
                                          />
                                        </h1>
                                        {prizeTitle && (
                                          <>
                                            <h2 className="title-Win-1">
                                              CONGRATULATIONS!!!
                                            </h2>
                                            <h4 className="title-Win-2">
                                              YOU HAVE WON
                                            </h4>
                                            <h2 className="title-Win-1">
                                              {prizeTitle} !!!
                                            </h2>
                                          </>
                                        )}
                                        <p>
                                          for contacting us, we will get in
                                          touch with you soon...
                                        </p>
                                        <Link to="/">Back to home</Link>
                                        <div className="clr"></div>
                                      </div>
                                      <div className="clr"></div>
                                    </div>
                                  </div>
                                  <div className="clr"></div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="overlay-modal"
        // style={{ display: "block" }}
        style={{ display: showSpinnerModal ? "block" : "none" }}
        // style={{ display: showModal || showSpinnerModal ? "block" : "none" }}
      ></div>
      {showSpinnerModal && (
        <div
          className="spinner-modal"
          style={{
            background: `url("https://images.careerline.org/uploads/wheel-background.png")`,
            height: "720px",
            width: "540px",
          }}
        >
          <div align="center" className="roulette-container">
            <Wheel
              mustStartSpinning={spin}
              spinDuration={[0.2]}
              prizeNumber={prizeNumber}
              data={wheelData}
              outerBorderColor={["transparent"]}
              outerBorderWidth={[1]}
              innerBorderColor={["transparent"]}
              radiusLineWidth={[]}
              textColors={["black"]}
              textDistance={60}
              fontSize={[17]}
              pointerProps={{
                src: "https://images.careerline.org/uploads/spinner_pointer.png",
              }}
              backgroundColors={[
                "#C1EAFF",
                "#F3A6B8",
                "#C1EAFF",
                "#F3A6B8",
                "#C1EAFF",
                "#F3A6B8",
              ]}
              onStopSpinning={(e) => {
                setSpin(false);
                setTimeout(() => {
                  setShowSpinnerModal(false);
                  WheelDataSubmitHandler();
                  // setTimeout(() => {
                  //   setShowModal(true);
                  // }, 200);
                }, 1000);
              }}
            />
            <button
              disabled={hasSpun}
              className="button roulette-button"
              onClick={handleSpinClick}
            >
              Spin
            </button>
          </div>
          <button
            disabled={hasSpun}
            class="spin-button"
            onClick={handleSpinClick}
          >
            Try Your Luck
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default CWCThankyou;
