import React from "react";
import { Link, useNavigate } from "react-router-dom";

const LatestNews = ({
  NewsDate,
  NewsTitle,
  ShortDescription,
  delay,
  NewsURL,
}) => {
  const navigate = useNavigate();

  const handleReadMore = (url) => {
    navigate(`/newsdetail/${url}`);
  };

  const URL = `${window.location.origin}/newsdetail/${NewsURL}/`;

  return (
    <div
      className="artical"
      data-aos="fade-left"
      data-aos-duration="700"
      data-aos-delay={delay}
    >
      <div className="artical-right search-red">
        <span>{NewsDate}</span>
        <Link to={URL}>
          <h5>{NewsTitle}</h5>
        </Link>
        <p>{ShortDescription}</p>
        <a
          onClick={() => handleReadMore(`${NewsURL}/`)}
          style={{
            cursor: "pointer",
          }}
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default LatestNews;
