import React, { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AdmissionSection from "./dynamicMain/AdmissionSection";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const getContactUS = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageContactUsData`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setContactData(data?.Data);
      } else {
        console.log("failed contactdata");
      }
    } catch (error) {
      console.log("Error while fetching coachingdata", error);
    } finally {
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    AOS.init();
    scrollToTop();
    getContactUS();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{contactData?.ContactUsSEO?.MetaTitle}</title>
        <meta
          name="description"
          content={contactData?.ContactUsSEO?.MetaDescription}
        />
        <meta
          name="keywords"
          content={contactData?.ContactUsSEO?.MetaKeyword}
        />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: contactData.ContactUs }} />
      <div className="container mes-form-container">
        <div className="message-section">
          <div className="row align-items-center">
            <div
              className="col-sm-12 col-lg-6"
              dangerouslySetInnerHTML={{ __html: contactData.ContactUsMessage }}
            />
            <AdmissionSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
