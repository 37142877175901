import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CourseSlider = ({ courseData }) => {
  const navigate = useNavigate();

  const handleReadMore = (url) => {
    navigate(`/${url}`);
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIcon
        onClick={onClick}
        className={className}
        width={100}
        style={{
          ...style,
          width: "50px",
          marginRight: "10px",
          height: "30px",
          display: "block",
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
        }}
        icon={faArrowRightLong}
      />
    );
  };

  const settings = {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="popular-section">
      <div className="container">
        <div
          className="popular-section-head"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          <h4>TEST PRE</h4>
          <h2>
            Popular <span>Courses</span>
          </h2>
          <p>
            Choose one appropriate course for you from over multifarious courses{" "}
            <br /> available on this platform.
          </p>
        </div>

        <Slider className="owl-carousel popular owl-theme" {...settings}>
          {courseData.map((course, index) => (
            <div key={index} className="item">
              <div
                className="popular-half"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-delay={index * 200}
              >
                <div className="popular-para">
                  <img
                    src={course.Logo}
                    alt=""
                    style={{ position: "fixed", top: "-30px", left: "20px" }}
                  />
                  <h5 style={{ marginTop: "30px" }}>
                    <a href="#">{course.Title}</a>
                  </h5>

                  <ul>
                    {course.CourseDescription.map((feature, index) => (
                      <li key={index}>{feature.Description}</li>
                    ))}
                  </ul>
                </div>
                <div
                  className="shortlist-red"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    onClick={() =>
                      handleReadMore(`${course.PopularCoursesURL}/`)
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CourseSlider;
