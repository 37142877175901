import React from "react";
import { useLocation } from "react-router-dom";

const PathRow = () => {
  const location = useLocation();
  const url = location.pathname.substring(1);
  const path = url.replace(/\/$/, "").split("/");
  const truncatedPath = path.slice(0, 2).join(" > ");

  if (!truncatedPath) {
    return null;
  }

  return (
    <div className="header-bottom">
      <p>
        Home {">"}{" "}
        {truncatedPath === "events > usauniversityseminar"
          ? "events > USA Universities Seminar"
          : truncatedPath}
      </p>
    </div>
  );
};

export default PathRow;
