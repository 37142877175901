import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false,
}

const dialogSlice = createSlice({
    name: "dialogSlice",
    initialState,
    reducers: {
        openDialog: (state) => {
            state.open = true
        },
        closeDialog: (state) => {
            state.open = false;
        }
    }
});


export default dialogSlice.reducer;
export const { openDialog, closeDialog } = dialogSlice.actions;
