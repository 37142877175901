import React, { useState } from "react";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";
import { useNavigate } from "react-router-dom";
import { Wheel } from "react-custom-roulette";
import { PORTAL_BACKEND_BASE_URL } from "../../../common/constant";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SpinnerWheel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const EventApplicantID = location.state?.EventApplicantID || null;

  const [spin, setSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeTitle, setPrizeTitle] = useState("");
  const [hasSpun, setHasSpun] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const data = [
    {
      id: 1,
      option: "Application for voucher",
    },
    {
      id: 2,
      option: "Coaching Discount Coupon",
    },
    {
      id: 3,
      option: "Coffee Mug",
    },
    {
      id: 4,
      option: "Batter Luck next Time",
    },
    {
      id: 5,
      option: "1000 INR Air Ticket Voucher",
    },
  ];

  const handleSpinClick = () => {
    const validOptions = data.filter(
      (option) => option.option !== "Batter Luck next Time"
    );

    const randomIndex = Math.floor(Math.random() * validOptions.length);
    const randomOption = validOptions[randomIndex];

    setPrizeTitle(randomOption.option);
    setPrizeNumber(randomOption.id - 1);
    setSpin(true);
    setHasSpun(true);
  };

  const WheelDataSubmitHandler = async () => {
    const insertPayload = {
      EventApplicantID: EventApplicantID,
      PriceWheelID: prizeNumber ? prizeNumber + 1 : 0,
    };
    try {
      setLoading(true);
      const response = await fetch(
        `${PORTAL_BACKEND_BASE_URL}/eventregistration/UpdatePriceWheelID`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(insertPayload),
        }
      );
      const responseData = await response.json();
      if (responseData.Status !== 200) {
        ErrorToast(responseData?.Details);
      } else {
        SuccessToast(responseData?.Details);
        navigate("/");
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred during the API call:", error);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <>
      <div align="center" className="roulette-container">
        <Wheel
          mustStartSpinning={spin}
          spinDuration={[0.2]}
          prizeNumber={prizeNumber}
          data={data}
          outerBorderColor={["#ccc"]}
          outerBorderWidth={[8]}
          innerBorderColor={["#f2f2f2"]}
          radiusLineColor={["tranparent"]}
          radiusLineWidth={[1]}
          textColors={["#f5f5f5"]}
          textDistance={55}
          fontSize={[12]}
          backgroundColors={[
            "#3f297e",
            "#175fa9",
            "#169ed8",
            "#239b63",
            "#64b031",
          ]}
          onStopSpinning={(e) => {
            setSpin(false);
            setShowModal(true);
          }}
        />
        <button
          disabled={hasSpun}
          className="button roulette-button"
          onClick={handleSpinClick}
        >
          Spin
        </button>
      </div>
      {showModal && (
        <div className="box-modal">
          {/* <div className="imageBox">
            <img
              src={
                IMAGES[`image${objIndex[this.state.show.split(" ").join("")]}`]
              }
              alt=""
            />
          </div> */}
          <h2 className="title-Win">
            CONGRATULATIONS!!! YOU HAVE WON {prizeTitle} !!!
          </h2>
          <div className="closeContainer">
            <button
              disabled={loading}
              className="closebtn"
              onClick={WheelDataSubmitHandler}
            >
              {loading ? "Submiting" : "Ok"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SpinnerWheel;
