import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  PORTAL_BACKEND_BASE_URL,
  STATUS_ARRAY,
} from "../../../common/constant";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";
import * as Yup from "yup";
import { useEffect } from "react";
import moment from "moment";

const YEARS_ARRAY = [
  {
    label: "Select Year",
    value: "",
  },
  {
    label: "1970",
    value: "1970",
  },
  {
    label: "1971",
    value: "1971",
  },
  {
    label: "1972",
    value: "1972",
  },
  {
    label: "1973",
    value: "1973",
  },
  {
    label: "1974",
    value: "1974",
  },
  {
    label: "1975",
    value: "1975",
  },
  {
    label: "1976",
    value: "1976",
  },
  {
    label: "1977",
    value: "1977",
  },
  {
    label: "1978",
    value: "1978",
  },
  {
    label: "1979",
    value: "1979",
  },
  {
    label: "1980",
    value: "1980",
  },
  {
    label: "1981",
    value: "1981",
  },
  {
    label: "1982",
    value: "1982",
  },
  {
    label: "1983",
    value: "1983",
  },
  {
    label: "1984",
    value: "1984",
  },
  {
    label: "1985",
    value: "1985",
  },
  {
    label: "1986",
    value: "1986",
  },
  {
    label: "1987",
    value: "1987",
  },
  {
    label: "1988",
    value: "1988",
  },
  {
    label: "1989",
    value: "1989",
  },
  {
    label: "1990",
    value: "1990",
  },
  {
    label: "1991",
    value: "1991",
  },
  {
    label: "1992",
    value: "1992",
  },
  {
    label: "1993",
    value: "1993",
  },
  {
    label: "1994",
    value: "1994",
  },
  {
    label: "1995",
    value: "1995",
  },
  {
    label: "1996",
    value: "1996",
  },
  {
    label: "1997",
    value: "1997",
  },
  {
    label: "1998",
    value: "1998",
  },
  {
    label: "1999",
    value: "1999",
  },
  {
    label: "2000",
    value: "2000",
  },
  {
    label: "2001",
    value: "2001",
  },
  {
    label: "2002",
    value: "2002",
  },
  {
    label: "2003",
    value: "2003",
  },
  {
    label: "2004",
    value: "2004",
  },
  {
    label: "2005",
    value: "2005",
  },
  {
    label: "2006",
    value: "2006",
  },
  {
    label: "2007",
    value: "2007",
  },
  {
    label: "2008",
    value: "2008",
  },
  {
    label: "2009",
    value: "2009",
  },
  {
    label: "2010",
    value: "2010",
  },
  {
    label: "2011",
    value: "2011",
  },
  {
    label: "2012",
    value: "2012",
  },
  {
    label: "2013",
    value: "2013",
  },
  {
    label: "2014",
    value: "2014",
  },
  {
    label: "2015",
    value: "2015",
  },
  {
    label: "2016",
    value: "2016",
  },
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
  {
    label: "2027",
    value: "2027",
  },
  {
    label: "2028",
    value: "2028",
  },
  {
    label: "2029",
    value: "2029",
  },
  {
    label: "2030",
    value: "2030",
  },
];

const INTEND_YEAR_ARRAY = [
  {
    label: "Select Year",
    value: "",
  },
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
  {
    label: "2027",
    value: "2027",
  },
  {
    label: "2028",
    value: "2028",
  },
  {
    label: "2029",
    value: "2029",
  },
  {
    label: "2030",
    value: "2030",
  },
];

const INTEND_MONTH_ARRAY = [
  {
    label: "Select Month",
    value: "",
  },
  {
    label: "Jan",
    value: "Jan",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "Sept",
    value: "Sept",
  },
];

const CommonEventRegForm = ({ data, CommonEventID, campaignList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(true);
  const [docs, setDocs] = useState({
    passportCopy: "",
    examResult: "",
    marksheet10: "",
    marksheet12: "",
    diploma: "",
    degree: "",
    master: "",
    schoolLeaving: "",
    experience1: "",
    experience2: "",
  });
  const [initialValues, setInitialValues] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNo: "",
    CountryPreference: [],
    TimeToVisitID: null,
    Details1: "",
    ExamStatus1: "",
    CourseID1: "",
    Address: "",
    Area: "",
    City: "",
    Pincode: "",
    Degree1: "",
    Grade1: "",
    University1: "",
    DegreePasingYear1: "",
    Degree2: "",
    Grade2: "",
    University2: "",
    DegreePasingYear2: "",
    Company1: "",
    FromDate1: "",
    ToDate1: "",
    IsOngoing1: "",
    Company2: "",
    FromDate2: "",
    ToDate2: "",
    IsOngoing2: "",
    EventSourceID: "",
    IntakeYear: "",
    IntakeMonth: "",
    passportCopy: "",
    examResult: "",
    marksheet10: "",
    marksheet12: "",
    diploma: "",
    degree: "",
    master: "",
    schoolLeaving: "",
    experience1: "",
    experience2: "",
    Campaign: "",
  });

  useEffect(() => {
    if (data?.StudentID && data.StudentID !== "add") {
      const fetchStudentDetails = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/Events/getstudentdetails?studentGUID=${data?.StudentID}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const responseData = await response.json();
          if (responseData.Status === 200) {
            const result = responseData?.Data;
            setInitialValues({
              ...initialValues,
              FirstName: result?.FirstName,
              LastName: result?.LastName,
              MobileNo: result?.MobileNo,
              TimeToVisitID: result?.TimeToVisitID,
              EmailID: result?.EmailID,
              ID: result?.ID,
              CountryPreference: result?.CountryPreference
                ? result?.CountryPreference.split(",")?.map(Number)
                : [],
              Address: result?.Address,
              Area: result?.Area,
              City: result?.City,
              Pincode: result?.Pincode,
              Details1: result?.Details1,
              ExamStatus1: result?.ExamStatus1,
              CourseID1: result?.CourseID1,
              Degree1: result?.Degree1,
              Grade1: result?.Grade1,
              University1: result?.University1,
              DegreePasingYear1: result?.DegreePasingYear1,
              Degree2: result?.Degree2,
              Grade2: result?.Grade2,
              University2: result?.University2,
              DegreePasingYear2: result?.DegreePasingYear2,
              Company1: result?.Company1,
              FromDate1: result?.FromDate1
                ? moment(result?.FromDate1, "DD/MM/YYYY").format("YYYY-MM-DD")
                : "",
              ToDate1: result?.ToDate1
                ? moment(result?.ToDate1, "DD/MM/YYYY").format("YYYY-MM-DD")
                : "",
              IsOngoing1: result?.IsOngoing1,
              Company2: result?.Company2,
              FromDate2: result?.FromDate2
                ? moment(result?.FromDate2, "DD/MM/YYYY").format("YYYY-MM-DD")
                : "",
              ToDate2: result?.ToDate2
                ? moment(result?.ToDate2, "DD/MM/YYYY").format("YYYY-MM-DD")
                : "",
              IsOngoing2: result?.IsOngoing2,
              IntakeYear: result?.IntakeYear,
              IntakeMonth: result?.IntakeMonth,
              Campaign: data?.Campaign,
            });
            setDocs({
              ...docs,
              passportCopy: result?.passportCopy,
              examResult: result?.examResult,
              marksheet10: result?.marksheet10,
              marksheet12: result?.marksheet12,
              diploma: result?.diploma,
              degree: result?.degree,
              master: result?.master,
              schoolLeaving: result?.schoolLeaving,
              experience1: result?.experience1,
              experience2: result?.experience2,
            });
          } else {
            console.log("Failed to fetch Student Details");
          }
        } catch (error) {
          console.log("Error while fetching Student Details:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchStudentDetails();
    }
  }, [data?.StudentID]);

  const phoneRegExp = /^[0-9]{6,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .email("Invalid email")
      .required("Email Address is required"),
    TimeToVisitID: Yup.number().required("Time to Visit is required"),
    CountryPreference: Yup.array().min(
      1,
      "At least one country preference is required"
    ),
    Degree1: data?.StudentID
      ? Yup.string().required("Degree 1 is required")
      : Yup.string(),
    Grade1: data?.StudentID
      ? Yup.string().required("Grade 1 is required")
      : Yup.string(),
    University1: data?.StudentID
      ? Yup.string().required("University 1 is required")
      : Yup.string(),
    DegreePasingYear1: data?.StudentID
      ? Yup.string().required("DegreePasingYear 1 is required")
      : Yup.string(),
    IntakeYear: data?.StudentID
      ? Yup.string().required("Intake Year is required")
      : Yup.string(),
    IntakeMonth: data?.StudentID
      ? Yup.string().required("Intake Month is required")
      : Yup.string(),
    Campaign:
      data?.StudentID?.toLowerCase() === "add"
        ? Yup.string().required("Campaign is required")
        : Yup.string(),
  });

  const handleCheckboxChange = (countryID) => {
    const { CountryPreference } = formik.values;
    const updatedPreferences = CountryPreference.includes(countryID)
      ? CountryPreference.filter((ID) => ID !== countryID)
      : [...CountryPreference, countryID];
    formik.setFieldValue("CountryPreference", updatedPreferences);
  };

  const onSubmit = async (values) => {
    if (data?.StudentID) {
      const editPayload = {
        ID: values.ID ? +values.ID : null,
        FirstName: values.FirstName ? values.FirstName : "",
        LastName: values.LastName ? values.LastName : "",
        EmailID: values.EmailID ? values.EmailID : "",
        MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
        CountryPreference: values.CountryPreference
          ? values.CountryPreference?.join(",")
          : "",
        TimeToVisitID: values.TimeToVisitID ? +values.TimeToVisitID : "",
        CourseID1: values.CourseID1 ? +values.CourseID1 : null,
        ExamStatus1: values.ExamStatus1 ? +values.ExamStatus1 : null,
        Details1: values.Details1 ? values.Details1 : "",
        Address: values.Address ? values.Address : "",
        Area: values.Area ? values.Area : "",
        City: values.City ? values.City : "",
        Pincode: values.Pincode ? values.Pincode : "",
        Degree1: values.Degree1 ? values.Degree1 : "",
        Grade1: values.Grade1 ? values.Grade1 : "",
        University1: values.University1 ? values.University1 : "",
        DegreePasingYear1: values.DegreePasingYear1
          ? values.DegreePasingYear1
          : "",
        Degree2: values.Degree2 ? values.Degree2 : "",
        Grade2: values.Grade2 ? values.Grade2 : "",
        University2: values.University2 ? values.University2 : "",
        DegreePasingYear2: values.DegreePasingYear2
          ? values.DegreePasingYear2
          : "",
        Company1: values.Company1 ? values.Company1 : "",
        FromDate1: values.FromDate1 ? values.FromDate1 : "",
        ToDate1: values.ToDate1 ? values.ToDate1 : "",
        IsOngoing1: values.IsOngoing1 ? values.IsOngoing1 : false,
        Company2: values.Company2 ? values.Company2 : "",
        FromDate2: values.FromDate2 ? values.FromDate2 : "",
        ToDate2: values.ToDate2 ? values.ToDate2 : "",
        IsOngoing2: values.IsOngoing2 ? values.IsOngoing2 : false,
        IntakeYear: values.IntakeYear ? values.IntakeYear : "",
        IntakeMonth: values.IntakeMonth ? values.IntakeMonth : "",
        EventID: CommonEventID ? +CommonEventID : null,
        StudentGUID:
          data?.StudentID?.toLowerCase() === "add"
            ? "3fa85f64-5717-4562-b3fc-2c963f66afa6"
            : data?.StudentID,
        Campaign:
          data?.StudentID?.toLowerCase() === "add"
            ? values.Campaign
            : data.Campaign
            ? data.Campaign
            : "website",
        IsWalkin: data?.StudentID?.toLowerCase() === "add" ? true : false,
      };
      try {
        setLoading(true);
        const response = await fetch(
          data?.StudentID?.toLowerCase() === "add"
            ? `${PORTAL_BACKEND_BASE_URL}/Events/AddWalkinEventRegistraton`
            : `${PORTAL_BACKEND_BASE_URL}/Events/EditEventRegistraton`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editPayload),
          }
        );
        const responseData = await response.json();
        if (responseData.Status !== 200) {
          ErrorToast(responseData?.Details);
        } else {
          // CHAGES NEEDED FOR STUDENTID AND ALSO CHANGES ALL THE PAYLOAD
          if (
            responseData?.Data?.EventApplicantID &&
            (values.passportCopy ||
              values.examResult ||
              values.marksheet10 ||
              values.marksheet12 ||
              values.diploma ||
              values.degree ||
              values.master ||
              values.schoolLeaving ||
              values.experience1 ||
              values.experience2)
          ) {
            setLoading(true);
            const formData = new FormData();
            formData.append(
              "passportCopy",
              values.passportCopy ? values.passportCopy : ""
            );
            formData.append(
              "examResult",
              values.examResult ? values.examResult : ""
            );
            formData.append(
              "marksheet10",
              values.marksheet10 ? values.marksheet10 : ""
            );
            formData.append(
              "marksheet12",
              values.marksheet12 ? values.marksheet12 : ""
            );
            formData.append("diploma", values.diploma ? values.diploma : "");
            formData.append("degree", values.degree ? values.degree : "");
            formData.append("master", values.master ? values.master : "");
            formData.append(
              "schoolLeaving",
              values.schoolLeaving ? values.schoolLeaving : ""
            );
            formData.append(
              "experience1",
              values.experience1 ? values.experience1 : ""
            );
            formData.append(
              "experience2",
              values.experience2 ? values.experience2 : ""
            );

            const imageResponse = await fetch(
              `${PORTAL_BACKEND_BASE_URL}/Events/AddEventDocuments?ID=${responseData?.Data?.EventApplicantID}`,
              {
                method: "POST",
                body: formData,
              }
            );
            const imageResponseData = await imageResponse.json();
            if (imageResponseData.Status !== 200) {
              setLoading(false);
              ErrorToast(imageResponseData?.Details);
              // return;
            } else {
              setLoading(false);
              setShowSuccessToast(false);
              SuccessToast(imageResponseData?.Details);
              navigate("/events/thankyou/");
            }
          }
          if (showSuccessToast) {
            SuccessToast(responseData?.Details);
          }
          navigate("/events/thankyou/");
        }
      } catch (error) {
        ErrorToast(error?.message || "Network Error");
        console.error("An error occurred during the API call:", error);
      } finally {
        setLoading(false);
      }
    } else {
      const insertPayload = {
        FirstName: values.FirstName ? values.FirstName : "",
        LastName: values.LastName ? values.LastName : "",
        EmailID: values.EmailID ? values.EmailID : "",
        MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
        CountryPreference: values.CountryPreference
          ? values.CountryPreference?.join(",")
          : "",
        TimeToVisitID: values.TimeToVisitID ? +values.TimeToVisitID : "",
        EventID: CommonEventID ? CommonEventID : null,
        Campaign: data.Campaign ? data.Campaign : "website",
      };
      try {
        setLoading(true);
        const response = await fetch(
          `${PORTAL_BACKEND_BASE_URL}/Events/AddEventRegistraton`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(insertPayload),
          }
        );
        const responseData = await response.json();
        if (responseData.Status !== 200) {
          ErrorToast(responseData?.Details);
        } else {
          SuccessToast(responseData?.Details);
          navigate("/events/thankyou/");
        }
      } catch (error) {
        ErrorToast(error?.message || "Network Error");
        console.error("An error occurred during the API call:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <>
      {/* card rows form starting */}
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* left sided card */}
          {!data.StudentID && (
            <div className="col-xl-6 d-flex m-auto">
              {/* <div className="card flex-fill"> */}
              {/* <div className="card-header">
                <h5 className="card-title mb-0">Basic Information</h5>
              </div> */}
              <div className="card-body">
                <div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label education-form-label">
                      First Name<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="FirstName"
                        type="text"
                        id="FirstName"
                        className={`form-control ${
                          formik.errors.FirstName &&
                          formik.touched.FirstName &&
                          "error-border"
                        }`}
                        maxlength="50"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FirstName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label education-form-label">
                      Last Name<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="LastName"
                        type="text"
                        id="LastName"
                        className={`form-control ${
                          formik.errors.LastName &&
                          formik.touched.LastName &&
                          "error-border"
                        }`}
                        maxlength="50"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.LastName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label education-form-label">
                      Email Address<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="EmailID"
                        type="email"
                        id="EmailID"
                        className={`form-control ${
                          formik.errors.EmailID &&
                          formik.touched.EmailID &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.EmailID}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label education-form-label">
                      Mobile No<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="MobileNo"
                        type="number"
                        id="MobileNo"
                        className={`form-control ${
                          formik.errors.MobileNo &&
                          formik.touched.MobileNo &&
                          "error-border"
                        }`}
                        max={9999999999}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.MobileNo}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label education-form-label">
                      Time to Visit<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <select
                        id="TimeToVisitID"
                        name="TimeToVisitID"
                        className={`form-control ${
                          formik.errors.TimeToVisitID &&
                          formik.touched.TimeToVisitID &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.TimeToVisitID}
                      >
                        <option value={null} label={"Select Time"} />
                        {data?.TimeSlotList?.map((item) => {
                          return (
                            <option value={item?.ID} label={item?.TimeSlot} />
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label education-form-label">
                      Country Preference<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <table
                        className={`form-control tabel-border-none ${
                          formik.errors.CountryPreference &&
                          formik.touched.CountryPreference &&
                          "error-border"
                        }`}
                        id="CountryPreference"
                      >
                        <tbody>
                          {data?.EventContry?.map((item) => (
                            <tr key={item.ID}>
                              <td>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={formik.values.CountryPreference.includes(
                                      item.ID
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(item.ID)
                                    }
                                  />
                                  {item.CountryName}
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!data.StudentID && (
                    <div className="col-lg-8 offset-lg-4">
                      <div style={{ textAlign: "center" }}>
                        <button
                          id="submit-btn"
                          style={{ background: "#2e4993" }}
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Submiting" : "Submit Details"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
          {data.StudentID && (
            <div className="col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5 className="card-title mb-0">Basic Information</h5>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        First Name<code>*</code>
                      </label>
                      <div className="col-lg-8">
                        <input
                          name="FirstName"
                          type="text"
                          id="FirstName"
                          className={`form-control ${
                            formik.errors.FirstName &&
                            formik.touched.FirstName &&
                            "error-border"
                          }`}
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.FirstName}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Last Name<code>*</code>
                      </label>
                      <div className="col-lg-8">
                        <input
                          name="LastName"
                          type="text"
                          id="LastName"
                          className={`form-control ${
                            formik.errors.LastName &&
                            formik.touched.LastName &&
                            "error-border"
                          }`}
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.LastName}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Email Address<code>*</code>
                      </label>
                      <div className="col-lg-8">
                        <input
                          name="EmailID"
                          type="email"
                          id="EmailID"
                          className={`form-control ${
                            formik.errors.EmailID &&
                            formik.touched.EmailID &&
                            "error-border"
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.EmailID}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Mobile No<code>*</code>
                      </label>
                      <div className="col-lg-8">
                        <input
                          name="MobileNo"
                          type="number"
                          id="MobileNo"
                          className={`form-control ${
                            formik.errors.MobileNo &&
                            formik.touched.MobileNo &&
                            "error-border"
                          }`}
                          max={9999999999}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.MobileNo}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Time to Visit<code>*</code>
                      </label>
                      <div className="col-lg-8">
                        <select
                          id="TimeToVisitID"
                          name="TimeToVisitID"
                          className={`form-control ${
                            formik.errors.TimeToVisitID &&
                            formik.touched.TimeToVisitID &&
                            "error-border"
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.TimeToVisitID}
                        >
                          <option value={null} label={"Select Time"} />
                          {data?.TimeSlotList?.map((item) => {
                            return (
                              <option value={item?.ID} label={item?.TimeSlot} />
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Country Preference<code>*</code>
                      </label>
                      <div className="col-lg-8">
                        <table
                          className={`form-control tabel-border-none ${
                            formik.errors.CountryPreference &&
                            formik.touched.CountryPreference &&
                            "error-border"
                          }`}
                          id="CountryPreference"
                        >
                          <tbody>
                            {data?.EventContry?.map((item) => (
                              <tr key={item.ID}>
                                <td>
                                  <label>
                                    <input
                                      className="me-2"
                                      type="checkbox"
                                      checked={formik.values.CountryPreference.includes(
                                        item.ID
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(item.ID)
                                      }
                                    />
                                    {item.CountryName}
                                  </label>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!data.StudentID && (
                      <div style={{ textAlign: "end" }}>
                        <button
                          id="submit-btn"
                          className="btn btn-primary"
                          style={{ background: "#2e4993" }}
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Submiting" : "Submit Details"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* right sided card */}
          {data.StudentID && (
            <div className="col-xl-6 mt-4 mt-xl-0">
              <div className="card mb-4">
                <div className="card-header">
                  <h5 className="card-title mb-0">Address Form</h5>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">Address</label>
                      <div className="col-lg-8">
                        <input
                          name="Address"
                          type="text"
                          id="Address"
                          className="form-control"
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Address}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">Area</label>
                      <div className="col-lg-8">
                        <input
                          name="Area"
                          type="text"
                          id="Area"
                          className="form-control"
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Area}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">City</label>
                      <div className="col-lg-8">
                        <input
                          name="City"
                          type="text"
                          id="City"
                          className="form-control"
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.City}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Postal Code
                      </label>
                      <div className="col-lg-8">
                        <input
                          name="Pincode"
                          type="text"
                          id="Pincode"
                          className="form-control"
                          maxlength="50"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Pincode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Exam details</h5>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Select Exam
                      </label>
                      <div className="col-lg-8">
                        <select
                          id="CourseID1"
                          name="CourseID1"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.CourseID1}
                        >
                          <option value={null} label={"Select Exam"} />
                          {data?.EventCourse?.map((item) => {
                            return (
                              <option value={item.ID} label={item.CourseName} />
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">Status</label>
                      <div className="col-lg-8">
                        <select
                          id="ExamStatus1"
                          name="ExamStatus1"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ExamStatus1}
                        >
                          {STATUS_ARRAY.map((item) => {
                            return (
                              <option value={item.value} label={item.label} />
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">Details</label>
                      <div className="col-lg-8">
                        <textarea
                          name="Details1"
                          type="text"
                          id="Details1"
                          rows={3}
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Details1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* second Row */}
        {data.StudentID && (
          <div className="row mt-4">
            <div className="col-md-12 ">
              <div className="card mb-0">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Educational & Experience Details
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <h6
                      style={{ textDecoration: "underline" }}
                      className="card-title mb-0"
                    >
                      Educational Information
                    </h6>
                  </div>
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h6 className="card-title mb-0">Degree 1</h6>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Degree<code>*</code>
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="Degree1"
                              type="text"
                              id="Degree1"
                              className={`form-control ${
                                formik.errors.Degree1 &&
                                formik.touched.Degree1 &&
                                "error-border"
                              }`}
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Degree1}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Grades<code>*</code>
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="Grade1"
                              type="text"
                              id="Grade1"
                              className={`form-control ${
                                formik.errors.Grade1 &&
                                formik.touched.Grade1 &&
                                "error-border"
                              }`}
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Grade1}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            University<code>*</code>
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="University1"
                              type="text"
                              id="University1"
                              className={`form-control ${
                                formik.errors.University1 &&
                                formik.touched.University1 &&
                                "error-border"
                              }`}
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.University1}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Passing Year<code>*</code>
                          </label>
                          <div className="col-lg-8">
                            <select
                              id="DegreePasingYear1"
                              name="DegreePasingYear1"
                              className={`form-control ${
                                formik.errors.DegreePasingYear1 &&
                                formik.touched.DegreePasingYear1 &&
                                "error-border"
                              }`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.DegreePasingYear1}
                            >
                              {YEARS_ARRAY.map((item) => {
                                return (
                                  <option
                                    value={item.value}
                                    label={item.label}
                                  />
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="row ms-xl-3 mt-3 mt-xl-0">
                        <div className="col-lg-12 mb-4">
                          <h6 className="card-title mb-0">Degree 2</h6>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Degree
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="Degree2"
                              type="text"
                              id="Degree2"
                              className="form-control"
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Degree2}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Grades
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="Grade2"
                              type="text"
                              id="Grade2"
                              className="form-control"
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Grade2}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            University
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="University2"
                              type="text"
                              id="University2"
                              className="form-control"
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.University2}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Passing Year
                          </label>
                          <div className="col-lg-8">
                            <select
                              id="DegreePasingYear2"
                              name="DegreePasingYear2"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.DegreePasingYear2}
                            >
                              {YEARS_ARRAY.map((item) => {
                                return (
                                  <option
                                    value={item.value}
                                    label={item.label}
                                  />
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <h6
                      style={{ textDecoration: "underline" }}
                      className="card-title mb-0"
                    >
                      Experience Details
                    </h6>
                  </div>
                  <div className="row mb-0">
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h6 className="card-title mb-0">Experience 1</h6>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Company Name
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="Company1"
                              type="text"
                              id="Company1"
                              className="form-control"
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Company1}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            From Date
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="FromDate1"
                              type="date"
                              id="FromDate1"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.FromDate1}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            To Date
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="ToDate1"
                              type="date"
                              id="ToDate1"
                              className="form-control"
                              // min={}
                              disabled={formik?.values?.IsOngoing1}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ToDate1}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="IsOngoing1"
                            className="col-lg-4 col-form-label"
                          >
                            On Going
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="IsOngoing1"
                              type="checkbox"
                              id="IsOngoing1"
                              className="form-check-input"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "IsOngoing1",
                                  event.target.checked
                                );
                                formik.setFieldValue(
                                  "ToDate1",
                                  moment().format("YYYY-MM-DD")
                                );
                              }}
                              checked={formik.values.IsOngoing1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="row ms-xl-3 mt-3 mt-xl-0">
                        <div className="col-lg-12 mb-4">
                          <h6 className="card-title mb-0">Experience 2</h6>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Company Name
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="Company2"
                              type="text"
                              id="Company2"
                              className="form-control"
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Company2}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            From Date
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="FromDate2"
                              type="date"
                              id="FromDate2"
                              className="form-control"
                              maxlength="50"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.FromDate2}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            To Date
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="ToDate2"
                              type="date"
                              id="ToDate2"
                              className="form-control"
                              maxlength="50"
                              disabled={formik?.values?.IsOngoing2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ToDate2}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="IsOngoing2"
                            className="col-lg-4 col-form-label"
                          >
                            On Going
                          </label>
                          <div className="col-lg-8">
                            <input
                              name="IsOngoing2"
                              type="checkbox"
                              id="IsOngoing2"
                              className="form-check-input"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "IsOngoing2",
                                  event.target.checked
                                );
                                formik.setFieldValue(
                                  "ToDate2",
                                  moment().format("YYYY-MM-DD")
                                );
                              }}
                              checked={formik.values.IsOngoing2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* third Row */}
        {data.StudentID && (
          <div className="row mt-4">
            <div className="col-md-12 ">
              <div className="card mb-0">
                <div className="card-header">
                  <h5 className="card-title mb-0">Other details</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Intended Intake<code>*</code>
                      </label>
                      <div className="col-lg-4">
                        <select
                          id="IntakeYear"
                          name="IntakeYear"
                          className={`form-control ${
                            formik.errors.IntakeYear &&
                            formik.touched.IntakeYear &&
                            "error-border"
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.IntakeYear}
                        >
                          {INTEND_YEAR_ARRAY.map((item) => {
                            return (
                              <option value={item.value} label={item.label} />
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-4 mt-3 mt-xl-0">
                        <select
                          id="IntakeMonth"
                          name="IntakeMonth"
                          className={`form-control ${
                            formik.errors.IntakeMonth &&
                            formik.touched.IntakeMonth &&
                            "error-border"
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.IntakeMonth}
                        >
                          {INTEND_MONTH_ARRAY.map((item) => {
                            return (
                              <option value={item.value} label={item.label} />
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* fourth Row */}
        {data?.StudentID?.toLowerCase() === "add" && (
          <div className="row mt-4">
            <div className="col-md-12 ">
              <div className="card mb-0">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    How did you come to know about this event?
                  </h5>
                </div>
                <div className="card-body">
                  <div className="col-lg-4 mt-3 mt-xl-0">
                    <select
                      id="Campaign"
                      name="Campaign"
                      className={`form-control ${
                        formik.errors.Campaign &&
                        formik.touched.Campaign &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Campaign}
                    >
                      <option value={""} label={"Select Campaign"} />
                      {campaignList.map((item) => {
                        return <option value={item.label} label={item.label} />;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* fifth Row */}
        {data.StudentID && (
          <div className="row mt-4">
            <div className="col-md-12 ">
              <div className="card mb-0">
                <div className="card-header">
                  <h5 className="card-title mb-0">Documents</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Passport Copy
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="passportCopy"
                              className="form-control"
                              name="passportCopy"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "passportCopy",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.passportCopy && (
                              <Link to={docs.passportCopy} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Result
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="examResult"
                              className="form-control"
                              name="examResult"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "examResult",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.examResult && (
                              <Link to={docs.examResult} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            10th Marksheet
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="marksheet10"
                              className="form-control"
                              name="marksheet10"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "marksheet10",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.marksheet10 && (
                              <Link to={docs.marksheet10} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            12th Marksheet
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="marksheet12"
                              className="form-control"
                              name="marksheet12"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "marksheet12",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.marksheet12 && (
                              <Link to={docs.marksheet12} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Diploma
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="diploma"
                              className="form-control"
                              name="diploma"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "diploma",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.diploma && (
                              <Link to={docs.diploma} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="row ms-xl-3">
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Degree
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="degree"
                              className="form-control"
                              name="degree"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "degree",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.degree && (
                              <Link to={docs.degree} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Master
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="master"
                              className="form-control"
                              name="master"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "master",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.master && (
                              <Link to={docs.master} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            School Leaving
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="schoolLeaving"
                              className="form-control"
                              name="schoolLeaving"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "schoolLeaving",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.schoolLeaving && (
                              <Link to={docs.schoolLeaving} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Experience 1
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="experience1"
                              className="form-control"
                              name="experience1"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "experience1",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.experience1 && (
                              <Link to={docs.experience1} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Experience 2
                          </label>
                          <div className="col-lg-8 text-end">
                            <input
                              type="file"
                              id="experience2"
                              className="form-control"
                              name="experience2"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "experience2",
                                  event.target.files[0]
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {docs.experience2 && (
                              <Link to={docs.experience2} target="_blank">
                                View Document
                              </Link>
                            )}
                          </div>
                        </div>
                        {data.StudentID && (
                          <div className="form-group row mb-0 text-sm-end text-center">
                            <div className="col-lg-8 offset-lg-4">
                              <button
                                id="submit-btn"
                                style={{ background: "#2e4993" }}
                                className="btn btn-primary"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? "Submiting" : "Submit Details"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default CommonEventRegForm;
