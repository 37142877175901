import React, { useEffect, useState } from "react";
import AOS from "aos";
import CommonEventBannerSection from "./CommonEventBannerSection";
import CommonEventRegForm from "./CommonEventRegForm";
import CommonVideoSection from "./CommonVideoSection";
import { getYouTubeVideoId } from "../../../common/commonHelpers";
import {
  BACKEND_BASE_URL,
  PORTAL_BACKEND_BASE_URL,
} from "../../../common/constant";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";

const EducationExpoMainRegForm = () => {
  const { Campaign, StudentID } = useParams();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [data, setData] = useState({});
  const [commonEventID, setCommonEventID] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("campaignList", campaignList);

  useEffect(() => {
    scrollToTop();
    AOS.init();
    const fetchHomePage = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${BACKEND_BASE_URL}/Master/ManageCMSDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ CMSURL: "CommonEventID" }),
          }
        );
        const data = await response.json();
        if (data.Status === 200) {
          setCommonEventID(data?.Data?.Template);
        } else {
          console.log("Failed to fetch blogs");
        }
      } catch (error) {
        console.log("Error while fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomePage();
    // return () => {
    //   dispatch(clearEventID());
    // };
  }, []);

  useEffect(() => {
    if (commonEventID) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/findbyid?id=${commonEventID}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const Response = await response.json();
          const result = Response?.Data;
          const updatedVideoUrls = result?.VideoURL.map((item) => {
            const videoId = getYouTubeVideoId(item.VideoURL);
            return { VideoID: videoId, ...item };
          });
          setData({
            BannerImage: result?.BannerImage,
            SideBanner: result?.SideBanner,
            CMSHeader: result?.CMSHeader,
            CMSFooter: result?.CMSFooter,
            EventCourse: result?.EventCourse ? result?.EventCourse : [],
            EventContry: result?.EventContry ? result?.EventContry : [],
            TimeSlotList: result?.TimeSlotList ? result?.TimeSlotList : [],
            VideoURL: updatedVideoUrls ? updatedVideoUrls : [],
            RegisteredPercentage: result?.RegisteredPercentage
              ? result?.RegisteredPercentage
              : null,
            Campaign: Campaign,
            StudentID: StudentID,
          });
        } catch (error) {
          console.error(error);
          // setError(error?.message || "NetWrok Error");
        } finally {
          setLoading(false);
        }
      };
      fetchData();

      // if (StudentID?.toLowerCase() === "add") {
        const getAllCampaignTypes = async () => {
          try {
            const response = await fetch(
              `${PORTAL_BACKEND_BASE_URL}/eventregistration/CampaignEventWise`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  EventID: commonEventID,
                }),
              }
            );
            if (!response.ok) {
              throw new Error("Network Error fetching campaign types");
            }
            const result = await response.json();
            const dataList = result?.Data;
            const finalResult = dataList
              ?.map((campaign) => {
                return {
                  value: campaign.ID,
                  label: campaign.CampaignName,
                };
              });
            setCampaignList(finalResult || []);
          } catch (error) {
            console.error("Error fetching campaign:", error);
          }
        };
        getAllCampaignTypes();
      // }
    }
  }, [commonEventID]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="education-event-reg-form">
        <CommonEventRegForm
          data={data}
          CommonEventID={commonEventID}
          campaignList={campaignList}
          setLoading={setLoading}
        />
      </div>
      <div className="row p-3">
        {data?.CMSHeader && (
          <div className="event-reg-form col-lg-6">
            <div className="crms-header-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.CMSHeader,
                }}
              />
            </div>
          </div>
        )}
        {data?.CMSFooter && (
          <div className="event-reg-form col-lg-6">
            <div className="crms-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.CMSFooter,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <CommonVideoSection data={data} />
      </div>
    </div>
  );
};

export default EducationExpoMainRegForm;
