import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CoachingForm from "./dynamicMain/CoachingForm";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BACKEND_BASE_URL } from "../../common/constant";
import CoachingFAG from "./dynamicMain/CoachingFAG";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { Helmet } from "react-helmet";

const Coaching = () => {
  const [coachingData, setCoachingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const { coachingname } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const CoachingName = [
    {
      value: 1,
      label: "ielts",
    },
    {
      value: 2,
      label: "gre",
    },
    // {
    //   value: 3,
    //   label: "toefl",
    // },
    {
      value: 4,
      label: "pte",
    },
    {
      value: 5,
      label: "sat",
    },
  ];
  const selectedCoaching = CoachingName.find(
    (coachings) => coachings.label === coachingname
  );
  const selectedCoachingID = selectedCoaching ? selectedCoaching.value : null;
  const fetchCoachings = async (selectedCoachingID) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageCoaching`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ID: selectedCoachingID, OperationType: 4 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setCoachingData(data?.Data[0]);
      } else {
        console.log("failed coachingdata");
      }
    } catch (error) {
      console.log("Error while fetching coachingdata", error);
    } finally {
      setLoading(false);
    }
  };
  const faqofChoching = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageFAQ`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID: selectedCoachingID, OperationType: 4 }),
      });
      const data = await response.json();
      if (data.Status === 200) {
        setFaqData(data?.Data[0]);
      } else {
        console.log("failed coachingdata");
      }
    } catch (error) {
      console.log("Error while fetching coachingdata", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoachings(selectedCoachingID);
  }, [selectedCoachingID]);
  useEffect(() => {
    faqofChoching(selectedCoachingID);
  }, [selectedCoachingID]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [selectedCoachingID]);
  React.useEffect(() => {
    AOS.init();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Helmet>
        <title>{coachingData?.MetaTitle}</title>
        <meta name="description" content={coachingData?.MetaDescription} />
        <meta name="keywords" content={coachingData?.MetaKeyword} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: coachingData.Banner,
        }}
      />
      <div className="container">
        <div className="ielts-coaching">
          <div className="row">
            {coachingData.AboutCourse && (
              <div
                className="col-sm-12 col-md-12 col-lg-6"
                dangerouslySetInnerHTML={{ __html: coachingData.AboutCourse }}
              />
            )}
            <CoachingForm />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: coachingData.WhyCareerline,
        }}
      />
      {coachingData.Highlights && (
        <div dangerouslySetInnerHTML={{ __html: coachingData.Highlights }} />
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: coachingData.Features,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: coachingData.TestFormat,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: coachingData.TrainingModules,
        }}
      />
      <div className="faq-section">
        <div className="container">
          <div className="faq-title">
            <h2>Frequently Asked Questions</h2>
            <p> {faqData?.Title}</p>
          </div>
          <div className="accordion" id="accordionExample">
            <CoachingFAG faqData={faqData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Coaching;
