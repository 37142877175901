import React from "react";
import Article from "./Artical";

const ArticalList = ({ articalData }) => {
  return (
    <>
      {articalData.map((article, index) => (
        <Article
          key={index}
          {...article}
        />
      ))}
    </>
  );
};

export default ArticalList;
