import React, { useEffect } from "react";
import BANNERIMG from "../../../assets/image/Coaching IELTS img layout.png";
import DynamicBanner from "../../coaching/dynamicMain/DynamicBanner";
import { Link } from "react-router-dom";

const EventThankyou = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <React.Fragment>
      <DynamicBanner title={"Thank You"} imageSrc={BANNERIMG} />
      <div className="post-inner" id="post-inner">
        <div className="entry-content">
          <div className="vc_row wpb_row vc_row-fluid about-section1">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid about-container">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                            <div className="wpb_wrapper">
                              <section className="login-main-wrapper">
                                <div className="main-container">
                                  <div className="login-process">
                                    <div className="login-main-container">
                                      <div className="thankyou-wrapper">
                                        <h1>
                                          <img
                                            src="http://www.websiteimages.careerline.in/uploads/thankyou.png"
                                            alt="Thanks"
                                          />
                                        </h1>
                                        <p>
                                          for contacting us, we will get in
                                          touch with you soon...
                                        </p>
                                        <Link to="/">Back to home</Link>
                                        <div className="clr"></div>
                                      </div>
                                      <div className="clr"></div>
                                    </div>
                                  </div>
                                  <div className="clr"></div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventThankyou;
