import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";

const CoachingFAG = ({ faqData }) => {
  const [activeKey, setActiveKey] = useState("0"); // Track active items
  const toggleAccordion = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  return (
    <>
      <Accordion activeKey={activeKey} onSelect={toggleAccordion}>
        {faqData?.FAQLIST?.map((item, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>{item.FAQ}</Accordion.Header>
            <Accordion.Body>
              <p dangerouslySetInnerHTML={{ __html: item.Answer }}></p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default CoachingFAG;
