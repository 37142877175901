import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import BlogListingItem from "./dynamicMain/BlogListingItem";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blogBanner, setBlogBanner] = useState("");
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    AOS.init();
    scrollToTop();
  }, []);

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("OperationType", 5);

      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageBlog`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.Status === 200) {
        setBlogData(data);
      } else {
        console.log("Failed to fetch news");
      }
    } catch (error) {
      console.error("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHome = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageCMSDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ CMSURL: "BlogBanner" }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setBlogBanner(data?.Data?.Template);
      } else {
        console.log("Failed to fetch news");
      }
    } catch (error) {
      console.error("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchHome();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{blogData?.MetaTitle}</title>
        <meta name="description" content={blogData?.MetaDescription} />
        <meta name="keywords" content={blogData?.MetaKeyword} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: blogBanner,
        }}
      />
      <div className="container">
        <div className="blog-section">
          <div className="blog-section-heading">
            <div className="row">
              <div
                className="col-sm-12 col-md-8 col-lg-9"
                data-aos="fade-up"
                data-aos-duration="700"
              ></div>
            </div>
          </div>
          <div className="row blog-boxes">
            {blogData?.Data?.map((item, index) => (
              <BlogListingItem key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Blog;
