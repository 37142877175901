import React from "react";
import "./GRELandingPageStyle.css";
import CURRRICULUM from "../../assets/image/IELTS EXAM/curriculam.jpg";
import INTERACTIVETEACHING from "../../assets/image/IELTS EXAM/interactiveteaching.jpg";
import DOUBTCLEARING from "../../assets/image/IELTS EXAM/doubtclearing.jpeg";
import ADVANCEACCESS from "../../assets/image/IELTS EXAM/advanceportalaccess.jpeg";
import SUCCESS from "../../assets/image/IELTS EXAM/success.png";
import AVAILABILITY from "../../assets/image/IELTS EXAM/2407availabel.png";
import EXPEFACULTIES from "../../assets/image/IELTS EXAM/experincedfaculties.png";
import GRECOUNSELLORS from "../../assets/image/IELTS EXAM/grecounsellors.png";
import PERFORMANCE from "../../assets/image/IELTS EXAM/performance.png";
import PATTENSION from "../../assets/image/IELTS EXAM/personalizedattention.png";
import Shreya from "../../assets/image/testimonial shreya.jpeg";
import Bansi from "../../assets/image/testimonial bansi.jpg";
import Mahadev from "../../assets/image/testimonial mahadev.jpg";
import Prashant from "../../assets/image/testimonial prashant.jpeg";
import Sanya from "../../assets/image/sanya shah.jpg";
import Krish from "../../assets/image/krish joshi.jpg";
import Manank from "../../assets/image/manank.jpg";
import Nimesh from "../../assets/image/nimesh shadhu.jpg";
import Aaryan from "../../assets/image/aaryan.jpg";
import WomenAvatar from "../../assets/image/women avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GRELandingPageForm from "./GRELandingPageForm";
import { Link } from "react-scroll";
import InvertedComma from "../../assets/image/white-quoter.png";
import Slider from "react-slick";

const FAQsData = [
  {
    title:
      "What is the minimum GRE score required for admission to universities abroad?",
    content:
      "Minimum GRE score requirements vary by university and program. We assist in researching and understanding the specific score expectations of the institutions you're interested in.",
  },
  {
    title: "Is the GRE compulsory for MS in the USA?",
    content:
      "The GRE is required by most top universities in the US to study the MS program. For other universities, it might be optional but usually preferred as an additional add-on to make your profile stand out from the crowd.",
  },
  {
    title: "What is the syllabus for GRE?",
    content:
      "The GRE exam syllabus measures verbal reasoning, critical thinking, analytical writing skills, and quantitative reasoning. The syllabus is broadly divided into three sections – Analytical Writing, Quantitative reasoning, and Verbal Reasoning.",
  },
];

const testimonialData = [
  {
    img: `${Shreya}`,
    description:
      "I had an exceptional experience with Kathan Sir, Maulin Sir, and Nikhil Sir for my USA study visa counseling! Their expertise, guidance, and support throughout the process were invaluable. Together, they made a fantastic team! Their dedication, professionalism, and kindness made my study visa journey seamless and successful. I highly recommend them to anyone seeking guidance for their study visa applications. Thank you, Kathan Sir, Maulin Sir, and Nikhil Sir, for your outstanding support and mentorship!",
    name: "Shreya Vaghela",
    pragram: "Master of Science in Artificial Intelligence",
    university: "Long Island University",
    intake: "SEPT_2024",
    country: "USA",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Bansi}`,
    description:
      "I had an amazing experience at Careerline education during my all visa process. the counsellors are very knowledgeable & supportive. I would especially like to thank Hetal Ma'am & Kishan sir who helped me throughout the all process and solved my all queries without giving me any stress. really Careerline Education provide excellent service to their all students..💯🤝",
    name: "Bansi Savaliya",
    pragram: "MSc Biomedical Laboratory Science",
    university: "Sheffield Hallam University",
    intake: "SEPT_2024",
    country: "UK",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Mahadev}`,
    description:
      "I applied for my UK visa as well as had my IELTS preparation from Careerline and they have been supportive to me at every step of the way especially Kishan sir and Anshul sir guiding me through all the universities of the UK and helping me apply for admission and visa. They have always been swift in solving any and all queries I’ve had during the process. My experience with IELTS classes was also great and had support from highly skilled faculties especially Poonam mam who conducted all of my lectures and solved my doubts, supported me wherever I lacked and made sure I was ready for my examination. I highly recommend Careerline to everyone who is planning on going abroad for further education.",
    name: "Mahadev Vaghela",
    pragram: "MSc Astrophysics",
    university: "University of Glasgow",
    intake: "SEPT_2024",
    country: "UK",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${Prashant}`,
    description:
      "I am delighted to share my positive experience working with Careeline during my student visa process for Newzeland, for my master’s degree at AUT. From the very beginning, Mr Hardik Sir demonstrated exceptional expertise and dedication. What stood out the most was their unwavering support and patience. No matter how many questions I had or how many times I needed clarification, Hardik Sir was always available and willing to assist. They provided timely updates and kept me informed at every step, making the whole process smooth and stress-free. Thanks to Hardik Sir and his team's expertise and assistance, I successfully obtained my student visa and am now excited to start my studies at AUT. I highly recommend Careerline to anyone seeking reliable and professional support in their visa application journey. Thank you, Careerline, for your outstanding service and support!",
    name: "Prashant Multani",
    pragram: "Master of Construction Management",
    university: "Auckland University of Technology",
    intake: "JULY_2024",
    country: "NZ",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Manank}`,
    description: `Careerline Education Foundation has been instrumental in my journey toward higher education in USA. Their team of experts provided invaluable guidance and support throughout the entire process, from selecting the right university to preparing for visa interviews. Special thanks to Hardik Sir and Saurabh Sir, whose precise information and step-by-step assistance made everything smooth and manageable. The staff at Careerline is knowledgeable, patient, and genuinely invested in their students' success. I highly recommend Careerline Education Foundation to anyone seeking reliable and comprehensive educational support. Their motto, "HAR KADAM PAR RIGHT ADVICE," truly reflects their commitment to excellence.`,
    name: "Manank Bhavsar",
    pragram: "Master of Science in Electrical And Computer Engineering",
    university: "Portland State University",
    intake: "SEPT_2024",
    country: "USA",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Sanya}`,
    description: `I am incredibly grateful to Careerline for their outstanding support throughout my visa process. The dedication and professionalism demonstrated by Karsh Sir, Upasha Mam, and Bhavin Sir were truly exceptional. Thanks to their hard work and meticulous attention to detail, my visa was approved within just 7 minutes of the application being processed! Karsh Sir and Upasha Mam went above and beyond to ensure my profile was robust and compelling. Their expertise and guidance were instrumental in securing my visa promptly. I highly recommend Careerline to anyone seeking reliable and efficient visa services. Their commitment to their clients' success is unparalleled. Thank you, Careerline, for making this process smooth and stress-free!`,
    name: "Sanya Shah",
    pragram: "Master of Information Technology (Artificial Intelligence)",
    university: "Macquarie University ",
    intake: "JULY_2024",
    country: "AUS",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${Krish}`,
    description:
      "I have recently secured my Canadian visa for acadia University where i am going to pursue my bachelor of computer science in fall 2024 intake I would specifically thank Megha Ma'am who was my counsellor she is the best counsellor i have ever met ….the process was very smooth with the help of her i am able to get admission in the best university that suits my profile moreover i would like to thank each and every member of the staff for helping me initially and potentially both..i would highly recommend careerline for any kind of visa process as they give haar kadam par right advise.",
    name: "Krish Joshi",
    pragram: "Bachelor of Computer Science",
    university: "Acadia University",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Aaryan}`,
    description:
      "I cannot express enough gratitude to Careerline Institute for their outstanding support and guidance throughout my journey to study in Canada. From admission to visa acquirement to even preparation for the IELTS exam, they've been with me every step of the way. Bhavin Sir, the owner, is a visionary leader who understands the complexity of the process and ensures everything runs seamlessly. Thanks to the collective efforts of this fantastic team, I not only achieved my dream of studying in Canada but also excelled in the IELTS exam. I wholeheartedly recommend Careerline Institute to anyone seeking professional assistance in their academic pursuits. They truly go above and beyond to ensure success!",
    name: "Aryan Shah",
    pragram: "Game Art",
    university: "Durham College",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Nimesh}`,
    description:
      "Thank you CareerLine team for consultation for Australian Visa.I am greatful to you for excellence guidance. Bhavin sir, Aayushi mem, Dipika mem and Karsh sir thank you so much to enlighten my path to my journey by your excellence expertise from the beginning to the final visa approval stage. It become possible because of your hardwork I'm really thankful to all member of Careerline foundation.",
    name: "Nimesh Sadhu",
    pragram: "Master of Professional Engineering (Civil)",
    university: "QUT",
    intake: "JULY_2024",
    country: "AUS",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${WomenAvatar}`,
    description:
      "Recently I got my Canadian student visa within 15 days just because of Careerline . Especially Ami mam and Ayush bhai are very supportive.They help a lot in the whole filing process. Guys if uh want to process your file for canada student visa then must visit Careerline. Don't get late& Again heartly thanx to Ami mam and Ayushbhai.",
    name: "Foram Darji",
    pragram: "Healthcare Administration Management—Nursing Leadership ",
    university: "Conestoga College",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
];

const coachingPoints = [
  {
    icon: SUCCESS,
    title: "23+ Years of Success",
    description: "Building a legacy of excellence in GRE coaching.",
  },
  {
    icon: EXPEFACULTIES,
    title: "Experienced & Certified Faculty",
    description: "Learn from the best in the field of GRE coaching.",
  },
  {
    icon: PATTENSION,
    title: "Personalized Attention",
    description:
      "Small batch sizes ensure every student gets individual focus.",
  },
  {
    icon: AVAILABILITY,
    title: "24/7 Access to Study Material",
    description: "Access top-tier study material anytime, anywhere.",
  },
  {
    icon: GRECOUNSELLORS,
    title: "Expert GRE Counselors",
    description: "Get guidance and mentorship from experienced counselors.",
  },
  {
    icon: PERFORMANCE,
    title: "Performance Analysis & Feedback",
    description:
      "Receive regular feedback to track and improve your performance.",
  },
];

const helpPoints = [
  {
    icon: CURRRICULUM,
    title: "Highly Effective Curriculum",
    description:
      "With over 23+ years of experience, our GRE curriculum is meticulously crafted to cover everything you need to crack the exam. Focus on what matters the most, without unnecessary extras.",
  },
  {
    icon: INTERACTIVETEACHING,
    title: "Simplified & Interactive Teaching",
    description:
      "Our certified GRE trainers break down complex topics into easy-to-understand concepts, making sure no prior knowledge is required. Whether you are starting from scratch or looking to improve, we have got you covered.",
  },
  {
    icon: DOUBTCLEARING,
    title: "Unlimited Doubt Clearing",
    description:
      "At Careerline, we offer personalized doubt-clearing sessions. If you have any doubts, join the doubt-clearing session to get assistance.",
  },
  {
    icon: ADVANCEACCESS,
    title: "Access to an Advanced Study Portal",
    description:
      "Get access to Careerline’s GRE Prep Portal, which includes full-length mock tests, sectional quizzes, and a vast question bank designed to give you comprehensive preparation.",
  },
];

const GRELandingPage = () => {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-prev-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowLeftLong}
        />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-next-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowRightLong}
        />
      </div>
    );
  };

  const settings = {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="gre-section-1 m-0 p-0">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-7 col-xl-8">
              <h1>Score 320+ in GRE With Careerline</h1>
              <p className="mt-5">
                Prepare for the GRE with Careerline’s expert coaching and
                achieve the scores you need to secure admission to top graduate
                schools worldwide. Our proven strategies, experienced trainers,
                and personalized coaching give you the edge you need.
              </p>
              {/* <p>
                Get Expert Guidance, Proven Study Plans, and Achieve Your
                Desired Band Score!
              </p> */}
            </div>
            <div className="col-lg-5 col-xl-4 mt-4 mt-lg-0" id="gre-form">
              <GRELandingPageForm />
            </div>
          </div>
        </div>
      </div>
      <div className="gre-section-2 pt-5 pb-5">
        <div className="container">
          <h2 className="text-center line-height-3rem">
            Why Careerline is Your Smart Choice for GRE Coaching
          </h2>
          <div className="points">
            {coachingPoints.map((point, index) => (
              <div className="point-card" key={index}>
                <div className="icon">
                  <img
                    src={point.icon}
                    alt={point?.title}
                    class="feature-image"
                  />
                </div>
                <h3>{point.title}</h3>
                <p>{point.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="gre-section-3 pt-5 pb-5"
        style={{ background: "linear-gradient(180deg, #cee6ff, #e0f7fa)" }}
      >
        <div className="container">
          <h2 className="text-center line-height-3rem">
            How Do We Help You Score 320+
          </h2>
          <div className="help-points">
            {helpPoints.map((point, index) => (
              <div className="help-card" key={index}>
                <div className="help-icon">
                  <img
                    src={point.icon}
                    alt={point.title}
                    className="help-image"
                  />
                </div>
                <div className="help-content">
                  <h3>{point.title}</h3>
                  <p>{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="gre-section-4">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Our Results</h1>
              <div class="testimonial-grid">
                <div className="event-testimonials">
                  <div className="testimonials-list ">
                    <Slider
                      className="owl-carousel popular owl-theme"
                      {...settings}
                    >
                      {testimonialData.map((item, index) => (
                        <RenderTestimonialCard
                          key={index}
                          uniqueKey={index}
                          description={item?.description}
                          img={item?.img}
                          name={item?.name}
                          background={item?.background}
                          university={item?.university}
                          pragram={item?.pragram}
                          intake={item?.intake}
                          country={item?.country}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cta-section">
          <div class="cta-content">
            <h2 class="cta-title mb-0">Ready to score 320+?</h2>
            <p class="cta-title">
              Start your journey to success with Careerline!
            </p>
            <Link
              to="gre-form"
              className="cta-button"
              activeClass="active"
              offset={-120}
              duration={300}
            >
              Enroll Now
            </Link>
          </div>
        </div>
      </div>
      <div className="gre-section-5">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1>FAQs</h1>
            </div>
            <div className="col-12">
              <div>
                {FAQsData.map((faq, index) => (
                  <Accordion key={index} className="ielts-faqs">
                    <AccordionSummary
                      className="ielts-faqs-header"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${index + 1}-content`}
                      id={`panel-${index + 1}-header`}
                    >
                      {faq.title}
                    </AccordionSummary>
                    <AccordionDetails className="ielts-faqs-content">
                      {faq.content}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const RenderTestimonialCard = (props) => {
  const handlePlayYoutubeVideo = () => {
    const youtubeUrl = "https://www.youtube.com/shorts/EFx1PW2Oe1A";

    window.open(youtubeUrl, "_blank");
  };

  return (
    <div key={props.uniqueKey} className="item pt-3">
      <div
        className="testimonials"
        style={{ background: "white" }}
        onClick={(e) => handlePlayYoutubeVideo(e)}
      >
        <div
          className="testimonials-item-top p-3 position-relative"
          style={{ background: props?.background }}
        >
          <div
            className="testimonials-item-effect"
            style={{ background: props.effectColor }}
          ></div>
          <div className="testimonials-item-desc">
            <img
              src={InvertedComma}
              style={{ marginRight: 25, display: "inline" }}
            />
            {props.description}{" "}
            <img
              src={InvertedComma}
              style={{
                marginLeft: 25,
                display: "inline",
                transform: "rotate(180deg)",
              }}
            />
          </div>
          <button className="play-button">
            <span className="play-icon">&#9658;</span>
            Watch their story
          </button>
        </div>
        <div className="testimonials-item-bottom  p-3">
          <div className="textimonial-img">
            <img src={props.img} alt="Student img" className="img-fluid" />
          </div>
          <div style={{ fontSize: 12, display: "flow-root" }}>
            <span className="fw-bold" style={{ fontSize: 15 }}>
              {props.name}, {props.country}
            </span>
            <br />
            <span>{props.pragram}</span>, <br />
            at&nbsp;<span>{props.university}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRELandingPage;
