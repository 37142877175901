import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import bolgPic from "../../assets/image/bolg-pic.png";
import Arrowdpng from "../../assets/image/arrow-d.png";
import { BACKEND_BASE_URL } from "../../common/constant";
import NewsListingItem from "./NewsListingItem";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";

// const BASE_URL = "https://api.careerline.in/api";

const News = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    AOS.init();
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // Add a useEffect hook to scroll to the top when the component mounts
  useEffect(() => {
    scrollToTop();
  }, []);

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("OperationType", 10);

      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageNews`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.Status === 200) {
        setBlogs(data?.Data);
      } else {
        console.log("Failed to fetch news");
      }
    } catch (error) {
      console.error("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="container">
        <div className="blog-section">
          <div className="blog-section-heading">
            <div className="row">
              {/* ... */}
              <div
                className="col-sm-12 col-md-8 col-lg-9"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                <h2>
                  Our <span>News</span>
                </h2>
                <p>
                  Tiny doses of study abroad wisdom, right from Kanan alumni,
                  counselors and students.
                </p>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <div className="blog-btn">
                  <button className="apply-now">
                    <a href="#">Talk to our Expert</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row blog-boxes">
            {blogs.map((item, index) => (
              <NewsListingItem key={index} {...item} />
            ))}
          </div>
        </div>
        {/* <div className="blog-bottom" data-aos="fade-up" data-aos-duration="700">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <img src={Arrowdpng} alt="" />
        </div> */}
      </div>
    </>
  );
};
export default News;
