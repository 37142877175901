import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Article(props) {
  const navigate = useNavigate();

  const handleReadMore = (url) => {
    navigate(`/blogdetail/${url}`);
  };

  const URL = `${window.location.origin}/blogdetail/${props.BlogURL}/`;

  return (
    <div
      className="artical"
      data-aos="fade-right"
      data-aos-duration="700"
      data-aos-delay={props.delay}
    >
      <div className="artical-left">
        <img src={props.BlogImage} alt="" />
      </div>
      <div className="artical-right search-red">
        <span>{props.BlogDate}</span>
        <Link to={URL}>
          <h5>{props.BlogTitle}</h5>
        </Link>
        <p>{props.ShortDescription}</p>
        <a
          onClick={() => handleReadMore(`${props.BlogURL}/`)}
          style={{
            cursor: "pointer",
          }}
        >
          Read More
        </a>
      </div>
    </div>
  );
}

export default Article;
