import React from "react";
import "./IELTSLandingPageStyle.css";
import Experts from "../../assets/image/landingpages/experts.png";
import Insights from "../../assets/image/landingpages/insight.png";
import InteractiveClass from "../../assets/image/landingpages/interactiveclass.png";
import Oportunities from "../../assets/image/landingpages/oportunities.png";
import Community from "../../assets/image/landingpages/community.png";
import flexiblestartdate from "../../assets/image/IELTS EXAM/flexiblestartdate.png";
import portalaccess from "../../assets/image/IELTS EXAM/portalaccess.png";
import studyabroad from "../../assets/image/IELTS EXAM/studyabroad.png";
import weekendclasses from "../../assets/image/IELTS EXAM/weekendclasses.png";
import TEST from "../../assets/image/IELTS EXAM/test.png";
import TEST1 from "../../assets/image/IELTS EXAM/test1.png";
import TEST2 from "../../assets/image/IELTS EXAM/test2.png";
import TEST3 from "../../assets/image/IELTS EXAM/test3.png";
import TEST4 from "../../assets/image/IELTS EXAM/test4.png";
// import UserMale from "../../assets/image/landingpages/user-male-circle.png";
// import UserFemale from "../../assets/image/landingpages/user-female-circle.png";
// import InvertedComma from "../../../assets/image/white-quoter.png";
import Shreya from "../../assets/image/testimonial shreya.jpeg";
import Bansi from "../../assets/image/testimonial bansi.jpg";
import Mahadev from "../../assets/image/testimonial mahadev.jpg";
import Prashant from "../../assets/image/testimonial prashant.jpeg";
import Sanya from "../../assets/image/sanya shah.jpg";
import Krish from "../../assets/image/krish joshi.jpg";
import Manank from "../../assets/image/manank.jpg";
import Nimesh from "../../assets/image/nimesh shadhu.jpg";
import Aaryan from "../../assets/image/aaryan.jpg";
import WomenAvatar from "../../assets/image/women avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IELTSLandingPageForm from "./IELTSLandingPageForm";
import { Link } from "react-scroll";
import InvertedComma from "../../assets/image/white-quoter.png";
import Slider from "react-slick";

const FeaturedData = [
  {
    title: "Expert Tutors",
    image: TEST1,
    description:
      "Our tutors are meticulously trained to deliver a top-tier learning experience, providing you with the guidance and strategies you need to excel.",
  },
  {
    title: "Exam-Level Content",
    image: TEST,
    description:
      "Study with materials that match the actual exam—neither too simple nor overly complex. We provide standardized content that mirrors the difficulty of the IELTS, so you're fully prepared for test day.",
  },
  {
    title: "Support at Every Step",
    image: TEST2,
    description:
      "Our trainers are here to support you whenever you need it—whether through email, phone, or social media, help is always just a message away.",
  },
  {
    title: "Simulated Test Engine",
    image: TEST3,
    description:
      "Experience the real exam environment with our advanced test engine. Familiarize yourself with the format and pressure of IELTS before you even step into the exam room.",
  },
  {
    title: "Mock Tests",
    image: TEST4,
    description:
      "Hone your skills with multiple sectional and full-length mock tests, all from the convenience of your home. Get detailed feedback to fine-tune your performance.",
  },
];

const FAQsData = [
  {
    title: "Can you provide guidance on how to prepare for the IELTS exam?",
    content:
      "Absolutely! We offer comprehensive IELTS preparation guidance, including study materials, practice tests, and tips to improve your performance in each section of the exam.",
  },
  {
    title: "Can you assist in obtaining IELTS waivers?",
    content:
      "We can guide you through the process, but IELTS waivers are typically granted under specific circumstances. It's essential to check the policies of individual universities regarding waivers.",
  },
  {
    title:
      "How can I access additional resources or support if I have questions about the IELTS process?",
    content:
      "We provide ongoing support and resources, including study materials, practice sessions, and assistance with any questions or concerns you may have about the IELTS process.",
  },
];

const testimonialData = [
  {
    img: `${Shreya}`,
    description:
      "I had an exceptional experience with Kathan Sir, Maulin Sir, and Nikhil Sir for my USA study visa counseling! Their expertise, guidance, and support throughout the process were invaluable. Together, they made a fantastic team! Their dedication, professionalism, and kindness made my study visa journey seamless and successful. I highly recommend them to anyone seeking guidance for their study visa applications. Thank you, Kathan Sir, Maulin Sir, and Nikhil Sir, for your outstanding support and mentorship!",
    name: "Shreya Vaghela",
    pragram: "Master of Science in Artificial Intelligence",
    university: "Long Island University",
    intake: "SEPT_2024",
    country: "USA",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Bansi}`,
    description:
      "I had an amazing experience at Careerline education during my all visa process. the counsellors are very knowledgeable & supportive. I would especially like to thank Hetal Ma'am & Kishan sir who helped me throughout the all process and solved my all queries without giving me any stress. really Careerline Education provide excellent service to their all students..💯🤝",
    name: "Bansi Savaliya",
    pragram: "MSc Biomedical Laboratory Science",
    university: "Sheffield Hallam University",
    intake: "SEPT_2024",
    country: "UK",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Mahadev}`,
    description:
      "I applied for my UK visa as well as had my IELTS preparation from Careerline and they have been supportive to me at every step of the way especially Kishan sir and Anshul sir guiding me through all the universities of the UK and helping me apply for admission and visa. They have always been swift in solving any and all queries I’ve had during the process. My experience with IELTS classes was also great and had support from highly skilled faculties especially Poonam mam who conducted all of my lectures and solved my doubts, supported me wherever I lacked and made sure I was ready for my examination. I highly recommend Careerline to everyone who is planning on going abroad for further education.",
    name: "Mahadev Vaghela",
    pragram: "MSc Astrophysics",
    university: "University of Glasgow",
    intake: "SEPT_2024",
    country: "UK",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${Prashant}`,
    description:
      "I am delighted to share my positive experience working with Careeline during my student visa process for Newzeland, for my master’s degree at AUT. From the very beginning, Mr Hardik Sir demonstrated exceptional expertise and dedication. What stood out the most was their unwavering support and patience. No matter how many questions I had or how many times I needed clarification, Hardik Sir was always available and willing to assist. They provided timely updates and kept me informed at every step, making the whole process smooth and stress-free. Thanks to Hardik Sir and his team's expertise and assistance, I successfully obtained my student visa and am now excited to start my studies at AUT. I highly recommend Careerline to anyone seeking reliable and professional support in their visa application journey. Thank you, Careerline, for your outstanding service and support!",
    name: "Prashant Multani",
    pragram: "Master of Construction Management",
    university: "Auckland University of Technology",
    intake: "JULY_2024",
    country: "NZ",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Manank}`,
    description: `Careerline Education Foundation has been instrumental in my journey toward higher education in USA. Their team of experts provided invaluable guidance and support throughout the entire process, from selecting the right university to preparing for visa interviews. Special thanks to Hardik Sir and Saurabh Sir, whose precise information and step-by-step assistance made everything smooth and manageable. The staff at Careerline is knowledgeable, patient, and genuinely invested in their students' success. I highly recommend Careerline Education Foundation to anyone seeking reliable and comprehensive educational support. Their motto, "HAR KADAM PAR RIGHT ADVICE," truly reflects their commitment to excellence.`,
    name: "Manank Bhavsar",
    pragram: "Master of Science in Electrical And Computer Engineering",
    university: "Portland State University",
    intake: "SEPT_2024",
    country: "USA",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Sanya}`,
    description: `I am incredibly grateful to Careerline for their outstanding support throughout my visa process. The dedication and professionalism demonstrated by Karsh Sir, Upasha Mam, and Bhavin Sir were truly exceptional. Thanks to their hard work and meticulous attention to detail, my visa was approved within just 7 minutes of the application being processed! Karsh Sir and Upasha Mam went above and beyond to ensure my profile was robust and compelling. Their expertise and guidance were instrumental in securing my visa promptly. I highly recommend Careerline to anyone seeking reliable and efficient visa services. Their commitment to their clients' success is unparalleled. Thank you, Careerline, for making this process smooth and stress-free!`,
    name: "Sanya Shah",
    pragram: "Master of Information Technology (Artificial Intelligence)",
    university: "Macquarie University ",
    intake: "JULY_2024",
    country: "AUS",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${Krish}`,
    description:
      "I have recently secured my Canadian visa for acadia University where i am going to pursue my bachelor of computer science in fall 2024 intake I would specifically thank Megha Ma'am who was my counsellor she is the best counsellor i have ever met ….the process was very smooth with the help of her i am able to get admission in the best university that suits my profile moreover i would like to thank each and every member of the staff for helping me initially and potentially both..i would highly recommend careerline for any kind of visa process as they give haar kadam par right advise.",
    name: "Krish Joshi",
    pragram: "Bachelor of Computer Science",
    university: "Acadia University",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Aaryan}`,
    description:
      "I cannot express enough gratitude to Careerline Institute for their outstanding support and guidance throughout my journey to study in Canada. From admission to visa acquirement to even preparation for the IELTS exam, they've been with me every step of the way. Bhavin Sir, the owner, is a visionary leader who understands the complexity of the process and ensures everything runs seamlessly. Thanks to the collective efforts of this fantastic team, I not only achieved my dream of studying in Canada but also excelled in the IELTS exam. I wholeheartedly recommend Careerline Institute to anyone seeking professional assistance in their academic pursuits. They truly go above and beyond to ensure success!",
    name: "Aryan Shah",
    pragram: "Game Art",
    university: "Durham College",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Nimesh}`,
    description:
      "Thank you CareerLine team for consultation for Australian Visa.I am greatful to you for excellence guidance. Bhavin sir, Aayushi mem, Dipika mem and Karsh sir thank you so much to enlighten my path to my journey by your excellence expertise from the beginning to the final visa approval stage. It become possible because of your hardwork I'm really thankful to all member of Careerline foundation.",
    name: "Nimesh Sadhu",
    pragram: "Master of Professional Engineering (Civil)",
    university: "QUT",
    intake: "JULY_2024",
    country: "AUS",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${WomenAvatar}`,
    description:
      "Recently I got my Canadian student visa within 15 days just because of Careerline . Especially Ami mam and Ayush bhai are very supportive.They help a lot in the whole filing process. Guys if uh want to process your file for canada student visa then must visit Careerline. Don't get late& Again heartly thanx to Ami mam and Ayushbhai.",
    name: "Foram Darji",
    pragram: "Healthcare Administration Management—Nursing Leadership ",
    university: "Conestoga College",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
];

const IELTSLandingPage = () => {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-prev-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowLeftLong}
        />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-next-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowRightLong}
        />
      </div>
    );
  };

  const settings = {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="ielts-section-1 m-0 p-0">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-7 col-xl-8">
              <h1>
                IELTS is Easy if You Prepare Right – Start with Careerline!
              </h1>
              <p className="mt-5">
                With over 23+ years of proven success, Careerline offers you the
                expertise, resources, and support to achieve your dream IELTS
                score. Whether you’re preparing for higher education, work
                visas, or migration, our comprehensive program is designed to
                make IELTS preparation easy and effective.
              </p>
              <p>
                Get Expert Guidance, Proven Study Plans, and Achieve Your
                Desired Band Score!
              </p>
            </div>
            <div className="col-lg-5 col-xl-4 mt-4 mt-lg-0" id="ielts-form">
              <IELTSLandingPageForm />
            </div>
          </div>
        </div>
      </div>
      <div className="ielts-section-2">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-12">
              <h1>Why 30,000+ Students Choose Us</h1>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={Experts} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Certified Band 9 <br /> Trainers
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={Insights} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Unlimited Detailed <br /> Evaluation
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={InteractiveClass} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Two-Way Live <br /> Interactive Classes
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={Oportunities} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Actual IELTS <br /> Exam Interface
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={Community} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Small Batch Sizes <br /> Up to only 20
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={weekendclasses} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Weekend Classes <br /> Available
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={flexiblestartdate} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Flexible Start Date <br /> Join Any Week
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={portalaccess} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Exclusive Access to the <br /> Careerline Prep Portal
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-4 ps-3 ps-lg-5 pe-3 pe-lg-5">
                  <div className="ielts-cards">
                    <img src={studyabroad} alt={"Flexible Learning"} />
                    <p className="mt-4">
                      Expert Study <br /> Abroad Counseling
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="ielts-section-3" style={{ background: "#cee6ff" }}>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1>Top Features of Careerline’s Proven IELTS Coaching</h1>
              <div class="feature-list">
                {FeaturedData.map((feature, index) => (
                  <div className="feature-item" key={index}>
                    <h2>{feature?.title}</h2>
                    <p>{feature?.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="ielts-section-3"
        style={{ background: "linear-gradient(180deg, #cee6ff, #e0f7fa)" }}
      >
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Top Features of Careerline’s Proven IELTS Coaching</h1>
            </div>
          </div>
          <div className="feature-list">
            {FeaturedData.map((feature, index) => (
              <div class="feature-item" key={index}>
                <div class="feature-icon">
                  <img
                    src={feature?.image}
                    alt={feature?.title}
                    class="feature-image"
                  />
                </div>
                <h2>{feature?.title}</h2>
                <p>{feature?.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="ielts-section-4">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Our Results</h1>
              <div class="testimonial-grid">
                <div class="testimonial-card">
                  <div class="card-header">
                    <img src={UserFemale} alt="Priya" class="avatar" />
                    <h3 class="testimonial-name">Priya</h3>
                    <span class="testimonial-role">Canada Student Visa</span>
                  </div>
                  <p class="testimonial-text">
                    "Careerline’s IELTS coaching helped me improve my band score
                    from 6 to 8. The mock tests and personalized feedback were
                    game-changers!"
                  </p>
                </div>
                <div class="testimonial-card">
                  <div class="card-header">
                    <img src={UserMale} alt="Raj" class="avatar" />
                    <h3 class="testimonial-name">Raj</h3>
                    <span class="testimonial-role">Australia PR Visa</span>
                  </div>
                  <p class="testimonial-text">
                    "Thanks to Careerline, I achieved the score I needed for my
                    PR application. The speaking sessions were especially
                    helpful."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cta-section">
          <div class="cta-content">
            <h2 class="cta-title">
              Join Careerline’s IELTS coaching program and take the first step
              toward your global aspirations.
            </h2>
            <Link
              to="ielts-form"
              className="cta-button"
              activeClass="active"
              offset={-120}
              duration={300}
            >
              Enroll Now
            </Link>
          </div>
        </div>
      </div> */}
      <div className="ielts-section-4">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Our Results</h1>
              <div class="testimonial-grid">
                <div className="event-testimonials">
                  <div className="testimonials-list ">
                    <Slider
                      className="owl-carousel popular owl-theme"
                      {...settings}
                    >
                      {testimonialData.map((item, index) => (
                        <RenderTestimonialCard
                          key={index}
                          uniqueKey={index}
                          description={item?.description}
                          img={item?.img}
                          name={item?.name}
                          background={item?.background}
                          university={item?.university}
                          pragram={item?.pragram}
                          intake={item?.intake}
                          country={item?.country}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
                {/* <div class="testimonial-card">
                  <div class="card-header">
                    <img src={UserFemale} alt="Priya" class="avatar" />
                    <h3 class="testimonial-name">Priya</h3>
                    <span class="testimonial-role">Canada Student Visa</span>
                  </div>
                  <p class="testimonial-text">
                    "Careerline’s IELTS coaching helped me improve my band score
                    from 6 to 8. The mock tests and personalized feedback were
                    game-changers!"
                  </p>
                </div>
                <div class="testimonial-card">
                  <div class="card-header">
                    <img src={UserMale} alt="Raj" class="avatar" />
                    <h3 class="testimonial-name">Raj</h3>
                    <span class="testimonial-role">Australia PR Visa</span>
                  </div>
                  <p class="testimonial-text">
                    "Thanks to Careerline, I achieved the score I needed for my
                    PR application. The speaking sessions were especially
                    helpful."
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div class="cta-section">
          <div class="cta-content">
            <h2 class="cta-title">
              Join Careerline’s IELTS coaching program and take the first step
              toward your global aspirations.
            </h2>
            <Link
              to="ielts-form"
              className="cta-button"
              activeClass="active"
              offset={-120}
              duration={300}
            >
              Enroll Now
            </Link>
          </div>
        </div>
      </div>
      <div className="ielts-section-5">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1>FAQs</h1>
            </div>
            <div className="col-12">
              <div>
                {FAQsData.map((faq, index) => (
                  <Accordion key={index} className="ielts-faqs">
                    <AccordionSummary
                      className="ielts-faqs-header"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${index + 1}-content`}
                      id={`panel-${index + 1}-header`}
                    >
                      {faq.title}
                    </AccordionSummary>
                    <AccordionDetails className="ielts-faqs-content">
                      {faq.content}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const RenderTestimonialCard = (props) => {
  const handlePlayYoutubeVideo = () => {
    const youtubeUrl = "https://www.youtube.com/shorts/EFx1PW2Oe1A";

    window.open(youtubeUrl, "_blank");
  };

  return (
    <div key={props.uniqueKey} className="item pt-3">
      <div
        className="testimonials"
        style={{ background: "white" }}
        onClick={(e) => handlePlayYoutubeVideo(e)}
      >
        <div
          className="testimonials-item-top p-3 position-relative"
          style={{ background: props?.background }}
        >
          <div
            className="testimonials-item-effect"
            style={{ background: props.effectColor }}
          ></div>
          <div className="testimonials-item-desc">
            <img
              src={InvertedComma}
              style={{ marginRight: 25, display: "inline" }}
            />
            {props.description}{" "}
            <img
              src={InvertedComma}
              style={{
                marginLeft: 25,
                display: "inline",
                transform: "rotate(180deg)",
              }}
            />
          </div>
          <button className="play-button">
            <span className="play-icon">&#9658;</span>
            Watch their story
          </button>
        </div>
        <div className="testimonials-item-bottom  p-3">
          <div className="textimonial-img">
            <img src={props.img} alt="Student img" className="img-fluid" />
          </div>
          <div style={{ fontSize: 12, display: "flow-root" }}>
            <span className="fw-bold" style={{ fontSize: 15 }}>
              {props.name}, {props.country}
            </span>
            <br />
            <span>{props.pragram}</span>, <br />
            at&nbsp;<span>{props.university}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IELTSLandingPage;
