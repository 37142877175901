import React from "react";
import "./PTELandingPageStyle.css";
import SUCCESS from "../../assets/image/ptelanding/Achievement.svg";
import STUDYFLEXIBILITY from "../../assets/image/ptelanding/studyflexibility.svg";
import PRECISION from "../../assets/image/ptelanding/point2-2.svg";
import MOCKTEST from "../../assets/image/ptelanding/mocktest.svg";
import FEATUREONE from "../../assets/image/ptelanding/feature-1.jpg";
import FEATURETWO from "../../assets/image/ptelanding/feature-2.jpg";
import FEATURETHREE from "../../assets/image/ptelanding/feature-3.jpg";
import PLANONE from "../../assets/image/ptelanding/plan-1.png";
import PLANTWO from "../../assets/image/ptelanding/plan-2.png";
import PLANTHREE from "../../assets/image/ptelanding/plan-3.png";
import Shreya from "../../assets/image/testimonial shreya.jpeg";
import Bansi from "../../assets/image/testimonial bansi.jpg";
import Mahadev from "../../assets/image/testimonial mahadev.jpg";
import Prashant from "../../assets/image/testimonial prashant.jpeg";
import Sanya from "../../assets/image/sanya shah.jpg";
import Krish from "../../assets/image/krish joshi.jpg";
import Manank from "../../assets/image/manank.jpg";
import Nimesh from "../../assets/image/nimesh shadhu.jpg";
import Aaryan from "../../assets/image/aaryan.jpg";
import WomenAvatar from "../../assets/image/women avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PTELandingPageForm from "./PTELandingPageForm";
import { Link } from "react-scroll";
import InvertedComma from "../../assets/image/white-quoter.png";
import Slider from "react-slick";

const FAQsData = [
  {
    title: "Is PTE easier than IELTS?",
    content:
      "Both the exams PTE vs IELTS test the same fundamental language skills viz. Speaking, Writing, Reading, and Listening. Neither of the tests is easier than the other, except for the differences in the mode of examination and the pattern. The total time for both exams is 3 hours.",
  },
  {
    title:
      "Can your consultancy assist with rescheduling the PTE exam if needed?",
    content:
      "Certainly! We can guide you through the process of rescheduling the PTE exam, considering your individual circumstances and ensuring minimal disruption to your study plans.",
  },
  {
    title: "What documents are required for the PTE exam?",
    content:
      "Documents required for PTE exam registration in India All one needs is a passport to register for PTE. On the test day, applicants must carry their passports and admit cards to the testing center. The PTE scorecard is delivered within five working days from the test day",
  },
];

const testimonialData = [
  {
    img: `${Shreya}`,
    description:
      "I had an exceptional experience with Kathan Sir, Maulin Sir, and Nikhil Sir for my USA study visa counseling! Their expertise, guidance, and support throughout the process were invaluable. Together, they made a fantastic team! Their dedication, professionalism, and kindness made my study visa journey seamless and successful. I highly recommend them to anyone seeking guidance for their study visa applications. Thank you, Kathan Sir, Maulin Sir, and Nikhil Sir, for your outstanding support and mentorship!",
    name: "Shreya Vaghela",
    pragram: "Master of Science in Artificial Intelligence",
    university: "Long Island University",
    intake: "SEPT_2024",
    country: "USA",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Bansi}`,
    description:
      "I had an amazing experience at Careerline education during my all visa process. the counsellors are very knowledgeable & supportive. I would especially like to thank Hetal Ma'am & Kishan sir who helped me throughout the all process and solved my all queries without giving me any stress. really Careerline Education provide excellent service to their all students..💯🤝",
    name: "Bansi Savaliya",
    pragram: "MSc Biomedical Laboratory Science",
    university: "Sheffield Hallam University",
    intake: "SEPT_2024",
    country: "UK",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Mahadev}`,
    description:
      "I applied for my UK visa as well as had my IELTS preparation from Careerline and they have been supportive to me at every step of the way especially Kishan sir and Anshul sir guiding me through all the universities of the UK and helping me apply for admission and visa. They have always been swift in solving any and all queries I’ve had during the process. My experience with IELTS classes was also great and had support from highly skilled faculties especially Poonam mam who conducted all of my lectures and solved my doubts, supported me wherever I lacked and made sure I was ready for my examination. I highly recommend Careerline to everyone who is planning on going abroad for further education.",
    name: "Mahadev Vaghela",
    pragram: "MSc Astrophysics",
    university: "University of Glasgow",
    intake: "SEPT_2024",
    country: "UK",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${Prashant}`,
    description:
      "I am delighted to share my positive experience working with Careeline during my student visa process for Newzeland, for my master’s degree at AUT. From the very beginning, Mr Hardik Sir demonstrated exceptional expertise and dedication. What stood out the most was their unwavering support and patience. No matter how many questions I had or how many times I needed clarification, Hardik Sir was always available and willing to assist. They provided timely updates and kept me informed at every step, making the whole process smooth and stress-free. Thanks to Hardik Sir and his team's expertise and assistance, I successfully obtained my student visa and am now excited to start my studies at AUT. I highly recommend Careerline to anyone seeking reliable and professional support in their visa application journey. Thank you, Careerline, for your outstanding service and support!",
    name: "Prashant Multani",
    pragram: "Master of Construction Management",
    university: "Auckland University of Technology",
    intake: "JULY_2024",
    country: "NZ",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Manank}`,
    description: `Careerline Education Foundation has been instrumental in my journey toward higher education in USA. Their team of experts provided invaluable guidance and support throughout the entire process, from selecting the right university to preparing for visa interviews. Special thanks to Hardik Sir and Saurabh Sir, whose precise information and step-by-step assistance made everything smooth and manageable. The staff at Careerline is knowledgeable, patient, and genuinely invested in their students' success. I highly recommend Careerline Education Foundation to anyone seeking reliable and comprehensive educational support. Their motto, "HAR KADAM PAR RIGHT ADVICE," truly reflects their commitment to excellence.`,
    name: "Manank Bhavsar",
    pragram: "Master of Science in Electrical And Computer Engineering",
    university: "Portland State University",
    intake: "SEPT_2024",
    country: "USA",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Sanya}`,
    description: `I am incredibly grateful to Careerline for their outstanding support throughout my visa process. The dedication and professionalism demonstrated by Karsh Sir, Upasha Mam, and Bhavin Sir were truly exceptional. Thanks to their hard work and meticulous attention to detail, my visa was approved within just 7 minutes of the application being processed! Karsh Sir and Upasha Mam went above and beyond to ensure my profile was robust and compelling. Their expertise and guidance were instrumental in securing my visa promptly. I highly recommend Careerline to anyone seeking reliable and efficient visa services. Their commitment to their clients' success is unparalleled. Thank you, Careerline, for making this process smooth and stress-free!`,
    name: "Sanya Shah",
    pragram: "Master of Information Technology (Artificial Intelligence)",
    university: "Macquarie University ",
    intake: "JULY_2024",
    country: "AUS",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${Krish}`,
    description:
      "I have recently secured my Canadian visa for acadia University where i am going to pursue my bachelor of computer science in fall 2024 intake I would specifically thank Megha Ma'am who was my counsellor she is the best counsellor i have ever met ….the process was very smooth with the help of her i am able to get admission in the best university that suits my profile moreover i would like to thank each and every member of the staff for helping me initially and potentially both..i would highly recommend careerline for any kind of visa process as they give haar kadam par right advise.",
    name: "Krish Joshi",
    pragram: "Bachelor of Computer Science",
    university: "Acadia University",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
  },
  {
    img: `${Aaryan}`,
    description:
      "I cannot express enough gratitude to Careerline Institute for their outstanding support and guidance throughout my journey to study in Canada. From admission to visa acquirement to even preparation for the IELTS exam, they've been with me every step of the way. Bhavin Sir, the owner, is a visionary leader who understands the complexity of the process and ensures everything runs seamlessly. Thanks to the collective efforts of this fantastic team, I not only achieved my dream of studying in Canada but also excelled in the IELTS exam. I wholeheartedly recommend Careerline Institute to anyone seeking professional assistance in their academic pursuits. They truly go above and beyond to ensure success!",
    name: "Aryan Shah",
    pragram: "Game Art",
    university: "Durham College",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
  {
    img: `${Nimesh}`,
    description:
      "Thank you CareerLine team for consultation for Australian Visa.I am greatful to you for excellence guidance. Bhavin sir, Aayushi mem, Dipika mem and Karsh sir thank you so much to enlighten my path to my journey by your excellence expertise from the beginning to the final visa approval stage. It become possible because of your hardwork I'm really thankful to all member of Careerline foundation.",
    name: "Nimesh Sadhu",
    pragram: "Master of Professional Engineering (Civil)",
    university: "QUT",
    intake: "JULY_2024",
    country: "AUS",
    background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
  },
  {
    img: `${WomenAvatar}`,
    description:
      "Recently I got my Canadian student visa within 15 days just because of Careerline . Especially Ami mam and Ayush bhai are very supportive.They help a lot in the whole filing process. Guys if uh want to process your file for canada student visa then must visit Careerline. Don't get late& Again heartly thanx to Ami mam and Ayushbhai.",
    name: "Foram Darji",
    pragram: "Healthcare Administration Management—Nursing Leadership ",
    university: "Conestoga College",
    intake: "SEPT_2024",
    country: "CAN",
    background: "linear-gradient(45deg, #c09500, goldenrod)",
  },
];

const pteSuccessPoints = [
  {
    imgsrc: SUCCESS,
    imgalt: "Success",
    title: "23+ Years of Proven Results",
    description:
      "For over two decades, we’ve been transforming dreams into reality. Thousands of students have achieved their ideal scores with our tried-and-tested strategies, making us a name synonymous with PTE success.",
  },
  {
    imgsrc: PRECISION,
    imgalt: "Precision guidance",
    title: "Precision Coaching by Certified Trainers",
    description:
      "Our trainers are certified PTE specialists with a wealth of experience. They understand every nuance of the exam and will guide you in a personalized, step-by-step approach to reach your target score.",
  },
  {
    imgsrc: STUDYFLEXIBILITY,
    imgalt: "Flexible Study",
    title: "Study Anywhere, Anytime",
    description:
      "Access our exclusive Careerline PTE Portal with the flexibility to study whenever and wherever it suits you. From high-quality video tutorials to interactive exercises, we ensure you’re never stuck on a question or concept.",
  },
  {
    imgsrc: MOCKTEST,
    imgalt: "Mock test",
    title: "Not Just Mock Tests—Real Test Experience!",
    description:
      "We don’t just offer mock tests. At Careerline, we simulate the actual PTE exam environment. This ensures you’re completely familiar with the test interface, leaving no surprises on test day.",
  },
];

const featurePoints = [
  {
    icon: FEATUREONE,
    title: "Individualized Attention",
    description:
      "Our batch sizes are intentionally small to ensure you receive personalized feedback and can actively participate in each session.",
  },
  {
    icon: FEATURETWO,
    title: "Exclusive Access to Unlimited Study Resources",
    description:
      "Your preparation doesn’t stop with classroom sessions. Get continuous access to study materials, practice quizzes, sectional tests, and video content.",
  },
  {
    icon: FEATURETHREE,
    title: "Comprehensive Doubt Clearing",
    description:
      "Have a question? Talk to our trainers to clear your doubts at any stage of your preparation. Our trainers are available round-the-clock.",
  },
];

const planPoints = [
  {
    icon: PLANONE,
    title: "Initial Mock Test",
    description:
      "Start with a mock test to evaluate your strengths and weaknesses.",
  },
  {
    icon: PLANTWO,
    title: "Weekly Progress Reports",
    description:
      "Receive weekly performance reports with suggestions for improvement and motivational feedback from our expert trainers.",
  },
  {
    icon: PLANTHREE,
    title: "Complete Test Readiness",
    description:
      "After completing your full-length mock exams, our trainers will walk you through detailed performance analyses to ensure you're fully prepared for test day.",
  },
];

const PTELandingPage = () => {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-prev-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowLeftLong}
        />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-next-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowRightLong}
        />
      </div>
    );
  };

  const settings = {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="pte-section-1 m-0 p-0">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-7 col-xl-8">
              <h1>Best PTE Coaching in India</h1>
              <p className="mt-5">
                At Careerline , we don’t just help you pass the PTE exam. We
                prepare you for a global future . With our proven methodology ,
                expert trainers, and state-of-the-art resources, you'll feel
                confident, motivated, and ready to conquer your PTE test with
                ease.
              </p>
              <b>Unlock Your Potential</b>
            </div>
            <div className="col-lg-5 col-xl-4 mt-4 mt-lg-0" id="pte-form">
              <PTELandingPageForm />
            </div>
          </div>
        </div>
      </div>
      <div className="pte-section-2 pt-5 pb-5">
        <div className="container">
          <h2 className="text-center line-height-3rem">
            What Sets Careerline Apart for PTE Success?
          </h2>
          <div className="points">
            {pteSuccessPoints.map((point, index) => (
              <div class="point-card">
                <div class="content">
                  <img src={point.imgsrc} alt={point.imgalt} />
                  <h3>{point.title}</h3>
                  <h5>{point.description}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="pte-section-3 pt-5 pb-5"
        style={{ background: "linear-gradient(180deg,#cee6ff, #e0f7fa )" }}
      >
        <div className="container">
          <h2 className="text-center line-height-3rem">
            PTE Coaching Features
          </h2>
          <div className="feature-points">
            {featurePoints.map((point, index) => (
              <div className="feature-card" key={index}>
                <div className="feature-icon">
                  <img
                    src={point.icon}
                    alt={point.title}
                    className="feature-image"
                  />
                </div>
                <div className="feature-content">
                  <h3>{point.title}</h3>
                  <p>{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pte-section-4">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">We Don’t Just Teach, We Mentor</h1>
              <div class="testimonial-grid">
                <div className="event-testimonials">
                  <div className="testimonials-list ">
                    <Slider
                      className="owl-carousel popular owl-theme"
                      {...settings}
                    >
                      {testimonialData.map((item, index) => (
                        <RenderTestimonialCard
                          key={index}
                          uniqueKey={index}
                          description={item?.description}
                          img={item?.img}
                          name={item?.name}
                          background={item?.background}
                          university={item?.university}
                          pragram={item?.pragram}
                          intake={item?.intake}
                          country={item?.country}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pte-section-6 pt-5"
        style={{ background: "linear-gradient(180deg,#cee6ff ,#e0f7fa )" }}
      >
        <div className="container">
          <h2 className="text-center line-height-3rem">
            The Careerline PTE Success Plan
          </h2>
          <div className="plan-points">
            {planPoints.map((point, index) => (
              <div className="plan-card" key={index}>
                <div className="plan-icon">
                  <img
                    src={point.icon}
                    alt={point.title}
                    className="plan-image"
                  />
                </div>
                <div className="plan-content">
                  <h3>{point.title}</h3>
                  <p>{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="cta-section">
          <div class="cta-content">
            <h2 class="cta-title mb-2">Start Your PTE Prep Today!</h2>
         
            <Link
              to="pte-form"
              className="cta-button"
              activeClass="active"
              offset={-120}
              duration={300}
            >
              Enroll Now
            </Link>
          </div>
        </div>
      </div>
      <div className="pte-section-5">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <h1>FAQs</h1>
            </div>
            <div className="col-12">
              <div>
                {FAQsData.map((faq, index) => (
                  <Accordion key={index} className="ielts-faqs">
                    <AccordionSummary
                      className="ielts-faqs-header"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${index + 1}-content`}
                      id={`panel-${index + 1}-header`}
                    >
                      {faq.title}
                    </AccordionSummary>
                    <AccordionDetails className="ielts-faqs-content">
                      {faq.content}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const RenderTestimonialCard = (props) => {
  const handlePlayYoutubeVideo = () => {
    const youtubeUrl = "https://www.youtube.com/shorts/EFx1PW2Oe1A";

    window.open(youtubeUrl, "_blank");
  };

  return (
    <div key={props.uniqueKey} className="item pt-3">
      <div
        className="testimonials"
        style={{ background: "white" }}
        onClick={(e) => handlePlayYoutubeVideo(e)}
      >
        <div
          className="testimonials-item-top p-3 position-relative"
          style={{ background: props?.background }}
        >
          <div
            className="testimonials-item-effect"
            style={{ background: props.effectColor }}
          ></div>
          <div className="testimonials-item-desc">
            <img
              src={InvertedComma}
              style={{ marginRight: 25, display: "inline" }}
            />
            {props.description}{" "}
            <img
              src={InvertedComma}
              style={{
                marginLeft: 25,
                display: "inline",
                transform: "rotate(180deg)",
              }}
            />
          </div>
          <button className="play-button">
            <span className="play-icon">&#9658;</span>
            Watch their story
          </button>
        </div>
        <div className="testimonials-item-bottom  p-3">
          <div className="textimonial-img">
            <img src={props.img} alt="Student img" className="img-fluid" />
          </div>
          <div style={{ fontSize: 12, display: "flow-root" }}>
            <span className="fw-bold" style={{ fontSize: 15 }}>
              {props.name}, {props.country}
            </span>
            <br />
            <span>{props.pragram}</span>, <br />
            at&nbsp;<span>{props.university}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PTELandingPage;
