import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../pages/Slice/dialogSlice";
import eventSlice from "../pages/events/CoffeeWithCareearline/eventSlice";
import eventDialogSlice from "../pages/Slice/eventDialogSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const persistConfig = {
  key: "root",
  storage,
};

const persistedEventReducer = persistReducer(persistConfig, eventSlice);

const store = configureStore({
  reducer: {
    dialog: dialogSlice,
    eventDialog: eventDialogSlice,
    event: persistedEventReducer,
  },
  middleware: customizedMiddleware,
});

export default store;
