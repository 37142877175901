import { toast } from "react-toastify";

const OPTIONS = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const SuccessToast = (message) => {
  toast.success(message, OPTIONS);
};

export const ErrorToast = (message) => {
  toast.error(message, OPTIONS);
};
