import React, { useState } from "react";
import Addmissiondot from "../../../assets/image/addmission-dot-pic.png";
import { useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../common/constant";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";

// const BASE_URL = "https://api.careerline.in/api";

const AdmissionSection = () => {
  const navigate = useNavigate(); // Initialize useHistory
  const [formData, setFormData] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    Remarks: "",
  });

  const [formErrors, setFormErrors] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    Remarks: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: value ? "" : "This field is required.",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFormErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        newFormErrors[key] = "This field is required.";
      }
    }

    if (Object.keys(newFormErrors).length > 0) {
      setFormErrors(newFormErrors);
      return;
    }

    const formDataWithOperationType = {
      ...formData,
      OperationType: 1,
    };

    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageInquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithOperationType),
      });

      const responseData = await response.json();
      if (responseData.Status === 200) {
        SuccessToast(responseData?.Details)
        setFormData({
          Name: "",
          EmailID: "",
          MobileNo: "",
          Remarks: "",
        });
        setFormErrors({
          Name: "",
          EmailID: "",
          MobileNo: "",
          Remarks: "",
        });
        navigate("/thankyou");
      } else {
        ErrorToast(responseData?.Details)
        console.error("Failed to submit data");
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error")
      console.error("An error occurred while submitting data", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="col-sm-12 col-md-12 col-lg-6">
      <div
        className="admission-para"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="300"
      >
        <h2>
          Apply for<span> Admission</span>
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            className={formErrors.Name ? "error-border" : ""}
          />
          {/* {formErrors.Name && (
            <div className="error-message">{formErrors.Name}</div>
          )} */}
          <input
            type="email"
            placeholder="Email Address"
            name="EmailID"
            value={formData.EmailID}
            onChange={handleChange}
            className={formErrors.EmailID ? "error-border" : ""}
          />
          {/* {formErrors.EmailID && (
            <div className="error-message">{formErrors.EmailID}</div>
          )} */}
          <input
            type="number"
            placeholder="Phone Number"
            name="MobileNo"
            value={formData.MobileNo}
            onChange={handleChange}
            className={formErrors.MobileNo ? "error-border" : ""}
          />
          {/* {formErrors.MobileNo && (
            <div className="error-message">{formErrors.MobileNo}</div>
          )} */}
          <textarea
            name="Remarks"
            cols="100"
            rows="5"
            placeholder="Write Message"
            value={formData.Remarks}
            onChange={handleChange}
            className={formErrors.Remarks ? "error-border" : ""}
          />
          {/* {formErrors.Remarks && (
            <div className="error-message">{formErrors.Remarks}</div>
          )} */}
          <button type="submit" className="apply-now">
            Apply Now
          </button>
          <div className="admission-dot-pic">
            <img src={Addmissiondot} alt="" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdmissionSection;
