import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventID: null,
};

const eventSlice = createSlice({
  name: "eventSlice",
  initialState,
  reducers: {
    setEventID: (state, action) => {
      state.eventID = action.payload;
    },
    clearEventID: (state) => {
      state.eventID = null;
    },
  },
});

export default eventSlice.reducer;
export const { setEventID, clearEventID } = eventSlice.actions;
