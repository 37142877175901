import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdmissionSection from "../studyAbroad/dynamicMain/AdmissionSection";
import StudentReview from "../studyAbroad/StudentReview";
import DestinationSlider from "./ExtraHome/DestinationSlider";
import CourseSlider from "./ExtraHome/CourseSlider";
import BannerSlider from "./ExtraHome/BannerSlider";
import NewsAndArical from "./ExtraHome/LatestNewAndArtical/NewsAndArical";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
// import { openEventDialog } from "../Slice/eventDialogSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const [homeData, setHomeData] = useState({});
  const [bannerdata, setBannreData] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [destinationData, setDestinationData] = useState([]);
  const [articalData, setArticalData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  React.useEffect(() => {
    AOS.init();
  }, []);

  const fetchHomePage = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageHomeData`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setHomeData(data?.Data);
        // setTimeout(() => {
        //   dispatch(openEventDialog());
        // }, 3000);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBanner = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageBanner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID: 1, OperationType: 4 }),
      });
      const data = await response.json();
      if (data.Status === 200) {
        setBannreData(data?.Data[0]);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };
  const getTestimonial = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageTestimonial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ OperationType: 5 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setTestimonialData(data?.Data);
      } else {
        console.log("Failed to fetch testimonial");
      }
    } catch (error) {
      console.log("Error while fetching testimonial data", error);
    } finally {
      setLoading(false);
    }
  };
  const getDestination = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageDestination`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ID: 1, OperationType: 4 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setDestinationData(data?.Data);
      } else {
        console.log("Failed to fetch testimonial");
      }
    } catch (error) {
      console.log("Error while fetching testimonial data", error);
    } finally {
      setLoading(false);
    }
  };
  const getCourse = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManagePopularCourse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ID: 1, OperationType: 4 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setCourseData(data?.Data);
      } else {
        console.log("Failed to fetch course");
      }
    } catch (error) {
      console.log("Error while fetching course data", error);
    } finally {
      setLoading(false);
    }
  };

  const getArtical = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("OperationType", 8);

      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageBlog`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.Status === 200) {
        setArticalData(data?.Data);
      } else {
        console.log("Failed to fetch news");
      }
    } catch (error) {
      console.log("Error while fetching news data", error);
    } finally {
      setLoading(false);
    }
  };

  const getNews = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("OperationType", 10);

      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageNews`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.Status === 200) {
        setNewsData(data?.Data);
      } else {
        console.log("Failed to fetch news");
      }
    } catch (error) {
      console.log("Error while fetching news data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHomePage();
    fetchBanner();
    getTestimonial();
    getDestination();
    getCourse();
    getArtical();
    getNews();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{homeData?.HomeSEO?.MetaTitle}</title>
        <meta name="description" content={homeData?.HomeSEO?.MetaDescription} />
        <meta name="keywords" content={homeData?.HomeSEO?.MetaKeyword} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div style={{ marginTop: "70px" }}></div>
      <div className="studyplan-section">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="700"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: homeData.HomeStudyPlan,
                }}
              />
            </div>
            {/* <BannerRight /> */}
            <BannerSlider bannerdata={bannerdata} />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: homeData.HomeFuture,
        }}
      />
      <DestinationSlider destinationData={destinationData} />
      <div
        dangerouslySetInnerHTML={{
          __html: homeData.HomeService,
        }}
      />
      <CourseSlider courseData={courseData} />
      <div
        className="container"
        dangerouslySetInnerHTML={{
          __html: homeData.HomeExpert,
        }}
      />
      <div className="container">
        <div className="careerline">
          <div className="row">
            {homeData.HomeWhyCareerline && (
              <div
                className="col-sm-12 col-md-12 col-lg-6"
                dangerouslySetInnerHTML={{ __html: homeData.HomeWhyCareerline }}
              />
            )}
            <AdmissionSection />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: homeData.HomePopularCourses,
        }}
      />
      <StudentReview testimonialData={testimonialData} />
      <NewsAndArical articalData={articalData} newsData={newsData} />
    </>
  );
};

export default Home;
