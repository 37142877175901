import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import YouTube from "react-youtube";

const CommonVideoSection = ({ data }) => {
  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  const renderDestinations = () => {
    return data?.VideoURL?.map((video, index) => (
      <div className="item" key={index}>
        <div style={{ textAlign: "center" }}>
          <YouTube
            videoId={video?.VideoID}
            opts={{
              ...opts,
              height: "300",
              width: "440",
            }}
            iframeClassName="Iframe-className"
          />
        </div>
      </div>
    ));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <div className="event-videos-carousel mb-4">
          <div
            className="dream-head text-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <h2 style={{ marginBottom: "10px" }}>Our Previous Events Videos</h2>
          </div>
          <Slider {...settings} className="dream-slider">
            {renderDestinations()}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CommonVideoSection;
