import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const StudentReview = ({ testimonialData }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    AOS.init();
    // Initialize AOS animations for the initial slide
    AOS.refresh();
  }, []);

  const renderTestimonials = () => {
    return testimonialData.map((testimonial, index) => (
      <div
        key={index}
        className="item"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay={(index + 1) * 100}
      >
        <div className="hear-sectionpara">
          <div className="css-dot">
            <div className="testiomnial-img">
              <img src={testimonial.StudentImg} alt="" />
            </div>
            <h5>{testimonial.Name}</h5>
            {testimonial.University}
            <p
              dangerouslySetInnerHTML={{ __html: testimonial.Descriptions }}
            ></p>
          </div>
        </div>
      </div>
    ));
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIcon
        onClick={onClick}
        className={className}
        width={120}
        style={{
          ...style,
          width: "30px",
          marginRight: "20px",
          height: "30px",
          display: "block",
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
        }}
        icon={faArrowRightLong}
      />
    );
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick}>
        <FontAwesomeIcon
          onClick={onClick}
          className={className}
          width={120}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            display: "block",
            background: "white",
            borderRadius: "8px",
            color: "#e7e7e7",
          }}
          icon={faArrowLeftLong}
        />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: () => {
      // Refresh AOS animations after slide change
      AOS.refresh();
    },
  };

  return (
    <div className="hear-section">
      <div className="container">
        <h2 data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          Let’s hear from our <span>Student</span>
        </h2>
        <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
          Our students send us a bunch of smiles with our services, and we love
          them
        </p>
        <Slider
          ref={sliderRef}
          {...settings}
          className="owl-carousel hear owl-theme"
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-delay="300"
        >
          {renderTestimonials()}
        </Slider>
      </div>
    </div>
  );
};

export default StudentReview;
