import React, { useState } from "react";
import addmissiondotpic from "../../../assets/image/addmission-dot-pic.png";
import { useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../common/constant";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";

const AdmissionSection = () => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [coutryError, setCountryError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    CoursePreference: "",
    CountryPreference: "",
  });
  const [formErrors, setFormErrors] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    CoursePreference: "",
  });

  const countries = [
    {
      value: 1,
      label: "USA",
    },
    {
      value: 2,
      label: "UK",
    },
    {
      value: 3,
      label: "Canada",
    },
    {
      value: 4,
      label: "Australia",
    },
    {
      value: 5,
      label: "New Zealand",
    },
  ];

  const handleCheckboxChange = (country) => {
    setSelectedCountries((prevSelectedCountries) => {
      const updatedCountries = prevSelectedCountries.includes(country.value)
        ? prevSelectedCountries.filter((c) => c !== country.value)
        : [...prevSelectedCountries, country.value];

      if (updatedCountries.length === 0) {
        setCountryError("At least one country required");
      } else {
        setCountryError("");
      }

      return updatedCountries;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: name !== "CountryPreference" && !value ? "Required." : "",
    });
  };

  const handleApplyNowClick = async (event) => {
    event.preventDefault();
    let IsError = false;
    if (selectedCountries.length < 1) {
      setCountryError("At least one country required");
      IsError = true;
    }
    const newFormErrors = {};
    for (const key in formData) {
      if (key !== "CountryPreference" && !formData[key]) {
        newFormErrors[key] = "Required.";
      }
    }

    if (Object.keys(newFormErrors).length > 0) {
      IsError = true;
      setFormErrors(newFormErrors);
    }

    if (IsError) {
      return;
    }

    let data = formData;
    data.OperationType = 1;
    data.CountryPreference = selectedCountries.join(", ");

    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageInquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (responseData.Status === 200) {
        SuccessToast(responseData?.Details);
        navigate("/thankyou");
        setFormData({
          Name: "",
          EmailID: "",
          MobileNo: "",
          CoursePreference: "",
          CountryPreference: "",
        });
        setFormErrors({
          Name: "",
          EmailID: "",
          MobileNo: "",
          CoursePreference: "",
        });
        setCountryError("");
      } else {
        ErrorToast(responseData?.Details);
        console.error("Failed to submit data to the API");
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred while submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="col-sm-12 col-lg-6">
      <div
        className="admission-para"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="300"
      >
        <h2>
          Get In <span>Touch</span>
        </h2>
        <div className="get-in-touch">
          <form onSubmit={handleApplyNowClick}>
            <input
              className={formErrors.Name ? "error-border" : ""}
              name="Name"
              type="text"
              placeholder="name"
              onChange={handleChange}
            />
            <input
              className={formErrors.EmailID ? "error-border" : ""}
              type="text"
              name="EmailID"
              placeholder="email address"
              onChange={handleChange}
            />
            <div className="input-phone">
              <input
                className={formErrors.MobileNo ? "error-border" : ""}
                type="number"
                name="MobileNo"
                placeholder="phone number"
                onChange={handleChange}
              />
              <label htmlFor="CoursePreference"></label>
              <select
                className={formErrors.CoursePreference ? "error-border" : ""}
                name="CoursePreference"
                id="CoursePreference"
                onChange={handleChange}
              >
                <option value="">Select Course</option>
                <option value="10">IELTS</option>
                <option value="4">GRE</option>
                <option value="3">PTE</option>
                <option value="5">SAT</option>
                {/* <option value="2">TOEFL</option> */}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {countries.map((country) => (
                <div
                  key={country.value}
                  style={{ display: "flex", width: "fit-content" }}
                >
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                    }}
                    type="checkbox"
                    value={country.value}
                    checked={selectedCountries.includes(country.value)}
                    onChange={() => handleCheckboxChange(country)}
                  />
                  <label> {country.label}</label>
                </div>
              ))}
            </div>

            {coutryError && <div className="error-message">{coutryError}</div>}

            <button className="apply-now" type="submit">
              Apply Now
            </button>
            <div className="admission-dot-pic">
              <img src={addmissiondotpic} alt="" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdmissionSection;
