import React from 'react';

const BannerSubBlog = ({title,imageSrc,date}) => {
  return (
    <React.Fragment>
    <div className="benner">
      <img src={imageSrc} alt="" />
      <div className="benner-para container">
        <div className="bennr-in-heading" data-aos="fade-up" data-aos-duration="700">
          <h2>{title}</h2>
           <h4>{date}</h4>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default BannerSubBlog;
