import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import "bootstrap/dist/css/bootstrap.min.css";
import PathRow from "./PathRow";
import { appBarData } from "./appBarData";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { openDialog } from "../../pages/Slice/dialogSlice";
import { useDispatch } from "react-redux";
const Header = () => {
  const dispatch = useDispatch();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  const handleSubmenuClick = (event) => {
    const parentListItem = event.target.closest("li");
    const siblings = Array.from(parentListItem.parentElement.children);

    siblings.forEach((sibling) => {
      if (sibling !== parentListItem) {
        sibling.classList.remove("active-menu");
      }
    });

    parentListItem.classList.toggle("active-menu");
  };

  const handleMenuItemClick = () => {
    closeMenu();
  };
  const headerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  };

  return (
    <>
      <header style={headerStyle}>
        <div className="container-fluid">
          <div className="header-middle">
            <Logo />
            <div className="menu-col">
              <button className="menu-toggle" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div
                className={`navigation-main ${
                  isMenuActive ? "menu-active" : ""
                }`}
              >
                <div className="close-btn-menu" onClick={closeMenu}></div>
                <ul className="main-nav">
                  {appBarData.map((item, index) => (
                    <li
                      key={index}
                      className={item.submenus.length > 0 ? "dropdown" : ""}
                    >
                      {item.submenus.length > 0 ? (
                        <>
                          <div
                            className="dropdown-header"
                            onClick={handleSubmenuClick}
                          >
                            {item.label}
                            <KeyboardArrowDownSharpIcon fontSize="small" />
                          </div>
                          <div className="dropdown-content">
                            <ul>
                              {item.submenus.map((submenu, subIndex) => (
                                <li key={subIndex}>
                                  <Link
                                    to={submenu.link}
                                    onClick={() => {
                                      handleMenuItemClick();
                                    }}
                                  >
                                    {submenu.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <Link
                          to={item.link}
                          onClick={() => {
                            handleMenuItemClick();
                          }}
                        >
                          {item.label}
                        </Link>
                      )}
                    </li>
                  ))}
                  <li
                    className="apply-now"
                    onClick={() => dispatch(openDialog())}
                  >
                    <Link to="#">apply now</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <PathRow />
    </>
  );
};

export default Header;
