import React, { useEffect, useState } from "react";
// import AOS from "aos";
import CommonEventBannerSection from "./CommonEventBannerSection";
// import CommonEventRegForm from "./CommonEventRegForm";
import CommonVideoSection from "./CommonVideoSection";
import { getYouTubeVideoId } from "../../../common/commonHelpers";
import {
  BACKEND_BASE_URL,
  PORTAL_BACKEND_BASE_URL,
} from "../../../common/constant";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";
import CommonModifiedEventRegForm from "./CommonModifiedEventRegForm";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import InvertedComma from "../../../assets/image/white-quoter.png";
import Shreya from "../../../assets/image/testimonial shreya.jpeg";
import Bansi from "../../../assets/image/testimonial bansi.jpg";
import Mahadev from "../../../assets/image/testimonial mahadev.jpg";
import Prashant from "../../../assets/image/testimonial prashant.jpeg";
import Sanya from "../../../assets/image/sanya shah.jpg";
import Krish from "../../../assets/image/krish joshi.jpg";
import Manank from "../../../assets/image/manank.jpg";
import Nimesh from "../../../assets/image/nimesh shadhu.jpg";
import Aaryan from "../../../assets/image/aaryan.jpg";

import WomenAvatar from "../../../assets/image/women avatar.jpg";
import CareerlineLogo from "../../../assets/image/logo.png";
import RightAdvice from "../../../assets/image/rightadvice.png";
import Attend1 from "../../../assets/image/attend1.png";
import Attend2 from "../../../assets/image/attend2.png";
import Attend3 from "../../../assets/image/attend3.png";
import Attend4 from "../../../assets/image/attend4.png";
import AUSUniLogo from "../../../assets/image/universitylogo/aus/The-University-of-Western-Australia-logo-profile.jpg";
import CANUniLogo1 from "../../../assets/image/universitylogo/can/Toronto Metropolitan University International College 1.png";
import CANUniLogo2 from "../../../assets/image/universitylogo/can/Centennial_College_Centennial_College_and_International_Business.jpg";
import CANUniLogo3 from "../../../assets/image/universitylogo/can/Algonquin copy.png";
import CANUniLogo4 from "../../../assets/image/universitylogo/can/Cambrian College.png";
import CANUniLogo5 from "../../../assets/image/universitylogo/can/Cape Breton University Logo.jpg";
import UKUniLogo1 from "../../../assets/image/universitylogo/uk/Liverpool John Moores University Logo copy.png";
import UKUniLogo2 from "../../../assets/image/universitylogo/uk/Newcastle-University-Logo.png";
import UKUniLogo3 from "../../../assets/image/universitylogo/uk/Queensred.png";
import UKUniLogo4 from "../../../assets/image/universitylogo/uk/Uni_Landscape_Pos_Lrg-e1662406285626-_1_.png";
import USAUniLogo10 from "../../../assets/image/universitylogo/usa/Roosevelt University Logo.png";
import USAUniLogo1 from "../../../assets/image/universitylogo/usa/University of Arizona.png";
import USAUniLogo2 from "../../../assets/image/universitylogo/usa/University of Illinois at Chicago.png";
import USAUniLogo3 from "../../../assets/image/universitylogo/usa/CLEVELAND STATE UNIVERSITY.jpg";
import USAUniLogo4 from "../../../assets/image/universitylogo/usa/California State University San Marcos.png";
import USAUniLogo5 from "../../../assets/image/universitylogo/usa/massachusettsamherst.png";
import USAUniLogo6 from "../../../assets/image/universitylogo/usa/Montclair State University.png";
import USAUniLogo7 from "../../../assets/image/universitylogo/usa/post university.png";
import USAUniLogo8 from "../../../assets/image/universitylogo/usa/Florida Atlantic University.png";
import USAUniLogo9 from "../../../assets/image/universitylogo/usa/texas aandm university.jpg";
import { Element, Link } from "react-scroll";

const CommonMainRegForm = () => {
  const dispatch = useDispatch();
  const { Campaign, StudentID } = useParams();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [data, setData] = useState({});
  const [commonEventID, setCommonEventID] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignTypeList, setCampaignTypeList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollToTop();
    // AOS.init();
    const fetchHomePage = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${BACKEND_BASE_URL}/Master/ManageCMSDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ CMSURL: "CommonEventID" }),
          }
        );
        const data = await response.json();
        if (data.Status === 200) {
          setCommonEventID(data?.Data?.Template);
        } else {
          console.log("Failed to fetch blogs");
        }
      } catch (error) {
        console.log("Error while fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomePage();
    // return () => {
    //   dispatch(clearEventID());
    // };
  }, []);

  useEffect(() => {
    if (commonEventID) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/findbyid?id=${
              commonEventID
              // eventID
              // eventID || commonEventID
            }`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const Response = await response.json();
          const result = Response?.Data;
          const updatedVideoUrls = result?.VideoURL.map((item) => {
            const videoId = getYouTubeVideoId(item.VideoURL);
            return { VideoID: videoId, ...item };
          });
          setData({
            BannerImage: result?.BannerImage,
            SideBanner: result?.SideBanner,
            CMSHeader: result?.CMSHeader,
            CMSFooter: result?.CMSFooter,
            EventCourse: result?.EventCourse ? result?.EventCourse : [],
            EventContry: result?.EventContry ? result?.EventContry : [],
            TimeSlotList: result?.TimeSlotList ? result?.TimeSlotList : [],
            VideoURL: updatedVideoUrls ? updatedVideoUrls : [],
            RegisteredPercentage: result?.RegisteredPercentage
              ? result?.RegisteredPercentage
              : null,
            Campaign: Campaign,
            StudentID: StudentID,
          });
        } catch (error) {
          setError(error?.message || "NetWrok Error");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
      // if (StudentID?.toLowerCase() === "add") {
      const getAllCampaigns = async () => {
        try {
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/CampaignEventWise`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                EventID: commonEventID,
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Network Error fetching campaign types");
          }
          const result = await response.json();
          const dataList = result?.Data;
          const finalResult = dataList?.map((campaign) => {
            return {
              value: campaign.ID,
              label: campaign.CampaignName,
              CampaignTypeID: campaign.CampaignTypeID,
              CampaignURL: campaign.CampaignURL,
            };
          });
          setCampaignList(finalResult || []);
        } catch (error) {
          console.error("Error fetching campaign:", error);
        }
      };
      const getAllCampaignTypes = async () => {
        try {
          const response = await fetch(
            `${PORTAL_BACKEND_BASE_URL}/eventregistration/CampaignTypeEventWise`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                EventID: commonEventID,
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Network Error fetching campaign types");
          }
          const result = await response.json();
          const dataList = result?.Data;
          const finalResult = dataList?.map((campaign) => {
            return {
              value: campaign.CampaignTypeID,
              label: campaign.CampaignTypeName,
            };
          });
          setCampaignTypeList(finalResult || []);
        } catch (error) {
          console.error("Error fetching campaign:", error);
        }
      };
      getAllCampaigns();
      getAllCampaignTypes();
      // }
    }
  }, [commonEventID]);
  // }, [eventID]);
  // }, [eventID, homeData]);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-prev-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowLeftLong}
        />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          height: 45,
          width: 45,
          background: "white",
          borderRadius: "8px",
          color: "#414c68",
          boxShadow: "0 0 10px lightgray",
        }}
        className="testimonial-next-arrow"
        onClick={onClick}
      >
        <FontAwesomeIcon
          className={className}
          width={50}
          style={{
            ...style,
            width: "30px",
            height: "30px",
            color: "black",
          }}
          icon={faArrowRightLong}
        />
      </div>
    );
  };

  const settings = {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonialData = [
    {
      img: `${Shreya}`,
      description:
        "I had an exceptional experience with Kathan Sir, Maulin Sir, and Nikhil Sir for my USA study visa counseling! Their expertise, guidance, and support throughout the process were invaluable. Together, they made a fantastic team! Their dedication, professionalism, and kindness made my study visa journey seamless and successful. I highly recommend them to anyone seeking guidance for their study visa applications. Thank you, Kathan Sir, Maulin Sir, and Nikhil Sir, for your outstanding support and mentorship!",
      name: "Shreya Vaghela",
      pragram: "Master of Science in Artificial Intelligence",
      university: "Long Island University",
      intake: "SEPT_2024",
      country: "USA",
      background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
    },
    {
      img: `${Bansi}`,
      description:
        "I had an amazing experience at Careerline education during my all visa process. the counsellors are very knowledgeable & supportive. I would especially like to thank Hetal Ma'am & Kishan sir who helped me throughout the all process and solved my all queries without giving me any stress. really Careerline Education provide excellent service to their all students..💯🤝",
      name: "Bansi Savaliya",
      pragram: "MSc Biomedical Laboratory Science",
      university: "Sheffield Hallam University",
      intake: "SEPT_2024",
      country: "UK",
      background: "linear-gradient(45deg, #c09500, goldenrod)",
    },
    {
      img: `${Mahadev}`,
      description:
        "I applied for my UK visa as well as had my IELTS preparation from Careerline and they have been supportive to me at every step of the way especially Kishan sir and Anshul sir guiding me through all the universities of the UK and helping me apply for admission and visa. They have always been swift in solving any and all queries I’ve had during the process. My experience with IELTS classes was also great and had support from highly skilled faculties especially Poonam mam who conducted all of my lectures and solved my doubts, supported me wherever I lacked and made sure I was ready for my examination. I highly recommend Careerline to everyone who is planning on going abroad for further education.",
      name: "Mahadev Vaghela",
      pragram: "MSc Astrophysics",
      university: "University of Glasgow",
      intake: "SEPT_2024",
      country: "UK",
      background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
    },
    {
      img: `${Prashant}`,
      description:
        "I am delighted to share my positive experience working with Careeline during my student visa process for Newzeland, for my master’s degree at AUT. From the very beginning, Mr Hardik Sir demonstrated exceptional expertise and dedication. What stood out the most was their unwavering support and patience. No matter how many questions I had or how many times I needed clarification, Hardik Sir was always available and willing to assist. They provided timely updates and kept me informed at every step, making the whole process smooth and stress-free. Thanks to Hardik Sir and his team's expertise and assistance, I successfully obtained my student visa and am now excited to start my studies at AUT. I highly recommend Careerline to anyone seeking reliable and professional support in their visa application journey. Thank you, Careerline, for your outstanding service and support!",
      name: "Prashant Multani",
      pragram: "Master of Construction Management",
      university: "Auckland University of Technology",
      intake: "JULY_2024",
      country: "NZ",
      background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
    },
    {
      img: `${Manank}`,
      description: `Careerline Education Foundation has been instrumental in my journey toward higher education in USA. Their team of experts provided invaluable guidance and support throughout the entire process, from selecting the right university to preparing for visa interviews. Special thanks to Hardik Sir and Saurabh Sir, whose precise information and step-by-step assistance made everything smooth and manageable. The staff at Careerline is knowledgeable, patient, and genuinely invested in their students' success. I highly recommend Careerline Education Foundation to anyone seeking reliable and comprehensive educational support. Their motto, "HAR KADAM PAR RIGHT ADVICE," truly reflects their commitment to excellence.`,
      name: "Manank Bhavsar",
      pragram: "Master of Science in Electrical And Computer Engineering",
      university: "Portland State University",
      intake: "SEPT_2024",
      country: "USA",
      background: "linear-gradient(45deg, #c09500, goldenrod)",
    },
    {
      img: `${Sanya}`,
      description: `I am incredibly grateful to Careerline for their outstanding support throughout my visa process. The dedication and professionalism demonstrated by Karsh Sir, Upasha Mam, and Bhavin Sir were truly exceptional. Thanks to their hard work and meticulous attention to detail, my visa was approved within just 7 minutes of the application being processed! Karsh Sir and Upasha Mam went above and beyond to ensure my profile was robust and compelling. Their expertise and guidance were instrumental in securing my visa promptly. I highly recommend Careerline to anyone seeking reliable and efficient visa services. Their commitment to their clients' success is unparalleled. Thank you, Careerline, for making this process smooth and stress-free!`,
      name: "Sanya Shah",
      pragram: "Master of Information Technology (Artificial Intelligence)",
      university: "Macquarie University ",
      intake: "JULY_2024",
      country: "AUS",
      background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
    },
    {
      img: `${Krish}`,
      description:
        "I have recently secured my Canadian visa for acadia University where i am going to pursue my bachelor of computer science in fall 2024 intake I would specifically thank Megha Ma'am who was my counsellor she is the best counsellor i have ever met ….the process was very smooth with the help of her i am able to get admission in the best university that suits my profile moreover i would like to thank each and every member of the staff for helping me initially and potentially both..i would highly recommend careerline for any kind of visa process as they give haar kadam par right advise.",
      name: "Krish Joshi",
      pragram: "Bachelor of Computer Science",
      university: "Acadia University",
      intake: "SEPT_2024",
      country: "CAN",
      background: "linear-gradient(45deg, goldenrod, rgb(187, 52, 56))",
    },
    {
      img: `${Aaryan}`,
      description:
        "I cannot express enough gratitude to Careerline Institute for their outstanding support and guidance throughout my journey to study in Canada. From admission to visa acquirement to even preparation for the IELTS exam, they've been with me every step of the way. Bhavin Sir, the owner, is a visionary leader who understands the complexity of the process and ensures everything runs seamlessly. Thanks to the collective efforts of this fantastic team, I not only achieved my dream of studying in Canada but also excelled in the IELTS exam. I wholeheartedly recommend Careerline Institute to anyone seeking professional assistance in their academic pursuits. They truly go above and beyond to ensure success!",
      name: "Aryan Shah",
      pragram: "Game Art",
      university: "Durham College",
      intake: "SEPT_2024",
      country: "CAN",
      background: "linear-gradient(45deg, #c09500, goldenrod)",
    },
    {
      img: `${Nimesh}`,
      description:
        "Thank you CareerLine team for consultation for Australian Visa.I am greatful to you for excellence guidance. Bhavin sir, Aayushi mem, Dipika mem and Karsh sir thank you so much to enlighten my path to my journey by your excellence expertise from the beginning to the final visa approval stage. It become possible because of your hardwork I'm really thankful to all member of Careerline foundation.",
      name: "Nimesh Sadhu",
      pragram: "Master of Professional Engineering (Civil)",
      university: "QUT",
      intake: "JULY_2024",
      country: "AUS",
      background: "linear-gradient(45deg, rgb(101 154 255), rgb(38, 78, 154))",
    },
    {
      img: `${WomenAvatar}`,
      description:
        "Recently I got my Canadian student visa within 15 days just because of Careerline . Especially Ami mam and Ayush bhai are very supportive.They help a lot in the whole filing process. Guys if uh want to process your file for canada student visa then must visit Careerline. Don't get late& Again heartly thanx to Ami mam and Ayushbhai.",
      name: "Foram Darji",
      pragram: "Healthcare Administration Management—Nursing Leadership ",
      university: "Conestoga College",
      intake: "SEPT_2024",
      country: "CAN",
      background: "linear-gradient(45deg, #c09500, goldenrod)",
    },
  ];

  const faqData = [
    {
      question: "What is the World Education Fair 2024?",
      answer:
        "The World Education Fair 2024 is an event organized by Careerline Education Foundation where students can explore educational opportunities abroad. It features representatives from 80+ colleges and universities from countries like the USA, UK, Canada, Australia, and New Zealand.",
    },
    {
      question: "Why should I attend the fair?",
      answer:
        "Attending the World Education Fair 2024 offers a unique opportunity to meet one-on-one with university admission officers, providing you with personalized guidance on your academic and career aspirations. Additionally, the event offers significant benefits, including application fee waivers and exclusive discounts on test preparation courses. By applying on the spot, you can save between INR 5,000 and INR 25,000, making this an excellent opportunity to streamline your path to studying abroad while also reducing your overall costs.",
    },
    {
      question: "When and where is the event taking place?",
      answer:
        "The event will be held on Saturday, 31st August 2024, from 03:30 PM to 07:30 PM at Careerline Edu, 601-608, AWS-3, Block-A, Nr. Manav Mandir, Memnagar, Ahmedabad.",
    },
    {
      question: "Who can attend the fair?",
      answer:
        "The fair is open to students, parents, and anyone interested in exploring study abroad opportunities. Whether you're looking for undergraduate, postgraduate, or other specialized programs, this event will have something for you.",
    },
    {
      question: "How can I register for the event?",
      answer:
        "You can register for the event by clicking the provided link or by contacting the following numbers: +91 6358 931 838, +91 6358 989 873.",
    },
    {
      question: "What should I expect at the fair?",
      answer:
        "At the fair, you will have the opportunity to interact with representatives from 80+ international colleges and universities. You can get personalized counseling and guidance on courses, admission procedures, visa processes, and more. Learn about scholarships, education loans, and financial planning for studying abroad.",
    },
    {
      question: "Is there any cost to attend the fair?",
      answer: "No, it's free to attend the fair.",
    },
    {
      question:
        "Will there be any on-the-spot admissions or application assistance?",
      answer:
        "Yes, many universities often provide on-the-spot application assessments and admissions during such fairs. It’s advisable to bring your academic documents, test scores, and any other relevant paperwork.",
    },
    {
      question: "What documents should I bring to the fair?",
      answer:
        "You should bring: Academic transcripts and certificates, English proficiency test scores (e.g., IELTS, TOEFL, PTE, GRE), Statement of Purpose (SOP), Letters of Recommendation (LOR), Passport-sized photographs, and any other relevant documents for university applications.",
    },
    {
      question:
        "How can I learn more about the universities participating in the fair?",
      answer:
        "You can scroll up the page to get information about the universities participating in the fair.",
    },
    {
      question: "What kind of support will be available at the fair?",
      answer:
        "There will be professional counselors, university representatives, and financial advisors present to assist with all aspects of studying abroad, including course selection, applications, visas, and financial planning.",
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {/* <div>
        <CommonEventBannerSection data={data} />
      </div> */}
      <div className="event-banner-section">
        <div></div>
        <div className="event-banner-bottom">
          <h1
            className="text-white "
            style={{ textShadow: "0 0 10px rgb(0 0 0 / 50%)" }}
          >
            Discover Your Global Education Journey!
          </h1>
          <p style={{ fontSize: 18, textShadow: "0 0 10px rgb(0 0 0 / 100%)" }}>
            Meet representatives from top universities worldwide and explore
            endless opportunities.
          </p>
          <div style={{ padding: "10px 0" }}>
            <Link
              activeClass="active"
              to="test1"
              offset={-120}
              duration={300}
              className="event-banner-btn"
            >
              Register Now
            </Link>
          </div>
        </div>
      </div>
      <div className="event-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="event-hero-label">Event Details</h3>
              <div className="event-details-info mt-5">
                <p className="fw-bold">Where & when</p>
                <div className="d-sm-flex">
                  <div className="d-flex gap-2 align-items-center mb-3 w-sm-50 w-100">
                    <i
                      className="bi bi-calendar-minus"
                      style={{ color: "#ff7a00", fontSize: 25 }}
                    ></i>
                    <p className="m-0">
                      <span className="fw-bold">Date & Time</span>
                      <br />
                      August 31, 2024 <br />
                      3:30 PM to 7.30 PM
                    </p>
                  </div>
                  <div className="d-flex gap-2 align-items-center w-sm-50 w-100">
                    <i
                      className="bi bi-geo-alt"
                      style={{ color: "#ff7a00", fontSize: 25 }}
                    ></i>
                    <p className="m-0">
                      <span className="fw-bold">Location</span>
                      <br />
                      Careerline Education, 601-608, AWS-III Block-A, Nr. Manav
                      Mandir, Memnagar Ahmedabad.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <h4
                className="mt-sm-1 mb-2 text-start"
                style={{
                  // textAlign: "center",
                  color: "rgb(220 86 0)",
                  // fontWeight: 700,
                }}
              >
                80+ University/college participants
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="event-attend-section">
        <h2
          className="text-center mt-5 mb-5 text-white "
          style={{ fontWeight: 500 }}
        >
          Why Attend?
        </h2>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6 d-flex">
              <img src={Attend1} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>Personal Meetings with University Representatives</h5>
                <p>
                  Engage directly with university representatives to get one to
                  one information and answers to your specific queries.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <img src={Attend2} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>Personalized Guidance on Courses and Programs</h5>
                <p>
                  Receive advice on selecting the right courses and programs
                  that align with your academic and career goals.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <img src={Attend3} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>
                  Insights into Visa Processes and Scholarship Opportunities
                </h5>
                <p>
                  Gain valuable information about visa procedures and explore
                  various scholarship options to fund your education from
                  universities official.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <img src={Attend4} alt="Image" className="img-fluid" />
              <div className="box">
                <h5>Networking Opportunities</h5>
                <p>
                  Connect with fellow aspirants and alumni, expanding your
                  network and learning from their experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-uni-section">
        <div className="container">
          <div className="row">
            {/* <p className="fw-600">University Partners</p> */}
            <div className="col-12">
              <h3 className="event-uni-label">
                <span className="uni-count">300+</span> Partner Universities
              </h3>
            </div>
            <div className="uni-list mt-5 mb-5">
              <div className="row">
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={AUSUniLogo} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={CANUniLogo1} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={CANUniLogo2} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={CANUniLogo3} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={CANUniLogo4} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={CANUniLogo5} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={UKUniLogo1} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={UKUniLogo2} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={UKUniLogo3} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={UKUniLogo4} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo10} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo1} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo2} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo3} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo4} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo5} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo6} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img
                    src={USAUniLogo7}
                    alt="image"
                    className="img-fluid"
                    style={{ background: "#632D4F", padding: 4 }}
                  />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo8} alt="image" className="img-fluid" />
                </div>
                <div className="uni-img-div col-6 col-sm-4 col-lg-3 mb-3">
                  <img src={USAUniLogo9} alt="image" className="img-fluid" />
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-8 col-lg-6 offset-0 offset-sm-2 offset-lg-3">
              <p
                className="uni-description m-0"
                style={{
                  textAlign: "center",
                  color: "#bd6500",
                  fontWeight: 700,
                }}
              >
                Admissions counselling service to these universities is FREE for
                students.
              </p>
              <p className="uni-description" style={{ textAlign: "center" }}>
                As their official partners, we receive a student advisory fee
                from universities.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <Element
        name="test1"
        className="event-reg-form"
        id="event-registration-section"
      >
        {/* <CommonEventRegFormWithBanner
          data={data}
          CommonEventID={commonEventID}
          campaignList={campaignList}
          campaignTypeList={campaignTypeList}
          setLoading={setLoading}
        /> */}
        <CommonModifiedEventRegForm
          data={data}
          CommonEventID={commonEventID}
          campaignList={campaignList}
          campaignTypeList={campaignTypeList}
          setLoading={setLoading}
        />
        {/* <CommonEventRegForm
          data={data}
          // CHANGES REMAINING FOR ONLY EVENTID
          CommonEventID={commonEventID}
          campaignList={campaignList}
          // CommonEventID={eventID}
          // CommonEventID={eventID || commonEventID}
          setLoading={setLoading}
        /> */}
      </Element>
      <div className="event-testimonials">
        <div className="container pt-5 pb-5">
          <div className="row">
            <p className="fw-600">30000+ Success Stories</p>
            <div className="col-12 mb-5">
              <h3 className="event-testimonials-label">
                From Dreamers{" "}
                <span className="testimonial-hightlight">to Achievers</span>
              </h3>
            </div>
            <div className="testimonials-list ">
              <Slider className="owl-carousel popular owl-theme" {...settings}>
                {testimonialData.map((item, index) => (
                  <RenderTestimonialCard
                    key={index}
                    uniqueKey={index}
                    description={item?.description}
                    img={item?.img}
                    name={item?.name}
                    background={item?.background}
                    university={item?.university}
                    pragram={item?.pragram}
                    intake={item?.intake}
                    country={item?.country}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="event-faqs">
        <div className="container pt-5 pb-5">
          <div className="row">
            <h2 className="text-center" style={{ fontWeight: 500 }}>
              FAQs
            </h2>
            <div className="faqs-list mt-3">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {faqData.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-headingOne${index}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls="flush-collapseOne"
                      >
                        {item?.question}
                      </button>
                    </h2>
                    <div
                      id={`flush-headingOne${index}`}
                      className={`accordion-collapse collapse  ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item?.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-footer">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 d-flex justify-content-between pb-5 footer-imgs">
              <img src={CareerlineLogo} alt="logo" />
              <img src={RightAdvice} alt="logo" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-5">
              <h3>Contact us</h3>
              <div className="footer-top-para-right">
                <p className="pb-3">
                  <i className="bi bi-envelope" />
                  <a href="mailto:admin@careerline.org">admin@careerline.org</a>
                </p>
                <i className="bi bi-telephone-plus" />
                <a
                  href="tel:6358989872"
                  // contentEditable="false"
                  style={{ cursor: "pointer" }}
                >
                  +91-6358989872
                </a>
              </div>
              <div className="footer-socialmedia mt-4">
                <a
                  href="https://www.facebook.com/CareerlineEF/"
                  target="_blank"
                >
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://twitter.com/Careerlineef" target="_blank">
                  <i className="bi bi-twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/careerlineef/"
                  target="_blank"
                >
                  <i className="bi bi-linkedin" />
                </a>
                <a
                  href="https://www.youtube.com/c/CareerlineEducationFoundation/"
                  target="_blank"
                >
                  <i className="bi bi-youtube" />
                </a>
                <a
                  href="https://www.instagram.com/careerlineef/"
                  target="_blank"
                >
                  <i className="bi bi-instagram" />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-9 ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12349.140012101447!2d72.53275668773627!3d23.045011391639978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac8a7faf3%3A0x605d0c100abfcc1a!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1724236290085!5m2!1sen!2sin"
                width={"100%"}
                height={200}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="event-faqs">

        <div>
          <CommonVideoSection data={data} />
        </div>
        <div className="event-reg-form">
          <div className="crms-title row mb-4">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.CMSFooter,
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};
const RenderTestimonialCard = (props) => {
  return (
    <div key={props.uniqueKey} className="item pt-3">
      <div
        className="testimonials"
        // data-aos="fade-up"
        // data-aos-duration="700"
        // data-aos-delay={props.uniqueKey * 200}
        style={{ background: "white" }}
      >
        <div
          className="testimonials-item-top p-3 position-relative"
          style={{ background: props?.background }}
        >
          <div
            className="testimonials-item-effect"
            style={{ background: props.effectColor }}
          ></div>
          <div className="testimonials-item-desc">
            <img
              src={InvertedComma}
              style={{ marginRight: 25, display: "inline" }}
            />
            {props.description}{" "}
            <img
              src={InvertedComma}
              style={{
                marginLeft: 25,
                display: "inline",
                transform: "rotate(180deg)",
              }}
            />
          </div>
        </div>
        <div className="testimonials-item-bottom  p-3">
          <div className="textimonial-img">
            <img src={props.img} alt="Student img" className="img-fluid" />
          </div>
          <div style={{ fontSize: 12, display: "flow-root" }}>
            <span className="fw-bold" style={{ fontSize: 15 }}>
              {props.name}, {props.country}
            </span>
            <br />
            <span>{props.pragram}</span>, <br />
            at&nbsp;<span>{props.university}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonMainRegForm;
