import React from "react";
import { Link } from "react-router-dom";
import CWC from "../../../assets/image/cwc/Coffee with careerline2.png";
import BhavinThakkar from "../../../assets/image/cwc/Mr Bhavin Thaker2.png";
import CWCAttend1 from "../../../assets/image/cwc/cwc attend 1.png";
import CWCAttend2 from "../../../assets/image/cwc/cwc attend 2.png";
import CWCAttend3 from "../../../assets/image/cwc/cwc attend 3.png";
import CWCAttend4 from "../../../assets/image/cwc/cwc attend 4.png";
import Overview1 from "../../../assets/image/cwc/Personalized Counseling Sessions2.png";
import Overview2 from "../../../assets/image/cwc/InDepth QA Sessions2.png";
import Man2 from "../../../assets/image/cwc/man 2.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import CWCEventForm from "../CoffeeWithCareearline/CWCEventForm";
import { RxCalendar } from "react-icons/rx";
import { WiTime4 } from "react-icons/wi";
import { AiOutlineShop } from "react-icons/ai";
import { MdArrowCircleRight } from "react-icons/md";

const testimonials = [
  {
    name: "Priya Shah",
    text: "This event was a game changer! I received a scholarship and fee waiver for my application to a top USA university. Don’t miss it!",
  },
  {
    name: "Amit Patel",
    text: "Thank you Careerline for organizing such event that I got opportunity to meet representatives of universities & got a lot of guidance.",
  },
  {
    name: "Neel Patel",
    text: "The direct interaction with university representatives helped me understand the admission process better. Highly recommended!",
  },
];

const CWCEventPage = () => {
  return (
    <div>
      <div className="cwc2-event-banner-section"></div>
      <div className="cwc2-event-hero-section pt-5 ">
        <div className="container pt-lg-5 pb-lg-5">
          <div className="row">
            <div className="col-lg-6 pb-5">
              <p className="m-0 hero-subline">
                Join us for an exclusive, <b>in-person coffee session</b>, where
                our
                <i>expert counselors</i> will offer expert insights and
                solutions tailored to your <b>study abroad goals!</b>
              </p>
              <div className="section2-event-details">
                <img src={CWC} className="cwc-pic" />
                <h3
                  className="pb-2"
                  style={{ borderBottom: "1px solid black" }}
                >
                  Event Details
                </h3>
                <p>
                  <RxCalendar className="text-dark" />
                  &nbsp; Date &nbsp;&nbsp;&nbsp;:&nbsp; <b>Date</b>
                </p>
                <p>
                  <WiTime4 className="text-dark" />
                  &nbsp; Time &nbsp;&nbsp;&nbsp;:&nbsp; <b>Time</b>
                </p>
                <p>
                  <AiOutlineShop className="text-dark" />
                  &nbsp; Venue : &nbsp; <b>Shambhu's Cafe</b>
                </p>
              </div>
            </div>
            <div className="col-lg-6 pb-5 d-flex justify-content-center">
              <CWCEventForm />
            </div>
          </div>
        </div>
      </div>
      <div className="cwc2-event-section-3 pt-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <h1 className="mb-0">
                <span className="text-dark">Why Attend</span> Coffee with
                Careerline?
              </h1>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12 col-sm-6 col-xl-3  p-2">
                  <div className="attend-card">
                    <div className="d-flex">
                      <div style={{ width: "75%" }}>
                        <h3 style={{ color: "#88C5F9" }}>Expert Guidance</h3>
                      </div>
                      <div
                        style={{ width: "25%" }}
                        className="d-flex justify-content-end align-items-baseline"
                      >
                        <img src={CWCAttend1} width={"50px"} />
                      </div>
                    </div>
                    <p className="pt-4">
                      Benefit from over <b>23 years of experience</b> in the
                      field of international education. Our counselors will
                      provide you with <b>personalized advice</b> based on your
                      academic background and aspirations.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3  p-2">
                  <div className="attend-card">
                    <div className="d-flex">
                      <div style={{ width: "75%" }}>
                        <h3 style={{ color: "#88C5F9" }}>
                          Tailored Study Abroad Plans
                        </h3>
                      </div>
                      <div
                        style={{ width: "25%" }}
                        className="d-flex justify-content-end align-items-baseline"
                      >
                        <img src={CWCAttend2} width={"50px"} />
                      </div>
                    </div>
                    <p className="pt-4">
                      We understand that every student's journey is unique. Our
                      sessions focus on providing you with a customized roadmap—
                      from <b>selecting universities</b> to <b>scholarship</b>{" "}
                      opportunities and <b>visa applications.</b>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3  p-2">
                  <div className="attend-card">
                    <div className="d-flex">
                      <div style={{ width: "75%" }}>
                        <h3 style={{ color: "#88C5F9" }}>
                          Experienced counselors on hand
                        </h3>
                      </div>
                      <div
                        style={{ width: "25%" }}
                        className="d-flex justify-content-end align-items-baseline"
                      >
                        <img src={CWCAttend3} width={"50px"} />
                      </div>
                    </div>
                    <p className="pt-4">
                      This is not a large-scale event; it’s a focused, intimate
                      gathering where you can engage in meaningful one-on-one
                      discussions with professionals who have successfully
                      placed 30,000+ students in leading universities worldwide.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3  p-2">
                  <div className="attend-card">
                    <div className="d-flex">
                      <div style={{ width: "75%" }}>
                        <h3 style={{ color: "#88C5F9" }}>Free Consultation</h3>
                      </div>
                      <div
                        style={{ width: "25%" }}
                        className="d-flex justify-content-end align-items-baseline"
                      >
                        <img src={CWCAttend4} width={"50px"} />
                      </div>
                    </div>
                    <p className="pt-4">
                      Receive invaluable <b>expert guidance</b> at no cost to
                      you. This session could be the first step toward realizing
                      your dream of studying abroad.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "50px 0", textAlign: "center" }}>
              <div className="event-banner-btn" style={{ cursor: "pointer" }}>
                <Link
                  activeClass="active"
                  // to="test1"
                  offset={-120}
                  duration={300}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    marginRight: "45px",
                  }}
                >
                  Reserve Your Spot Today!
                </Link>
                <div
                  style={{
                    alignItems: "center",
                    width: "50px",
                    justifyContent: "center",
                    border: "1px solid rgb(39, 90, 166)",
                    borderImage: "initial",
                    position: "absolute",
                    top: -1,
                    right: -1,
                    bottom: -1,
                    display: "flex",
                    background: "white",
                    color: "#275AA6",
                    borderRadius: "6px",
                  }}
                >
                  <MdArrowCircleRight style={{ fontSize: "25px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc2-event-section-4 p-0 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className=" mb-0">
                Event Overview:{" "}
                <span
                  style={{ fontWeight: 200, fontFamily: "Poppins, sans-serif" }}
                >
                  What to Expect
                </span>
              </h1>
              <div className="row mt-5 p-3">
                <div className="col-12 pb-4">
                  <div
                    className="row"
                    style={{ background: "#fff", borderRadius: 20 }}
                  >
                    <div className="col-2 d-flex align-items-center">
                      <img src={Overview1} />
                    </div>
                    <div className="col-10 p-3">
                      <h4 className="mb-3">Personalized Counseling Sessions</h4>
                      <p>
                        Sit down with our experienced mentors for tailored
                        advice on everything from university selection to visa
                        guidance. We’ll answer all your questions— whether they
                        are about course options, costs, or career opportunities
                        abroad
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 pb-4">
                  <div
                    className="row"
                    style={{ background: "#fff", borderRadius: 20 }}
                  >
                    <div className="col-2 d-flex align-items-center">
                      <img src={Overview2} />
                    </div>
                    <div className="col-10 p-3">
                      <h4 className="mb-3">In-Depth Q&A Sessions</h4>
                      <p>
                        Get clarity on the most pressing questions about
                        studying abroad:
                        <ul className="ms-4">
                          <li>
                            What are the best universities for your chosen
                            field?
                          </li>
                          <li>
                            How can you increase your chances of securing a
                            scholarship?
                          </li>
                          <li>
                            What are the steps involved in obtaining a student
                            visa?
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 pb-4">
                  <div
                    className="row"
                    style={{ background: "#fff", borderRadius: 20 }}
                  >
                    <div className="col-2 d-flex align-items-center">
                      <img src={Overview2} />
                    </div>
                    <div className="col-10 p-3">
                      <h4 className="mb-3">
                        Step-by-Step Application Assistance
                      </h4>
                      <p>
                        Our counselors will walk you through the application
                        process, including important deadlines, required
                        documents, and key strategies for a successful
                        application
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 pt-2">
              <div className="event-banner-btn">
                <button>
                  Book Your Free Consultation Now!{" "}
                  <MdArrowCircleRight
                    style={{ fontSize: "28px", marginLeft: 10 }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc2-event-section-5 pt-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3 mb-lg-4">
              <h1 className="mb-0">
                <span className="text-dark">Meet Your </span>
                Mentors
              </h1>
            </div>
            <div className="col-lg-8">
              <p className="mb-4">
                At <b>Careerline</b>, our team of mentors is made up of industry
                experts who have guided thousands of students toward success.
                With over <b>two decades</b> of experience, we have established
                strong connections with leading universities in{" "}
                <b>6+ countries</b>, including the{" "}
                <b>U.S., U.K., Canada, and Australia.</b>
              </p>
              <p className="mb-4">
                Our mentors will work closely with you to ensure your{" "}
                <b>study abroad plans</b> align with your academic and career
                goals. They bring real-world insights and years of proven
                expertise to offer the best possible advice.
              </p>
            </div>
            <div className="col-lg-4 text-center">
              <div>
                <img src={BhavinThakkar} alt="Bhavin Thakar" />
                <h3 className="m-0" style={{ color: "#275AA6" }}>
                  Mr. Bhavin Thakar
                </h3>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="row p-2 p-md-5 pb-5">
            <div
              className="col-12 pt-3"
              style={{
                boxShadow: "0 20px 20px #00000024",
                borderRadius: "15px",
              }}
            >
              <div className="section2-event-details">
                <h3
                  className="pb-2"
                  style={{ borderBottom: "1px solid black", color: "#275AA6" }}
                >
                  Event Details
                </h3>
                <p>
                  <RxCalendar className="text-dark" />
                  &nbsp; Date &nbsp;&nbsp;&nbsp;:&nbsp; <b>Date</b>
                </p>
                <p>
                  <WiTime4 className="text-dark" />
                  &nbsp; Time &nbsp;&nbsp;&nbsp;:&nbsp; <b>Time</b>
                </p>
                <p>
                  <AiOutlineShop className="text-dark" />
                  &nbsp; Venue : &nbsp; <b>Shambhu's Cafe</b>{" "}
                </p>
                <p>
                  <div
                    className="event-banner-btn"
                    style={{ cursor: "pointer" }}
                  >
                    <Link
                      activeClass="active"
                      // to="test1"
                      offset={-120}
                      duration={300}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontWeight: "bold",
                        marginRight: "45px",
                      }}
                      //   className="event-banner-btn"
                    >
                      Reserve Your Spot Today!
                    </Link>
                    <div
                      style={{
                        alignItems: "center",
                        width: "50px",
                        justifyContent: "center",
                        border: "1px solid rgb(39, 90, 166)",
                        borderImage: "initial",
                        position: "absolute",
                        top: -1,
                        right: -1,
                        bottom: -1,
                        display: "flex",
                        background: "white",
                        color: "#275AA6",
                        borderRadius: "6px",
                      }}
                    >
                      <MdArrowCircleRight style={{ fontSize: "25px" }} />
                    </div>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc2-event-section-6 pt-5 mb-0 pb-3 pb-md-5">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <h1 className="mb-0">
                <span className="text-dark">Careerline's</span> Track Record of
                Success
              </h1>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <p>
                While this is the first event of its kind, <b>Careerline</b> has
                been a trusted name in the study abroad field for over{" "}
                <b>23 years</b>. We have helped more than <b>30,000 students</b>{" "}
                secure admissions to top universities across the globe,
                including institutions in the U.S.A., U.K., Canada, Australia,
                and more.
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-10 offset-lg-1">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                // slidesPerView={3} // Show 3 slides
                initialSlide={1}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className="testimonial-slide">
                      <p>{testimonial.text}</p>
                      <h3>{testimonial.name}</h3>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc2-event-section-7 pt-5 mb-0 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-flex align-items-end justify-content-center">
              <img src={Man2} alt="" className="w-50 w-lg-90" />
            </div>
            <div className="col-lg-7 mt-5 mt-lg-0 pb-4">
              <h1 className="mb-3 mb-sm-4 mb-lg-5">
                Coffee <span style={{ fontWeight: 300 }}>and</span> Counseling,{" "}
                <span style={{ fontWeight: 300 }}>On Us!</span>
              </h1>
              <p className="m-0 hero-subline mb-3 mb-lg-5">
                Don’t miss your chance to receive expert advice on your study
                abroad plans—all while enjoying a{" "}
                <b>complimentary cup of coffee.</b>
              </p>
              <p className="m-0 hero-line mb-3 mb-lg-5">
                <b>Limited spots</b> are available, so act fast. This could be
                the start of your international education journey!
              </p>
              <div className=" mb-5 mb-lg-0">
                <div>
                  <button style={{ margin: 0 }}>
                    Reserve Your Free Spot & Coffee Now!{" "}
                    <MdArrowCircleRight
                      style={{ fontSize: "28px", marginLeft: 10 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CWCEventPage;
