import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const eventDialogSlice = createSlice({
  name: "eventDialogSlice",
  initialState,
  reducers: {
    openEventDialog: (state) => {
      state.open = true;
    },
    closeEventDialog: (state) => {
      state.open = false;
    },
  },
});

export default eventDialogSlice.reducer;
export const { openEventDialog, closeEventDialog } = eventDialogSlice.actions;
