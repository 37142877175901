import React from 'react';

const DynamicBanner = ({title,imageSrc}) => {
  return (
    <React.Fragment>
    <div className="benner">
      <img src={imageSrc} alt="" />
      <div className="benner-para container">
        <div className="bennr-in-heading" data-aos="fade-up" data-aos-duration="700">
          <h2>{title}</h2>
          <p>Our experts can help you increase your chances of <br />
          admission and visa. Start your application process today!
          </p>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default DynamicBanner;
