import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AdmissionSection from "./dynamicMain/AdmissionSection";
import { useLocation, useParams } from "react-router-dom";
import StudentReview from "./StudentReview";
import StudyAbroadFAR from "./StudyAbroadFAR";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { Helmet } from "react-helmet";

const StudyAbroad = () => {
  const { countryname } = useParams();
  const CountryName = [
    {
      value: 1,
      label: "study-in-canada",
    },
    {
      value: 2,
      label: "study-in-usa",
    },
    {
      value: 3,
      label: "study-in-unitedkingdom",
    },
    {
      value: 4,
      label: "study-in-australia",
    },
    {
      value: 5,
      label: "study-in-newzealand",
    },
  ];

  const [coutryData, setCountryData] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  // const [universityIMG, setUniversityIMG] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const selectedCountryID = CountryName.find(
    (country) => country.label === countryname
  )?.value;

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageStudyAbroad`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ID: selectedCountryID, OperationType: 4 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setCountryData(data?.Data[0]);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.error("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };
  const getFaqData = async () => {
    const FAQID = +selectedCountryID + CountryName.length;
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageFAQ`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID: FAQID, OperationType: 4 }),
      });
      const data = await response.json();
      if (data.Status === 200) {
        setFaqData(data?.Data[0]);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching faq data", error);
    } finally {
      setLoading(false);
    }
  };

  // const getUniversitySlider = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await fetch(
  //       `${BACKEND_BASE_URL}/Master/ManageUniversities`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ ID: selectedCountryID, OperationType: 4 }),
  //       }
  //     );
  //       const data = await response.json();
  //     if (data.Status === 200) {
  //       // setUniversityIMG(data?.Data);
  //     } else {
  //       console.log("Failed to fetch university");
  //     }
  //   } catch (error) {
  //     console.log("Error while fetching uvi data", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getTestimonial = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageTestimonial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ID: selectedCountryID, OperationType: 4 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setTestimonialData(data?.Data);
      } else {
        console.log("Failed to fetch testimonial");
      }
    } catch (error) {
      console.log("Error while fetching testimonial data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
    getTestimonial();
    getFaqData();
    // getUniversitySlider();
  }, [selectedCountryID]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [selectedCountryID]);

  React.useEffect(() => {
    AOS.init();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{coutryData?.MetaTitle}</title>
        <meta name="description" content={coutryData?.MetaDescription} />
        <meta name="keywords" content={coutryData?.MetaKeyword} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: coutryData.Banner,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: coutryData.LongDescription,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: coutryData.AboutCountryAndStudy,
        }}
      />

      <div
        dangerouslySetInnerHTML={{
          __html: coutryData.EducationUniversities,
        }}
      />
      <div
        className="container"
        dangerouslySetInnerHTML={{
          __html: coutryData.Advantages,
        }}
      />
      <div
        className="container"
        dangerouslySetInnerHTML={{
          __html: coutryData.GeneralRequirements,
        }}
      />
      <div className="container">
        <div
          dangerouslySetInnerHTML={{
            __html: coutryData.EducationCost,
          }}
        />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: coutryData.ProcessFlow,
        }}
      />
      {coutryData.PartTime && (
        <div
          dangerouslySetInnerHTML={{
            __html: coutryData.PartTime,
          }}
        />
      )}
      <div
        className="container"
        dangerouslySetInnerHTML={{
          __html: coutryData.UniversityRequirement,
        }}
      />

      <div
        className="container"
        dangerouslySetInnerHTML={{
          __html: coutryData.StudyAbroadUniversities,
        }}
      />
      {/* <h2> {universityIMG[0]?.CountryName} <span>Universities</span></h2>
            
          <UniversitySlider universityIMG={universityIMG} /> */}
      {/* <div
        dangerouslySetInnerHTML={{
          __html: homeData.HomeFuture,
        }}
      /> */}

      <div className="container">
        <div className="careerline careermb">
          <div className="row">
            {coutryData.WhyCareerline && (
              <div
                className="col-sm-12 col-md-12 col-lg-6"
                dangerouslySetInnerHTML={{ __html: coutryData.WhyCareerline }}
              />
            )}
            {/* <CareerlineSection reasons={reasons} /> */}
            <AdmissionSection />
          </div>
        </div>
      </div>

      <div className="faq-section">
        <div className="container">
          <div className="faq-title">
            <h2>Frequently Asked Questions</h2>
            <p> {faqData?.Title}</p>
          </div>
          <div className="accordion" id="accordionExample">
            <StudyAbroadFAR faqData={faqData} />
          </div>
        </div>
      </div>

      <StudentReview testimonialData={testimonialData} />
    </>
  );
};

export default StudyAbroad;
