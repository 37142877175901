import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import React from "react";
import { Footer } from "./Footer/Footer";
import CustomModal from "../pages/home/QuickInquiryModel";
import { useSelector } from "react-redux";
import WhatsAppIcon from "../Components/Common/WhatsAppIcon";
import EventADPopup from "../pages/home/EventADPopup";

const Layout = () => {
  const show = useSelector((state) => state.dialog.open);
  // const eventShow = useSelector((state) => state.eventDialog.open);

  return (
    <React.Fragment>
      {/* {eventShow && <EventADPopup />} */}
      <Header />
      <Outlet />
      <Footer />
      {show && <CustomModal />}
      <WhatsAppIcon />
    </React.Fragment>
  );
};
export default Layout;
