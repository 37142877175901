import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";

const StudyAbroadFAR = ({ faqData }) => {
  const [activeKey, setActiveKey] = useState("0"); // Track active items

  const toggleAccordion = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  const parseAndRenderLinks = (text) => {
    // Regex to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex).map((part, index) => {
      if (index % 2 === 1) {
        // Odd index represents URLs, render them as links
        return (
          <a key={index} href={`${part}/`} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // Even index represents regular text
        return <span key={index}>{part}</span>;
      }
    });
  };

  return (
    <>
      <Accordion activeKey={activeKey} onSelect={toggleAccordion}>
        {faqData?.FAQLIST?.map((item, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>{item.FAQ}</Accordion.Header>
            <Accordion.Body>
              <p>{parseAndRenderLinks(item.Answer)}</p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default StudyAbroadFAR;
