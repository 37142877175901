import React from "react";
import { Link } from "react-router-dom";

const NewsListingItem = ({
  NewsDate,
  NewsTitle,
  ShortDescription,
  NewsImage,
  delay,
  NewsURL,
}) => {
  const URL = `${window.location.origin}/newsdetail/${NewsURL}/`;

  return (
    <div
      className="col-sm-12 col-md-6 col-lg-4"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-delay={delay}
    >
      <div className="blog-listing">
        <div className="blogopics">
          <img src={NewsImage} alt="" />
        </div>
        <div className="artical-right">
          <span>{NewsDate}</span>
          <Link to={URL}>
            <h5>{NewsTitle}</h5>
          </Link>
          <p>{ShortDescription}</p>
          <Link to={URL}>read more</Link>
        </div>
      </div>
    </div>
  );
};

export default NewsListingItem;
