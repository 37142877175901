import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import IeltsonlineForm from "./dynamicMain/IeltsOnlineForm";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";

// const BASE_URL = "http://68.64.173.183/CALWEBAPI/api";

const IeltsOnline = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  React.useEffect(() => {
    AOS.init();
  }, []);
  const [aboutData, setAboutData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAboutData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageCMS`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID: 1, OperationType: 4 }),
      });
      const data = await response.json();
      if (data.Status === 200) {
        setAboutData(data?.Data[0]);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const getBannerData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageBanner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID: 3, OperationType: 4 }),
      });
      const data = await response.json();
      if (data.Status === 200) {
        setBannerData(data?.Data[0]);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAboutData();
    getBannerData();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: bannerData.Banner,
        }}
      />
      <div className="container">
        <div className="ielts-coaching">
          <div className="row">
            <div
              className="col-sm-12 col-md-6 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="700"
              dangerouslySetInnerHTML={{
                __html: aboutData.IELTSCoaching,
              }}
            />
            <IeltsonlineForm />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.IELTSGuidence,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.IELTSPricingBlock,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.IELTSContactInfo,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.IELTSPaymentQR,
        }}
      />
    </>
  );
};

export default IeltsOnline;
