import React from "react";

const WhatsAppIcon = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "60px",
        right: "2rem",
        cursor: "pointer",
        zIndex: 5,
      }}
    >
      <a href="https://wa.me/916358989872/" target="_blank">
        <img
          src="http://www.websiteimages.careerline.in/uploads/WhatsAppIcon.png"
          height="60"
          alt="WhatsApp"
        />
      </a>
    </div>
  );
};

export default WhatsAppIcon;
