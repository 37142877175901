import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PORTAL_BACKEND_BASE_URL } from "../../../common/constant";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";

const EventContry = [
  {
    ID: 5,
    CountryName: "NZ",
  },
];

const WebinarRegForm = ({ data, CoffeeWithCareearlineID }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { Campaign } = useParams();

  const initialValues = {
    FirstName: "",
    LastName: "",
    MobileNo: "",
    EmailID: "",
    CountryPreference: "5",
  };

  const phoneRegExp = /^[0-9]{6,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .email("Invalid email")
      .required("Email Address is required"),
    CountryPreference: Yup.string().required("Country Preference is required"),
  });

  const handleRadioChange = async (event, field, formik) => {
    formik.setFieldValue(field, event.target.value);
  };

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      CountryPreference: values.CountryPreference || "",
      MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
      Campaign: Campaign ? Campaign : "website",
    //   EventID: CoffeeWithCareearlineID ? CoffeeWithCareearlineID : null,
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${PORTAL_BACKEND_BASE_URL}/Events/WebinarEventRegistration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      if (responseData.Status !== 200) {
        ErrorToast(responseData?.Details);
      } else {
        SuccessToast(responseData?.Details);
        navigate("/events/thankyou/");
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="event-reg-form">
      {/* heading */}
      {/* {data?.RegisteredPercentage && data?.RegisteredPercentage < 100 && (
        <div className="crms-title row mb-4">
          <div>
            <h5 className="m-0">{`${
              data?.RegisteredPercentage < 70 ? 70 : data?.RegisteredPercentage
            }% Seats are Packed... Hurry up!!`}</h5>
          </div>
        </div>
      )} */}
      <div className="crms-title row mb-4">
        <p>Webinar</p>
        <p>Study in New Zealand</p>
        <p>Date : 01 March, 2024</p>
        <p>Time : 05:30 PM to 07:30 PM</p>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: data?.CMSHeader,
          }}
        /> */}
      </div>
      {/* card rows form starting */}
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* left sided card */}
          <div className="col-xl-6 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <h5 className="card-title mb-0">Basic Information</h5>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      First Name<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="FirstName"
                        type="text"
                        id="FirstName"
                        className={`form-control ${
                          formik.errors.FirstName &&
                          formik.touched.FirstName &&
                          "error-border"
                        }`}
                        maxlength="50"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FirstName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Last Name<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="LastName"
                        type="text"
                        id="LastName"
                        className={`form-control ${
                          formik.errors.LastName &&
                          formik.touched.LastName &&
                          "error-border"
                        }`}
                        maxlength="50"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.LastName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Email Address<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="EmailID"
                        type="email"
                        id="EmailID"
                        className={`form-control ${
                          formik.errors.EmailID &&
                          formik.touched.EmailID &&
                          "error-border"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.EmailID}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Mobile No<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <input
                        name="MobileNo"
                        type="number"
                        id="MobileNo"
                        className={`form-control ${
                          formik.errors.MobileNo &&
                          formik.touched.MobileNo &&
                          "error-border"
                        }`}
                        max={9999999999}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.MobileNo}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-4 col-form-label">
                      Country Prefrence<code>*</code>
                    </label>
                    <div className="col-lg-8">
                      <table
                        className={`form-control tabel-border-none ${
                          formik.errors.CountryPreference &&
                          formik.touched.CountryPreference &&
                          "error-border"
                        }`}
                        id="CountryPrefrences"
                      >
                        <tbody>
                          {EventContry?.map((item) => (
                            <tr key={item.ID}>
                              <td>
                                <label>
                                  <input
                                    className="me-2"
                                    type="radio"
                                    name="CountryPreference"
                                    onChange={(event) =>
                                      handleRadioChange(
                                        event,
                                        "CountryPreference",
                                        formik
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    value={String(item?.ID)}
                                    checked={
                                      formik.values.CountryPreference ===
                                      String(item?.ID)
                                    }
                                  />
                                  {item?.CountryName}
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <div style={{ textAlign: "end" }} className="col-lg-12">
                      <button
                        id="submit-btn"
                        className="btn btn-primary"
                        type="submit"
                        style={{ width: "fit-content" }}
                        disabled={loading}
                      >
                        {loading ? "Submiting" : "Submit Details"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WebinarRegForm;
