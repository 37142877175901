import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../common/constant";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../Slice/dialogSlice";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { ErrorToast, SuccessToast } from "../../Components/Common/Toast";

const CustomModal = () => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryError, setCountryError] = useState("");

  const [formData, setFormData] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    CoursePreference: "",
    CountryPreference: "",
  });
  const [formErrors, setFormErrors] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    CoursePreference: "",
  });

  const show = useSelector((state) => state.dialog.open);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countries = [
    {
      value: 1,
      label: "USA",
    },
    {
      value: 2,
      label: "UK",
    },
    {
      value: 3,
      label: "Canada",
    },
    {
      value: 4,
      label: "Australia",
    },
    {
      value: 5,
      label: "New Zealand",
    },
  ];

  const handleCheckboxChange = (country) => {
    setSelectedCountries((prevSelectedCountries) => {
      const updatedCountries = prevSelectedCountries.includes(country.value)
        ? prevSelectedCountries.filter((c) => c !== country.value)
        : [...prevSelectedCountries, country.value];

      if (updatedCountries.length === 0) {
        setCountryError("At least one country required");
      } else {
        setCountryError("");
      }

      return updatedCountries;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: name !== "CountryPreference" && !value ? "Required." : "",
    });
  };

  const handleApplyNowClick = async (event) => {
    event.preventDefault();

    let IsError = false;
    if (selectedCountries.length < 1) {
      setCountryError("At least one country required");
      IsError = true;
    }
    const newFormErrors = {};
    for (const key in formData) {
      if (key !== "CountryPreference" && !formData[key]) {
        newFormErrors[key] = "Required.";
      }
    }
    if (Object.keys(newFormErrors).length > 0) {
      setFormErrors(newFormErrors);
      IsError = true;
      // return;
    }

    if (IsError) {
      return;
    }

    let data = formData;
    data.OperationType = 1;

    data.CountryPreference = selectedCountries.join(", ");

    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageInquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (responseData.Status === 200) {
        dispatch(closeDialog());
        SuccessToast(responseData?.Details);
        navigate("/thankyou");
        setFormData({
          Name: "",
          EmailID: "",
          MobileNo: "",
          CoursePreference: "",
          CountryPreference: "",
        });
        setFormErrors({
          Name: "",
          EmailID: "",
          MobileNo: "",
          CoursePreference: "",
        });
        setCountryError("");
      } else {
        ErrorToast(responseData?.Details);
        console.error("Failed to submit data to the API");
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred while submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "37px",
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  
  return (
    <Modal show={show} onHide={() => dispatch(closeDialog())}>
      <Modal.Header closeButton>
        <Modal.Title style={titleStyle}>Quick Inquiry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleApplyNowClick}>
          <input
            className={formErrors.Name ? "error-border" : ""}
            onChange={handleChange}
            name="Name"
            type="text"
            placeholder="Name"
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />

          <input
            type="text"
            name="EmailID"
            className={formErrors.EmailID ? "error-border" : ""}
            placeholder="Email address"
            onChange={handleChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginTop: "5px",
            }}
          />
          <div className="input-phone">
            <input
              type="number"
              name="MobileNo"
              className={formErrors.MobileNo ? "error-border" : ""}
              placeholder="phone number"
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginTop: "5px",
              }}
            />
            <label htmlFor="CoursePreference"></label>
            <select
              className={formErrors.CoursePreference ? "error-border" : ""}
              name="CoursePreference"
              id="CoursePreference"
              onChange={handleChange}
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            >
              <option value="">Select Course</option>
              <option value="10">IELTS</option>
              <option value="4">GRE</option>
              <option value="3">PTE</option>
              <option value="5">SAT</option>
              {/* <option value="2">TOEFL</option> */}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {countries.map((country) => (
              <div
                key={country.value}
                style={{ display: "flex", width: "fit-content" }}
              >
                <input
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  type="checkbox"
                  value={country.value}
                  checked={selectedCountries.includes(country.value)}
                  onChange={() => handleCheckboxChange(country)}
                />
                <label> {country.label}</label>
              </div>
            ))}
          </div>

          {countryError && <div className="error-message">{countryError}</div>}
          <div className="tourist-button">
            <button
              className="apply-now"
              type="submit"
              style={{ width: "100%" }}
            >
              Apply Now
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
