import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-4">
      <p>
        <strong>Privacy Policy</strong>
      </p>
      <p>Effective date: January 1, 2020</p>
      <p>
        Careerline Education &nbsp;("us", "we", or "our") operates the
        Careerline Education mobile application (hereinafter referred to as the
        "Service").
      </p>
      <p>
        This page informs you of our policies regarding the collection, use and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </p>
      <p>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, the terms used in this Privacy Policy have the same meanings as
        in our Terms and Conditions.
      </p>
      <p>
        <strong>Definitions</strong>
      </p>
      <ul className="ps-5">
        <li>
          <strong>Service</strong>
        </li>
      </ul>
      <p>
        Service is the Careerline Education &nbsp;mobile application operated by
        Careerline Education
      </p>
      <ul className="ps-5">
        <li>
          <strong>Personal Data</strong>
        </li>
      </ul>
      <p>
        Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </p>
      <ul className="ps-5">
        <li>
          <strong>Usage Data</strong>
        </li>
      </ul>
      <p>
        Usage Data is data collected automatically either generated by the use
        of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).
      </p>
      <ul className="ps-5">
        <li>
          <strong>Cookies</strong>
        </li>
      </ul>
      <p>
        Cookies are small files stored on your device (computer or mobile
        device).
      </p>
      <p>
        <strong>Information Collection and Use</strong>
      </p>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <p>
        <strong>Types of Data Collected</strong>
      </p>
      <p>
        <strong>Personal Data</strong>
      </p>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul className="ps-5">
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Cookies and Usage Data</li>
      </ul>
      <p>
        <strong>Usage Data</strong>
      </p>
      <p>
        When you access the Service with a mobile device, we may collect certain
        information automatically, including, but not limited to, the type of
        mobile device you use, your mobile device unique ID, the IP address of
        your mobile device, your mobile operating system, the type of mobile
        Internet browser you use, unique device identifiers and other diagnostic
        data ("Usage Data").
      </p>
      <p>
        <strong>Tracking &amp; Cookies Data</strong>
      </p>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information. Cookies are files with a
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Other tracking technologies are also used such as beacons, tags
        and scripts to collect and track information and to improve and analyse
        our Service. You can instruct your browser to refuse all cookies or to
        indicate when a cookie is being sent. However, if you do not accept
        cookies, you may not be able to use some portions of our Service.
        Examples of Cookies we use:
      </p>
      <ul className="ps-5">
        <li>
          <strong>Session Cookies.</strong>&nbsp;We use Session Cookies to
          operate our Service.
        </li>
        <li>
          <strong>Preference Cookies.</strong>&nbsp;We use Preference Cookies to
          remember your preferences and various settings.
        </li>
        <li>
          <strong>Security Cookies.</strong>&nbsp;We use Security Cookies for
          security purposes.
        </li>
      </ul>
      <p>
        <strong>Use of Data</strong>
      </p>
      <p>Careerline Education uses the collected data for various purposes:</p>
      <ul className="ps-5">
        <li>To provide and maintain our Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer support</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          Service
        </li>
        <li>To monitor the usage of our Service</li>
        <li>To detect, prevent and address technical issues</li>
      </ul>
      <p>
        <strong>Transfer of Data</strong>
      </p>
      <p>
        Your information, including Personal Data, may be transferred to - and
        maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>
      <p>
        If you are located outside and choose to provide information to us,
        please note that we transfer the data, including Personal Data, to and
        process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        Careerline Education will take all the steps reasonably necessary to
        ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer of your Personal Data will take place to
        an organisation or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </p>
      <p>
        <strong>Disclosure of Data</strong>
      </p>
      <p>
        <strong>Legal Requirements</strong>
      </p>
      <p>
        Careerline Education may disclose your Personal Data in the good faith
        belief that such action is necessary to:
      </p>
      <ul className="ps-5">
        <li>To comply with a legal obligation</li>
        <li>
          To protect and defend the rights or property of Careerline Education
        </li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </ul>
      <p>
        <strong>Security of Data</strong>
      </p>
      <p>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>
      <p>
        <strong>Service Providers</strong>
      </p>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), provide the Service on our behalf,
        perform Service-related services or assist us in analysing how our
        Service is used. These third parties have access to your Personal Data
        only to perform these tasks on our behalf and are obligated not to
        disclose or use it for any other purpose.
      </p>
      <p>
        <strong>Links to Other Sites</strong>
      </p>
      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </p>
      <p>&nbsp;</p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We will
        let you know via email and/or a prominent notice on our Service, prior
        to the change becoming effective and update the "effective date" at the
        top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <p>admin@careerline.org</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default PrivacyPolicy;
