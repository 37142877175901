import React from "react";
import ArticalList from "./ArticalList";
import NewsList from "./NewsList";
import { useNavigate } from "react-router-dom";

const NewsAndArical = ({ newsData, articalData }) => {
  const navigate = useNavigate() ;


  return (
    <div className="happening-section">
      <div className="container">
        <h2>
          What is <span>Happening Now</span>
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="artical-section">
              <h4>Latest Articles</h4>

              <ArticalList articalData={articalData} />

              <button className="apply-now">
                <a onClick={()=>navigate("/blogs/")}>Explore More Articles</a>
              </button>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="news-section">
              <h4>Latest News</h4>

              <NewsList newsData={newsData} />

              <div className="btn">
                <button className="apply-now">
                  <a onClick={()=>navigate("/news/")}>Explore More News</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsAndArical;
