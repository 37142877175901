import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import CourseSlider from "../home/ExtraHome/CourseSlider";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

// const BASE_URL = "http://68.64.173.183/CALWEBAPI/api";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  React.useEffect(() => {
    AOS.init();
  }, []);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const [aboutData, setAboutData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAboutData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageAboutData`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setAboutData(data?.Data);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };
  const getCourse = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManagePopularCourse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ID: 1, OperationType: 4 }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setCourseData(data?.Data);
      } else {
        console.log("Failed to fetch course");
      }
    } catch (error) {
      console.log("Error while fetching course data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAboutData();
    getCourse();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Helmet>
        <title>{aboutData?.AboutSEO?.MetaTitle}</title>
        <meta
          name="description"
          content={aboutData?.AboutSEO?.MetaDescription}
        />
        <meta name="keywords" content={aboutData?.AboutSEO?.MetaKeyword} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div className="container">
        <div className="about-benner">
          <div
            className="row"
            dangerouslySetInnerHTML={{
              __html: aboutData.AboutUsStudyPlan,
            }}
          />
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.AboutUsEducation,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.AboutUsMisionVision,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.AboutUsAdvising,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: aboutData.AboutUsInfrastructure,
        }}
      />
      <CourseSlider courseData={courseData} />
    </>
  );
};

export default About;
