import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";

export const Footer = () => {
  const [footer, setFooter] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchHomePage = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageCMSDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ CMSURL: "Footer" }),
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setFooter(data?.Data?.Template);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.log("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHomePage();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: footer,
      }}
    />
  );
};
