import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import AdmissionSection from "../studyAbroad/dynamicMain/AdmissionSection";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SummerCamp = () => {
  const [summerData, setSummerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;
  const getSummerCamp = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BACKEND_BASE_URL}/Master/ManageSummerCampData`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.Status === 200) {
        setSummerData(data?.Data);
      } else {
        console.log("Failed to fetch summer");
      }
    } catch (error) {
      console.log("Error while fetching summer:", error);
    } finally {
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    AOS.init();
    scrollToTop();
    getSummerCamp();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Helmet>
        <title>{summerData?.SummerCampSEO?.MetaTitle}</title>
        <meta
          name="description"
          content={summerData?.SummerCampSEO?.MetaDescription}
        />
        <meta
          name="keywords"
          content={summerData?.SummerCampSEO?.MetaKeyword}
        />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: summerData.SummerCamp,
        }}
      />
      <div className="container">
        <div className="careerline careerline-summer">
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-6"
              dangerouslySetInnerHTML={{
                __html: summerData.SummerCampWhy,
              }}
            />
            <AdmissionSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default SummerCamp;
