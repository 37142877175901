import React, { useState } from "react";
import IeletCall from "../../../assets/image/ielet-call.png";
import { useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../common/constant";
import LoadingSpinner from "../../../Components/Common/LoadingSpinner";
import { ErrorToast, SuccessToast } from "../../../Components/Common/Toast";

// const BASE_URL = "https://api.careerline.in/api";

const CoachingForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    Remarks: "",
  });
  const [formErrors, setFormErrors] = useState({
    Name: "",
    EmailID: "",
    MobileNo: "",
    Remarks: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: value ? "" : "This field is required.",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if there are any errors in the form
    const newFormErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        newFormErrors[key] = "This field is required.";
      }
    }

    // If there are errors, update the state and return
    if (Object.keys(newFormErrors).length > 0) {
      setFormErrors(newFormErrors);
      return;
    }

    const formDataWithOperationType = {
      ...formData,
      OperationType: 1,
    };

    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageInquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithOperationType),
      });
      const responseData = await response.json();
      if (responseData.Status === 200) {
        SuccessToast(responseData?.Details);
        setFormData({
          Name: "",
          EmailID: "",
          MobileNo: "",
          Remarks: "",
        });
        setFormErrors({
          Name: "",
          EmailID: "",
          MobileNo: "",
          Remarks: "",
        });
        navigate("/thankyou");
      } else {
        ErrorToast(responseData?.Details);
        console.error("Failed to submit data");
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred while submitting data", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   // Clear the error message when the user starts typing
  //   if (value) {
  //     setFormErrors({
  //       ...formErrors,
  //       [name]: "",
  //     });
  //   }
  // };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div
      className="col-sm-12 col-md-6 col-lg-6 coaching-form"
      data-aos="fade-up"
      data-aos-duration="700"
    >
      <div className="admission-para ielts-coaching-right">
        <h4>Free Information</h4>
        <form action="" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="name"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            className={formErrors.Name ? "error-border" : ""}
          />
          {/* {formErrors.Name && (
            <div
              className="error-message"
              style={{ textAlign: "left", marginTop: "-9px" }}
            >
              {formErrors.Name}
            </div>
          )} */}
          <input
            type="email"
            placeholder="email address"
            name="EmailID"
            value={formData.EmailID}
            onChange={handleChange}
            className={formErrors.EmailID ? "error-border" : ""}
          />
          {/* {formErrors.EmailID && (
            <div
              className="error-message"
              style={{ textAlign: "left", marginTop: "-9px" }}
            >
              {formErrors.EmailID}
            </div>
          )} */}
          <input
            type="number"
            placeholder="phone number"
            name="MobileNo"
            value={formData.MobileNo}
            onChange={handleChange}
            className={formErrors.MobileNo ? "error-border" : ""}
          />
          {/* {formErrors.MobileNo && (
            <div
              className="error-message"
              style={{ textAlign: "left", marginTop: "-9px" }}
            >
              {formErrors.MobileNo}
            </div>
          )} */}
          <p>
            {" "}
            <textarea
              type="text"
              name="Remarks"
              cols="100"
              rows="5"
              placeholder="write message"
              value={formData.Remarks}
              onChange={handleChange}
              className={formErrors.Remarks ? "error-border" : ""}
            />
          </p>
          {/* {formErrors.Remarks && (
            <div
              className="error-message"
              style={{ textAlign: "left", marginTop: "-9px" }}
            >
              {formErrors.Remarks}
            </div>
          )} */}
          <button type="submit" className="apply-now">
            apply now
          </button>
          <h5>For Our Appointment Service Call Us</h5>
          <span>
            <img src={IeletCall} alt="" />
            <a href="tel:6358989872">
              <h4>+91-6358989872</h4>
            </a>
          </span>
        </form>
      </div>
    </div>
  );
};

export default CoachingForm;
