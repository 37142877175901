import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { ErrorToast, SuccessToast } from "../../Components/Common/Toast";
import "react-phone-number-input/style.css";
import { BACKEND_BASE_URL } from "../../common/constant";
const GRELandingPageForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname?.split("/")[1] || "";

  const [loading, setLoading] = useState(false);
  const [initialValues] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNo: null,
  });

  const phoneRegExp = /^[0-9]{10,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .required("Email Address is required")
      .email("Invalid email"),
  });

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
      FromPage: pathName || "",
      OperationType: 1,
    };

    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageInquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const responseData = await response.json();
      if (responseData.Status !== 200) {
        ErrorToast(responseData?.Details);
      } else {
        navigate("/thankyou/");
        SuccessToast(responseData?.Details);
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="ielts-form">
      {loading && <LoadingSpinner />}
      <div className="row">
        <div className="col-12 d-flex">
          <div
            className="card flex-fill"
            style={{
              borderRadius: 30,
              background:
                pathName === "coffeewithcareerline2" ? "#275AA6" : "#fff",
              color: pathName === "coffeewithcareerline2" ? "#fff" : "#000",
            }}
          >
            <div className="card-body row">
              <h4
                className="card-title"
                style={{
                  color:
                    pathName === "coffeewithcareerline2" ? "#fff" : "#275AA6",
                }}
              >
                Claim Your Free Seat Now
              </h4>
              <div className="col-12">
                <div className="form-group row">
                  <div className="col-12">
                    <input
                      name="FirstName"
                      type="text"
                      id="FirstName"
                      placeholder="Enter First Name"
                      className={`form-control ${
                        formik.errors.FirstName &&
                        formik.touched.FirstName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.FirstName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <div className="col-12">
                    <input
                      name="LastName"
                      type="text"
                      id="LastName"
                      placeholder="Enter Last Name"
                      className={`form-control ${
                        formik.errors.LastName &&
                        formik.touched.LastName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.LastName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <div className="col-12">
                    <input
                      name="MobileNo"
                      type="number"
                      id="MobileNo"
                      placeholder="Enter Mobile No"
                      className={`form-control ${
                        formik.errors.MobileNo &&
                        formik.touched.MobileNo &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.MobileNo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group row">
                  <div className="col-12">
                    <input
                      name="EmailID"
                      type="text"
                      id="EmailID"
                      placeholder="Enter Email ID"
                      className={`form-control ${
                        formik.errors.EmailID &&
                        formik.touched.EmailID &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.EmailID}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  id="submit-btn"
                  className="submit-btn"
                  type="submit"
                  disabled={loading}
                >
                  {"Enroll Now"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default GRELandingPageForm;
