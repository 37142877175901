import React, { useEffect, useState } from "react";
import BANNERIMG from "../../assets/image/Coaching PTE img layout.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useParams } from "react-router-dom";
import BannerSubBlog from "./dynamicMain/BannerSubBlog";
import { BACKEND_BASE_URL } from "../../common/constant";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { Helmet } from "react-helmet";

const SubBlog = () => {
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const { url } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;
  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("OperationType", 4);
      formData.append("BlogURL", url);
      const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageBlog`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.Status === 200) {
        setBlog(data?.Data[0]);
      } else {
        console.log("Failed to fetch blogs");
      }
    } catch (error) {
      console.error("Error while fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchBlogs();
    AOS.init();
    scrollToTop();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{blog?.MetaTitle}</title>
        <meta name="description" content={blog?.MetaDescription} />
        <meta name="keywords" content={blog?.MetaKeyword} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <BannerSubBlog
        title={blog.BlogTitle}
        date={blog.BlogDate}
        imageSrc={BANNERIMG}
      />
      <div className="wpb_wrapper">
        <div className="wpb_text_column wpb_content_element">
          <div className="wpb_wrapper">
            {/* <figure
              id="attachment_2529"
              aria-describedby="caption-attachment-2529"
              style={{ width: "300px", margin: "95px auto" }}
              className="wp-caption alignnone"
            >
              <img src={blog.BlogImage} />
            </figure> */}

            <div style={{ margin: "0px 75px", marginTop: "73px" }}>
            {/* <div style={{ margin: "0px 75px" }}> */}
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: blog.ShortDescription,
                }}
              /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: blog.LongDescription,
                }}
              />
              {/* <p>{blog.ShortDescription}</p>
              <p>{blog.LongDescription}</p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubBlog;
