import React from 'react'
import CAREERLINE_LOGO from "../../assets/image/logo.png"
import { Link } from 'react-router-dom'
const Logo = () => {
  return (
    <div className="navbar-logo">
    <Link to="/">
    <img src={CAREERLINE_LOGO} alt="" /> 
    </Link>
  </div>
  )
}

export default Logo;

