import React from "react";
import { Link } from "react-router-dom";

const BlogListingItem = ({
  BlogDate,
  BlogTitle,
  ShortDescription,
  // BlogURL,
  BlogImage,
  delay,
  BlogURL,
}) => {
  const URL = `${window.location.origin}/blogdetail/${BlogURL}/`;

  return (
    <div
      className="col-sm-12 col-md-6 col-lg-4"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-delay={delay}
    >
      <div className="blog-listing">
        <div className="blogopics">
          <img src={BlogImage} alt="" />
        </div>
        <div className="artical-right">
          <span>{BlogDate}</span>
          <Link to={URL}>
            <h5>{BlogTitle}</h5>
          </Link>
          <p>{ShortDescription}</p>
          <Link to={URL}>read more</Link>
        </div>
      </div>
    </div>
  );
};

export default BlogListingItem;
