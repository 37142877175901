import React from "react";
import LatestNews from "./LatestNews";

const NewsList = ({ newsData }) => {
  return (
    <div>
      {newsData.map((article, index) => (
        <LatestNews key={index} {...article} />
      ))}
    </div>
  );
};

export default NewsList;
